/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeHeaderStatus } from 'store/modules/header/actions';

// Components
import { Sidebar } from './SideBar';
import Stepper from 'components/Stepper';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';

import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';

// Constants
import { steps } from './constants';
import * as services from './services';
import { useTheme } from 'styled-components';
import { setHeader } from './actions';
import { toast } from 'react-toastify';

const ConfigLayout = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  // --Control
  const [isEdit, setIsEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [trySave, setTrySave] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [layout, setLayout] = useState(id);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingBases, setLoadingBases] = useState(true);
  const [loadingRascunho, setLoadingRascunho] = useState(false);
  const [hasRascunho, setHasRascunho] = useState(false);
  const [rascunho, setRascunho] = useState({});

  // --DATAS--//
  const [aprovadores, setAprovadores] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [bases, setBases] = useState([]);
  const [posto, setPosto] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [tamanhos, setTamanhos] = useState([]);
  const [logs, setLogs] = useState([]);
  const [cliente, setCliente] = useState(1);

  // -----------------------REQ-----------------------//

  // Bases
  const getBases = async id_dist => {
    setLoadingBases(true);
    const sCliente = +searchParams.get('client');
    const cl = id_dist || posto?.id_da_distribuidora || sCliente;
    const res = await services.getBases(cl);
    setBases(res?.rows);
    setLoadingBases(false);
  };

  useEffect(() => {
    !id && getBases();
  }, []);

  // Rascunho
  useEffect(() => {
    const getRascunhos = async () => {
      const sCliente = +searchParams.get('client');
      const cl = posto?.id_da_distribuidora || sCliente;
      setLoadingRascunho(true);
      const res = await services.verifyRascunho(cl);
      setLoadingRascunho(false);
      res.success && setHasRascunho(true);
      setRascunho(res?.posto);
    };
    !id && getRascunhos();
  }, []);

  const continueRascunho = async () => {
    const currentStep = rascunho?.etapa;
    if (currentStep > 0 && currentStep < steps.length) {
      setPosto(rascunho);
      setLayout(rascunho?.id);
      setCurrentStep(steps[currentStep]);
      setHasRascunho(false);
    } else {
      toast.error(
        'Ocorreu um erro ao carregar o rascunho! Por favor, inicie um novo layout.',
      );
    }
  };

  const discardRascunho = async () => {
    setHasRascunho(false);
    setRascunho({});
    await services.removeRascunho(rascunho?.id);
  };

  // Posto
  const { isFetching: isLoading } = useQuery({
    queryKey: ['posto_cliente', { id: 1 }],
    queryFn: () => id && services.showLayout(id),
    onSuccess: data => {
      setPosto(data?.posto);
      setProdutos(data?.produtos);
      getBases(data?.posto?.id_da_distribuidora);
    },
    refetchOnWindowFocus: false,
  });

  // Aprovadores
  const {} = useQuery({
    queryKey: ['layout-aprovadores'],
    queryFn: () => services.getAprovadores(),
    onSuccess: data => setAprovadores(data?.rows),
    refetchOnWindowFocus: false,
  });

  // Produtos
  useEffect(() => {
    const getProducts = async () => {
      setLoadingProducts(true);
      const sCliente = +searchParams.get('client');
      const cl = posto?.id_da_distribuidora || sCliente;
      const res = await services.getProdutos(cl);
      setProdutos(res?.rows);
      setLoadingProducts(false);
    };
    !id && getProducts();
  }, []);

  // Tamanhos
  const {} = useQuery({
    queryKey: ['layout-tamanhos'],
    queryFn: () => services.getTamanhos(),
    onSuccess: data => setTamanhos(data?.rows),
    refetchOnWindowFocus: false,
  });

  // Logs
  const { refetch: refetchLogs } = useQuery({
    queryKey: ['layout-logs'],
    queryFn: () => id && services.getLogs(id),
    onSuccess: data => setLogs(data?.logs),
    refetchOnWindowFocus: false,
  });

  const changePage = () => {
    const { Component } = currentStep;
    if (!posto?.id_da_distribuidora && !cliente) {
      return null;
    }

    return (
      <Component
        steps={steps}
        isEdit={isEdit}
        trySave={trySave}
        setId={setLayout}
        setLoading={setLoading}
        setTrySave={setTrySave}
        setChanged={setChanged}
        setCurrentStep={setCurrentStep}
        // ----------// Datas //----------//
        posto={posto}
        aprovadores={aprovadores}
        bases={bases.map(b => ({ name: b.nome, value: b.id }))}
        tamanhos={tamanhos}
        produtos={produtos}
        setPosto={setPosto}
        logs={logs}
        cliente={posto?.id_da_distribuidora || cliente}
        id={layout}
        answers={answers}
        setAnswers={setAnswers}
      />
    );
  };

  // Updated questions all time
  useEffect(() => {
    if (posto) {
      const ans = posto?.respostas?.map(item => ({
        ...item,
        questao: {
          ...item?.questao,
          secao: {
            id: 1,
          },
        },
      }));

      setAnswers(ans);
      dispatch(changeHeaderStatus(setHeader(posto?.status, theme)));
    }
  }, [posto]);

  // -----------------------------------TYPE PAGE--------------------------------------//

  useEffect(() => {
    const pathname = location.pathname;

    const edit = !pathname.includes('criar');
    setIsEdit(edit);

    const sStep = +searchParams.get('step');
    const sCliente = +searchParams.get('client');

    // Atenção: Cliente 1 e 4 são os clientes que tem acesso a essa página
    // Pois a pagina é renderizada com base em informações fornecidas pelo cliente
    if (!edit && sCliente !== 1 && sCliente !== 4) {
      navigate('/layout', { replace: true });
    } else {
      setCliente(sCliente);
    }
    edit && sStep && sStep <= steps.length && setCurrentStep(steps[+sStep - 1]);
  }, []);

  // ---------------------------CONTROLLER PAGE (Functions)----------------------------//
  const handleBackPage = () => {
    if (currentStep.step !== 1) {
      const doc = document.getElementById('container-layout-page');
      doc.scrollTo(0, 0);
      setCurrentStep(steps[currentStep.value - 1]);
      setChanged(false);
    } else {
      navigate(-1, { replace: true });
    }
  };

  const handleNext = () => {
    if (currentStep.step < steps.length) {
      setChanged(false);
      const next = steps[currentStep.value + 1];
      if (next.step === 6) {
        if (posto?.status === 'RASCUNHO') {
          toast.warn('Assine como executor para liberar o layout!');
          return;
        }
        navigate(`/layout/${posto?.id}`, { replace: true });
        setCurrentStep(steps[0]);
        setIsEdit(true);
        refetchLogs();
      } else {
        const doc = document.getElementById('container-layout-page');
        doc.scrollTo(0, 0);
        setCurrentStep(steps[currentStep.value + 1]);
      }
    }
  };

  const handleSave = () => {
    // This state is used for start a request in sub components
    // They have a useEffect to verify true or false and dispatch request
    setTrySave(true);
  };

  // -------------------------CONTROLLER FOOTER BUTTONS----------------------------//
  const saveButton = () => {
    if (changed) {
      return (
        <DefaultButton onClick={() => handleSave()} loading={loading}>
          {isEdit ? 'Salvar' : 'Salvar e Avançar'}
        </DefaultButton>
      );
    }
  };

  const nextButton = () => {
    if (!changed && (currentStep.step !== 5 || !isEdit)) {
      return (
        <DefaultButton onClick={() => handleNext()}>
          {currentStep.step !== 5 ? 'Avançar' : 'Finalizar'}
        </DefaultButton>
      );
    }
  };

  // -------------------------RETURN----------------------------//
  return (
    <S.Container id="container-layout-page">
      {(loading ||
        isLoading ||
        loadingProducts ||
        loadingBases ||
        loadingRascunho) && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}

      {!isLoading && !loadingProducts && !loadingBases && !loadingRascunho && (
        <div style={{ display: loading ? 'none' : 'block' }}>
          {isEdit && (
            <Sidebar
              page={currentStep.step - 1 || 0}
              setPage={setCurrentStep}
              setChanged={setChanged}
              steps={steps}
            />
          )}
          <S.TemplatePage className={isEdit ? 'isEditTemplate' : ''}>
            {!isEdit && (
              <Stepper
                steps={steps.slice(0, 5)}
                currentStep={currentStep.step}
              />
            )}

            <S.HeaderPage className={isEdit ? 'isEditHeader' : ''}>
              <div className="titlePage">
                {!isEdit ? currentStep.pageTitle : currentStep.page}
              </div>
              {isEdit && (
                <div className="headerButtons">
                  <DefaultButton
                    children="Imprimir"
                    onClick={() => navigate(`/layout/pdf/${id}`)}
                  />
                </div>
              )}
            </S.HeaderPage>

            <div style={{ marginBottom: '60px' }}>
              {!isLoading && !loadingProducts && !loadingBases && changePage()}
            </div>

            {currentStep.step < 6 && (
              <S.ControllerArea>
                <div className="statusArea">
                  {changed && 'Alterações não salvas'}
                  {!changed && 'Informações Atualizadas'}
                </div>

                <div className="buttonsArea">
                  <GhostButton
                    className="backArea"
                    onClick={() => handleBackPage()}
                  >
                    Voltar
                  </GhostButton>

                  {saveButton()}
                  {nextButton()}
                </div>
              </S.ControllerArea>
            )}
          </S.TemplatePage>
        </div>
      )}

      <ConfirmModal
        open={hasRascunho}
        title="Deseja continuar o rascunho?"
        buttonText="Continuar"
        onClick={continueRascunho}
        subtitle="Você possui um rascunho salvo para esse cliente"
        handleClose={discardRascunho}
        titleIcon={
          <SaveAsOutlinedIcon
            htmlColor={theme.palette.brand.secondary.natural}
          />
        }
      />
    </S.Container>
  );
};

export default ConfigLayout;
