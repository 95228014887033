import React from 'react';

import { Divider } from '@mui/material';
import Tabs from 'components/Tabs';
import Podium from 'components/Podium';
import InfoCard from 'components/Cards/InfoCard';
import Header from './components/Header';
import Resumo from './components/Resumo';
import { DefaultTable } from 'components/_Table/templates/default';

const Ranking = ({
  title,
  headerInfo,
  headerActions,
  headerTabs = null,
  podium = [],
  resumo = null,
  loading = false,
  infoMessage = null,
  tableProps = {},
}) => {
  return (
    <>
      <Header title={title} actions={headerActions} info={headerInfo} />

      {headerTabs && (
        <>
          <Tabs {...headerTabs} />
          <Divider />
        </>
      )}

      {infoMessage ? <InfoCard message={infoMessage} key="info" /> : <br />}

      <Podium data={podium} loading={loading} />
      <br />
      <DefaultTable {...tableProps} />

      {resumo && <Resumo data={resumo} />}
    </>
  );
};

export default Ranking;
