import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import Ranking from 'pages/_templates/Ranking';

import { SaveAlt } from '@mui/icons-material';
import { trackEvent } from 'utils/mixpanel';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePlans } from 'hooks/usePlans';
import { useQuery } from 'react-query';

import {
  requestDriversRanking,
  requestDriversResumo,
  requestExcel,
} from './services';
import { categorias, columns, getExcelFields, fields } from './constants';
import { formatNameDate, formatNewHour } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import { getRouterQueryParams } from 'utils/router-query-params';

const RankingMotoristas = () => {
  const navigate = useNavigate();
  const { planosAtivos, isOpLogistico, isProvider, hasTorrePlus } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);
  const filter = useSelector(state =>
    isProvider ? state.filterProvider : state.filter,
  );
  const filterRanking = useSelector(state =>
    isProvider ? state.filterRankingDriverProvider : state.filterRankingDriver,
  );

  const [tab, setTab] = useState('geral');
  const [query, setQuery] = useState(null);
  const [loadingTab, setLoadingTab] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [resetTable, setResetTable] = useState(false);
  const [podium, setPodium] = useState([]);

  const order = getRouterQueryParams({ location: useLocation }).get('order');

  const {
    isFetching,
    isLoading,
    data: resData,
  } = useQuery(
    ['ranking', query],
    () => query && requestDriversRanking(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: res => {
        const podium = res?.data?.podio || null;
        if (podium)
          setPodium(
            podium.map(item => ({
              nameOne: item.nome ?? item.nome_motorista,
              position: item.posicao,
              oldPosition: item.posicao_anterior
                ? item.posicao_anterior > item.posicao
                  ? 'UP'
                  : item.posicao_anterior < item.posicao
                  ? 'DOWN'
                  : 'EQUAL'
                : 'UP',
              logo: item.foto,
              value: item.valor ?? item.total_pontos,
            })),
          );
      },
      onSettled: () => {
        setLoadingTab(false);
        resetTable && setResetTable(false);
      },
    },
  );

  const {
    isFetching: isFetchingResumo,
    isLoading: isLoadingResumo,
    data: resResumo,
  } = useQuery(
    ['ranking-resumo', query],
    () => query && requestDriversResumo(query),
    {
      refetchOnWindowFocus: false,
      onSettled: () => {
        setLoadingTab(false);
        resetTable && setResetTable(false);
      },
    },
  );

  // Troca do tab (filterDesvios.status)
  const handleStatusTab = async (event, newValue) => {
    // setSelectedCard(null);
    setLoadingTab(true);
    setTab(newValue);
  };

  // Configura as tabs da tela
  const headerTabs = {
    value: tab,
    items: categorias.filter(
      item =>
        !item.plan ||
        planosAtivos.some(plano => plano.id_do_plano === item.plan),
    ),
    onChange: handleStatusTab,
    disabled: isLoading,
  };

  const initial = useRef(true);
  useEffect(() => {
    if (tab === 'geral') {
      setQuery({
        ...filter,
        empresas: filter.empresas ? filter.empresas.split(',') : '',
        ...filterRanking,
        desvio: filterRanking.desvio ? filterRanking.desvio.split(',') : '',
        tipo: tab,
      });
      setResetTable(true);
      return;
    }
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, filterRanking, tab]);

  const handleExportar = useCallback(
    async query => {
      trackEvent(user, 'EXPORTAR RANKING');
      setLoadingExcel(true);

      const newFields = getExcelFields(query.tipo).filter(
        item =>
          !item.plans ||
          planosAtivos.some(({ id_do_plano }) =>
            item.plans.includes(id_do_plano),
          ),
      );
      const formatedDate = formatNameDate(new Date());
      const newQuery = { ...query, excelFields: newFields };

      const res = await requestExcel(newQuery, isProvider);
      if (res.excel) {
        ExportToExcel({
          excel: res.excel,
          name: `ranking_motoristas_${formatedDate}`,
        });
        toast.success(res.message);
      } else if (res.message) toast.error(res.message);

      setLoadingExcel(false);
    },
    [planosAtivos],
  );

  const formattedColumns = useMemo(
    () =>
      columns[query?.tipo]
        ?.filter(
          col =>
            !col.plans ||
            planosAtivos.some(({ id_do_plano }) =>
              col.plans.includes(id_do_plano),
            ),
        )
        .map(item => {
          if (item.id === 'nome_motorista') {
            if (hasTorrePlus)
              return {
                ...item,
                subRow: {
                  value: (_, item) => item?.nome_empresa,
                },
              };
            if (!isOpLogistico)
              return {
                ...item,
                subRow: {
                  prefix: 'Filial:',
                  value: (_, item) => item?.nome_filial,
                },
              };
          }
          return item;
        }),
    [query, isOpLogistico],
  );

  const actions = [
    {
      title: 'Ver perfil',
      function: (id, item) => {
        if (item.id_motorista) navigate(`/motoristas/${item.id_motorista}`);
        else
          toast.error('Não foi possível acessar os detalhes deste motorista.');
      },
    },
  ];

  const formatResumo = useMemo(() => {
    return fields[tab]
      .filter(
        item =>
          !item.plans ||
          planosAtivos.some(({ id_do_plano }) =>
            item.plans.includes(id_do_plano),
          ),
      )
      .map(item => {
        const data = resResumo?.data?.data[item.id] || '';
        return {
          ...item,
          value: item.value ? item.value(data) : data,
        };
      });
  }, [resResumo, fields, tab]);

  return (
    <Ranking
      title="Ranking"
      headerInfo=""
      headerActions={[
        {
          title: 'Exportar',
          variant: 'ghost',
          icon: <SaveAlt />,
          function: () => handleExportar(query),
          loading: loadingExcel,
        },
      ]}
      headerTabs={headerTabs}
      podium={podium}
      loading={isFetching}
      tableProps={{
        local: tab === 'geral',
        loading: isLoading || loadingTab,
        data: resData?.data?.data
          ? resData.data.data.map(item => ({
              ...item,
              media: resData.data.media,
            }))
          : [],
        pageCount: resData?.data?.total || 0,
        columns: formattedColumns,
        actions,
        reset: resetTable,
        searchKeys: ['nome'],
        placeholder: 'Buscar por Motorista',
        sortBy: { id: 'posicao', order: order ?? 'ASC' },
        setQuery: q =>
          setQuery({
            ...filter,
            empresas: filter.empresas ? filter.empresas.split(',') : '',
            ...filterRanking,
            desvio: filterRanking.desvio ? filterRanking.desvio.split(',') : '',
            ...q,
            tipo: tab,
          }),
        searchEvent: search =>
          trackEvent(user, 'Busca Ranking de Motoristas', null, search),
        empty: {
          image: 'motorista.png',
          title: 'Ops! Não foram encontrados motoristas para essa busca.',
          description:
            'Altere os filtros ou faça o cadastro do novo motorista!',
        },
      }}
      resumo={formatResumo}
    />
  );
};

export default RankingMotoristas;
