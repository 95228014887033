import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  overflow-y: scroll;
  margin-bottom: 5px;
`;

const Status = styled.div`
  ${({ theme, ...props }) => css`
    padding: 4px 10px;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    background-color: ${`${props.color}30`};

    p {
      color: ${props.color};
      font: normal normal bold 18px/18px Texta;
    }
  `}
`;

const SpacedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ColumnWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.system.overlay};
    border-radius: 4px;
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 25px;
  `}
`;

const ImageContainer = styled.div`
  width: 100%;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  position: relative;

  :hover {
    width: 99%;
    opacity: 0.9;
    transition-duration: 300ms;
  }

  img {
    width: 100%;
    border-radius: 5px;
  }

  .playVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
    cursor: pointer;

    :hover {
      opacity: 1;
      transition-duration: 300ms;
    }
  }

  .expandImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.1;
    cursor: pointer;

    :hover {
      opacity: 1;
      transition-duration: 300ms;
    }
  }
`;

const VideoContainer = styled.div`
  display: block;
  width: 100%;
  position: relative;
  border-radius: 10px;

  video {
    border-radius: 10px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.1;
    cursor: pointer;

    :hover {
      opacity: 1;
      transition-duration: 300ms;
    }
  }
`;

const StyledLogoWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid ${theme.palette.brand.secondary.natural};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const StyledLogo = styled.div`
  ${({ theme, ...props }) => css`
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: ${props.border};
    background-image: ${props.backgroundImage};
    background-color: ${theme.palette.system.border};
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 20px;
      color: ${theme.palette.brand.primary.natural};
      text-align: center;
    }
  `}
`;

const StyledTextWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 16px;
      color: ${theme.palette.words.title.natural};
      font-weight: 600;
      text-transform: capitalize;
      text-align: left;
    }
    p {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }
  `}
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid ${theme.palette.brand.primary.light}4d;

    div {
      display: flex;
      flex-direction: row;
      h2 {
        margin-left: 10px;
      }
    }
  `}
`;

const Title = styled.h1`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: 18px;
    color: ${theme.palette.brand.primary.dark};
    letter-spacing: 0px;
    text-transform: capitalize;
    font-weight: 900;
  `}
`;

export const Paragraph = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: gray;
  letter-spacing: 0px;
  padding: 0px 0px 0px 5px;
`;

export const RightHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    border-top: 1px solid ${theme.palette.brand.primary.light}4d;
  `}
`;

export const RightFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled(Button)`
  min-width: 15px !important;
  max-width: 15px !important;
  padding: 0px !important;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  :hover {
    transform: scale(1.1);
  }

  .MuiButton-startIcon {
    padding: 0px !important;
    margin: 0px !important;
  }
`;

const Container = styled.div`
  ${({ theme }) => css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0px;
    right: 0px;
    width: 550px;
    height: 100%;
    background-color: ${theme.palette.system.overlay};
    padding: 0px 30px;
  `}
`;

const Section = styled.h3`
  ${({ theme }) => css`
    font: normal normal 900 13px/50px Texta;
    justify-content: center;
    color: ${theme.palette.words.subtitle.natural};
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;

    .userArea {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-top: 35px;

      .imageArea {
        width: 50px;
        height: 50px;
        border: 2px solid ${theme.palette.brand.secondary.natural};
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          border-radius: 200px;
          padding: 1px;
        }

        .letters {
          width: 42px;
          height: 42px;
          border-radius: 42px;
          background-color: ${theme.palette.system.border};
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 900;
          color: ${theme.palette.words.title.natural};
          font-size: 19px;
        }
      }

      .textArea {
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        .userName {
          color: ${theme.palette.words.title.natural};
          font-weight: 700;
          font-size: 18px;
          text-transform: capitalize;

          &.error {
            color: ${theme.palette.semantics.feedback.attention.natural};
          }
        }
        .filialName {
          color: ${theme.palette.words.subtitle.natural};
          font-size: 14px;
        }
      }
    }

    .formArea {
      .counter {
        color: ${theme.palette.words.subtitle.natural};
        text-align: end;
        margin-top: -20px;
        margin-right: 8px;
        font-size: 12px;
        opacity: 0.8;
        font-weight: 600;
      }
    }
  `}
`;

export const ImageArea = styled.div`
  ${({ theme }) => css`
    width: 50px;
    height: 50px;
    border: 2px solid ${theme.palette.brand.secondary.natural};
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      border-radius: 200px;
      padding: 1px;
    }

    .letters {
      width: 42px;
      height: 42px;
      border-radius: 42px;
      background-color: ${theme.palette.system.border};
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 900;
      color: ${theme.palette.words.title.natural};
      font-size: 19px;
    }
  `}
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: -40px;
`;

export {
  Main,
  Status,
  SpacedRow,
  ImageContainer,
  VideoContainer,
  ColumnWrapper,
  StyledLogoWrapper,
  StyledLogo,
  StyledTextWrapper,
  IconContainer,
  Header,
  Title,
  Container,
  Section,
};
