import { React, useRef, useMemo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Icon from 'components/Icons';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ReactComponent as add } from 'images/Grupo 12749.svg';

import { Button, SvgIcon, Grid, IconButton } from '@mui/material';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import SelectInput from 'components/Inputs/Select';
import SelectOne from 'components/Inputs/SelectOne';
import Calendar from 'components/Inputs/Calendar';
import DataHour from 'components/Inputs/DataHour';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import TextInput from 'components/Inputs/TextField';
import { useTheme } from 'styled-components';
import * as S from './styled';

import * as request from '../services';

const EditNivel = ({
  handleClose,
  open,
  niveis,
  edited,
  disabled,
  closeModal,
  setUpdated,
}) => {
  const theme = useTheme();
  const [nivel, setNivel] = useState({
    nivel: null,
    descricao: null,
    pontuacao_inicial: 1,
    pontuacao_final: null,
    restricoes: null,
  });
  const {
    user: { user },
  } = useSelector(state => state.auth);

  useEffect(() => {
    if (edited && edited.id) {
      const formatedData = { ...edited };
      for (const key in edited) {
        if (!formatedData[key]) formatedData[key] = '';
      }
      setNivel(formatedData);
    } else {
      cleanFields();
    }
  }, [open]);

  const cleanFields = () => {
    setNivel({
      nivel: null,
      descricao: null,
      pontuacao_inicial: 1,
      pontuacao_final: null,
      restricoes: null,
    });
  };

  const handleChange = (name, value) => {
    if (name === 'pontuacao_inicial' || name === 'pontuacao_final') {
      if (value) {
        value = value.replace(/[^0-9]/g, '');
      }
    }
    setNivel(prev => {
      return { ...prev, [name]: value };
    });
  };

  const handleUpdate = async data => {
    if (
      !data.nivel ||
      !data.descricao ||
      !data.pontuacao_inicial ||
      !data.pontuacao_final ||
      !data.restricoes
    )
      toast.error('Preencha todos os campos obrigatórios!');
    else if (
      parseInt(data.pontuacao_final, 10) < parseInt(data.pontuacao_inicial, 10)
    ) {
      toast.error('A pontuação final não pode ser menor que a inicial');
    } else {
      const idx = niveis.findIndex(n => n.id === edited.id);

      if (niveis[idx - 1]) {
        if (
          parseInt(data.pontuacao_final) >=
          parseInt(niveis[idx - 1].pontuacao_final)
        ) {
          toast.error(
            'A pontuação final ultrapassa o limite da próxima faixa.',
          );
          return 0;
        }
      }

      const req = await request.updateNivel(data);

      if (niveis[idx - 1]) {
        if (
          parseInt(data.pontuacao_final) >=
          parseInt(niveis[idx - 1].pontuacao_inicial)
        ) {
          const editNext = niveis[idx - 1];
          editNext.pontuacao_inicial = parseInt(data.pontuacao_final) + 1;
          if (editNext.pontuacao_inicial === editNext.pontuacao_final) {
            editNext.pontuacao_final = +1;
          }
          const req = await request.updateNivel(editNext);
        } else if (
          parseInt(data.pontuacao_final) <=
          parseInt(niveis[idx - 1].pontuacao_inicial) - 1
        ) {
          const editNext = niveis[idx - 1];
          editNext.pontuacao_inicial = parseInt(data.pontuacao_final) + 1;

          const req = await request.updateNivel(editNext);
        }
      }

      if (req.success) toast.success(req.message);
      else toast.error(req.message);
      setUpdated(true);
      closeModal(false);
    }
  };

  const body = (
    <>
      <S.Container>
        <S.Header>
          <div style={{ alignItems: 'center' }}>
            <>
              <SvgIcon
                sx={{ color: theme.palette.brand.secondary.natural }}
                component={add}
                fontSize="medium"
              />
              <h2>Editar Pontuação</h2>
            </>
          </div>

          <IconButton size="small" onClick={handleClose}>
            <Icon
              sx={{ color: theme.palette.words.subtitle.natural }}
              name="close"
            />
          </IconButton>
        </S.Header>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <S.Section>INFORMAÇÕES OBRIGATÓRIAS</S.Section>
            </Grid>

            <Grid item xs={6} sm={6}>
              <TextInput
                label="Título"
                placeholder="Insira um título"
                onChange={e => handleChange('nivel', e.target.value)}
                multiline
                disabled={false}
                value={nivel.nivel}
                required
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <TextInput
                label="Descrição"
                placeholder="Insira uma descrição"
                onChange={e => handleChange('descricao', e.target.value)}
                multiline
                disabled={false}
                value={nivel.descricao}
                required
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <TextInput
                label="Pontuação inicial"
                placeholder="Informe o começo da faixa"
                onChange={e =>
                  handleChange('pontuacao_inicial', e.target.value)
                }
                multiline
                disabled
                value={nivel.pontuacao_inicial}
                required
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <TextInput
                label="Pontuação final"
                placeholder="Informe o fim da faixa"
                onChange={e => handleChange('pontuacao_final', e.target.value)}
                multiline
                disabled={false}
                value={nivel.pontuacao_final}
                required
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextInput
                label="Restricoes"
                placeholder="Informe as restrições que o motorista estará sujeito"
                onChange={e => handleChange('restricoes', e.target.value)}
                multiline
                disabled={false}
                rows={4}
                value={nivel.restricoes}
                required
              />
            </Grid>
          </Grid>
        </S.Main>

        <S.Footer>
          <div>
            <GhostButton onClick={handleClose} size="medium">
              CANCELAR
            </GhostButton>

            <DefaultButton
              onClick={() => handleUpdate(nivel)}
              size="medium"
              sx={{ marginLeft: '20px' }}
            >
              EDITAR
            </DefaultButton>
          </div>
        </S.Footer>
      </S.Container>
    </>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      {disabled && <Loading />}
    </div>
  );
};

export default EditNivel;
