import {
  getAgregados,
  getStatusItem,
  getTiposDesvio,
  getVeiculos,
  getMotoristas,
  getDesviosTiposPadrao,
} from 'constants/_SERVICES/user';

// Pages
import Drivers from 'pages/Motoristas';
import LogMotorista from 'pages/Motoristas/History';
import DriversRanking from 'pages/MotoristasRanking';
import Ranking from 'pages/RankingMotoristas';
import DriversCalender from 'pages/MotoristasCronogramas';
import { PerfilMotorista } from 'pages/Motoristas/PerfilMotorista';
import DriversUpload from 'pages/Motoristas/ModalAddEmMassa';
import { MotoristasEstatisticas } from 'pages/Estatisticas/Motoristas';

export default {
  '/motoristas': {
    date: true,
    defaults: ['filials', 'clients'],
    pageFilterName: 'filterDriver',
    filters: [
      {
        name: 'agregado',
        placeholder: 'Filtrar por Agregado',
        mode: 'single',
        section: 'Motoristas',
        data: async () => getAgregados(),
      },
      {
        name: 'status',
        placeholder: 'Filtrar por Status',
        mode: 'single',
        section: 'Motoristas',
        data: async () => getStatusItem(),
      },
    ],

    page: <Drivers />,
  },

  '/motoristas/:id': {
    date: false,
    title: 'Perfil do motorista',
    back: -1,
    page: <PerfilMotorista />,
  },

  '/motoristas/add-motoristas': {
    back: -1,
    title: 'Adicionar em Massa - Motoristas',
    page: <DriversUpload />,
  },

  '/logs/driver/:id': {
    back: -1,
    defaults: [],
    page: <LogMotorista />,
  },

  '/motoristas/ranking': {
    date: true,
    defaults: ['filials', 'clients', 'operations'],
    pageFilterName: 'filterRankingDriver',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        mode: 'single',
        section: 'Motoristas',
        data: async () => getTiposDesvio(),
      },
      {
        name: 'veiculo',
        placeholder: 'Filtrar por Veículo',
        mode: 'single',
        section: 'Motoristas',
        data: async () => getVeiculos(),
      },
      {
        name: 'motoristas',
        placeholder: 'Filtrar por Motoristas',
        mode: 'single',
        section: 'Motoristas',
        data: async () => getMotoristas(),
      },
    ],
    page: <DriversRanking />,
  },

  '/motoristas/rankings': {
    date: true,
    defaults: ['filials', 'clients', 'operations'],
    pageFilterName: 'filterRankingDriver',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        mode: 'multiple',
        section: 'Desvio, Veículo e Motorista',
        data: async () => getDesviosTiposPadrao(),
      },
      {
        name: 'veiculo',
        placeholder: 'Filtrar por Tipo de Veículo',
        mode: 'single',
        section: 'Desvio, Veículo e Motorista',
        data: async () => getVeiculos(),
      },
      {
        name: 'motoristas',
        placeholder: 'Filtrar por Tipo de Motorista',
        mode: 'single',
        section: 'Desvio, Veículo e Motorista',
        data: async () => getMotoristas(),
      },
    ],
    page: <Ranking />,
  },

  '/motoristas/cronograma': {
    defaults: ['filials', 'clients', 'operations'],
    pageFilterName: 'filterMotoristaCronograma',
    filters: [
      {
        name: 'agregado',
        placeholder: 'Filtrar por Contratação',
        mode: 'single',
        section: 'Motoristas',
        data: async () => getAgregados(),
      },
    ],
    page: <DriversCalender />,
  },
  '/motoristas/estatisticas': {
    title: 'Motoristas',
    back: -1,
    defaults: [],
    page: <MotoristasEstatisticas />,
  },
};
