import { useEffect } from 'react';
import { Autocomplete, Box, Grid } from '@mui/material';
import TextInput from 'components/Inputs/TextField';
import Search from '@mui/icons-material/Search';
import usePlacesAutocomplete from 'use-places-autocomplete';
import parse from 'autosuggest-highlight/parse';
import axios from 'axios';

const getOpenLatLng = address =>
  axios.get(`https://nominatim.openstreetmap.org/`, {
    params: {
      q: address,
      format: 'json',
    },
  });

const PlacesAutocomplete = ({
  onChange,
  onInputChange,
  value: address,
  label,
  required,
  error = false,
  message = '',
  ...props
}) => {
  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['geocode'],
    },
    debounce: 300,
  });

  const handleInput = e => {
    // Update the keyword of the input element
    if (e) {
      setValue(e.target.value);
      if (onInputChange instanceof Function) onInputChange(e.target.value);
    }
  };

  const handleSelect = (e, value) => {
    if (value) {
      const { description } = value;
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // get lat and lng from OSM
      getOpenLatLng(description).then(({ data }) => {
        if (data.length) {
          const position = {
            lat: Number(data[0].lat),
            lng: Number(data[0].lon),
          };
          if (onChange instanceof Function) onChange(position, description);
        } else if (value.structured_formatting?.secondary_text) {
          getOpenLatLng(value.structured_formatting.secondary_text).then(
            ({ data }) => {
              if (data.length) {
                const position = {
                  lat: Number(data[0].lat),
                  lng: Number(data[0].lon),
                };
                if (onChange instanceof Function)
                  onChange(position, description);
              }
            },
          );
        }
      });

      // Get latitude and longitude via utility functions (Google Places)
      // getGeocode({ address: description }).then(results => {
      //   console.log('results', results);
      //   const position = getLatLng(results[0]);
      //   if (onChange instanceof Function) onChange(position, value);
      // });
    }
  };

  useEffect(() => {
    if (!!address && value !== address) setValue(address, false);
  }, [address]);

  return (
    <Autocomplete
      {...props}
      id="places-autocomplete"
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={x => x}
      options={data}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="Nenhum resultado."
      onChange={handleSelect}
      onBlur={() => clearSuggestions()}
      onInputChange={handleInput}
      renderInput={params => (
        <TextInput
          {...params}
          label={label}
          required={required}
          noAsterisk={!required}
          placeholder="Busque a localização"
          startIcon={<Search />}
          error={error}
          message={message}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map(match => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                {option.structured_formatting.secondary_text}
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default PlacesAutocomplete;
