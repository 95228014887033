/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useTheme } from 'styled-components';

// React
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

// Components
import Loading from 'components/Loading';
import Table from 'components/TableLocal';
import ModalAddFrota from './ModalAddFrota';
import Card from 'components/Cards/Indicador';
import ExcelModal from 'components/ExcelModal';
import InfoCard from 'components/Cards/InfoCard';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';

// Components MUI
import { Grid } from '@mui/material';
import { SaveAlt, EditOutlined, DeleteOutline } from '@mui/icons-material';

// Utils
import { trackEvent } from 'utils/mixpanel';
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import { usePlans } from 'hooks/usePlans';

// Assets
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

// Constants
import {
  fields,
  resetExcelFields,
  columnsFrota,
  infoMessage,
  cardsFrotaInit,
  columnsFrota2,
} from './constants';

// Services
import {
  changeStatus,
  changeStatusMany,
  requestExcel,
  getTrucks,
} from './services';
import { DefaultTable } from 'components/_Table/templates/default';

const sortBy = [
  {
    id: 'placa',
    desc: false,
  },
];

const Frota = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { hasFadiga, hasTelemetria, hasMonitoramento, hasTorrePlus } =
    usePlans();

  // Redux e hooks
  const filter = useSelector(state => state.filterProvider);
  const filterFrota = useSelector(state => state.filterFrotaProvider);
  const user = useSelector(state => state.auth?.user?.user);

  // Data
  const [data, setData] = useState([]);
  const [triggerUpdate, setTriggerUpdate] = useState(true);
  const [loading, setLoading] = useState(false);

  // Edit modal
  const [dataEdit, setdataEdit] = useState(null);

  // Seleção tabela frota
  const [selectedData, setSelectedData] = useState(null);
  const [idConfirmChangeStatus, setIdConfirmChangeStatus] = useState(false);
  const [idsConfirmDesativar, setIdsConfirmDesativar] = useState(false);
  const [idsConfirmAtivar, setIdsConfirmAtivar] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  // Cards
  const [cardsFrota, setCardsFrota] = useState(cardsFrotaInit);

  // Excel Fields
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  // ---------------------  FROTA ---------------------

  const [trucks, setTrucks] = useState([]);

  useEffect(() => {
    const getProviderTrucks = async () => {
      setLoading(true);
      const res = await getTrucks();
      setLoading(false);
      setTrucks(res.data.data);
      setTriggerUpdate(false);
    };
    triggerUpdate && getProviderTrucks();
  }, [triggerUpdate]);

  // Aplica filtros em frota
  useEffect(() => {
    // Cria newData com trucks filtrado
    const newData = trucks.filter(truck => {
      const res =
        (!filter?.empresas ||
          filter?.empresas
            .split(',')
            .includes(truck?.id_da_empresa?.toString())) &&
        (!filter?.filial ||
          filter?.filial
            ?.split(',')
            ?.includes(truck?.id_da_filial?.toString())) &&
        (!filterFrota?.status || filterFrota?.status === truck?.status) &&
        (!filterFrota?.departamento ||
          filterFrota?.departamento === truck?.departamento) &&
        (!+filterFrota?.propriedade ||
          +filterFrota?.propriedade === truck?.propriedade) &&
        (!filterFrota?.carga || filterFrota?.carga === truck?.id_da_carga);
      return res;
    });

    // Atualiza valores dos cards com base nos dados filtrados
    // Armazena dados de newData filtrados pelo card em dataCardSeleted caso algum esteja selecionado
    let dataCardSeleted;
    const newCardsFrota = cardsFrota.map(card => {
      if (card.name === 'ativas') {
        trackEvent(user, 'FROTA: VISUALIZAR MOTORISTAS ATIVOS');
        const cardData = newData.filter(item => item.status === 'ATIVO');
        card.value = cardData.length;
        if (card.selected) dataCardSeleted = cardData;
      } else if (card.name === 'posicaoInadequada') {
        const cardData = newData.filter(item => item.pos_camera === 'NAO');
        card.value = cardData.length;
        if (card.selected) dataCardSeleted = cardData;
      } else if (card.name === 'canalIncorreto') {
        const cardData = newData.filter(item => item.dvr === 'NAO');
        card.value = cardData.length;
        if (card.selected) dataCardSeleted = cardData;
      }
      return card;
    });
    setCardsFrota(newCardsFrota);

    // Atualiza data com newData ou dataCardSeleted se algum card for selecionado
    if (dataCardSeleted) setData(dataCardSeleted);
    else setData(newData);
  }, [
    filter,
    filterFrota,
    cardsFrota[0].selected,
    cardsFrota[1].selected,
    cardsFrota[2].selected,
    trucks,
  ]);

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = { ...filter, ...filterFrota, excelFields: newFields };

    const res = await requestExcel(newQuery);
    if (res.data && res.data?.data?.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `frota_${formatedDate}`,
      });
    else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // Ativa / inativa card clicado. Inativa os demais
  // Seleciona / limpa dados filtrados do card em dataCard
  const handleClickCards = name => {
    trackEvent(user, 'FROTA: DISPONIBILIDADE POR PERÍODO');

    const newCards = cardsFrota.map(card => {
      if (name === card.name) {
        if (card.selected !== undefined) {
          card.selected = !card.selected;
        }
      } else if (card.selected) card.selected = false;
      return card;
    });

    setCardsFrota(newCards);
  };

  const handleEditar = id => {
    trackEvent(user, 'FROTA: EDITAR VEÍCULO');

    const truckEdit = data?.find(truck => String(truck.id) === String(id));
    if (truckEdit) setdataEdit(truckEdit);
  };

  const handleHistorico = id => {
    navigate(`/logs/frota/${id}`);
  };

  const handleSetDesativarAtivar = truckId => {
    const truckEdit = data?.find(truck => String(truck.id) === String(truckId));
    setIdConfirmChangeStatus({ id: truckId, status: truckEdit.status });
  };

  const formatTable = data => {
    if (hasTorrePlus) {
      data = data.filter(item => item?.id !== 'modelo');
    } else {
      data = data.filter(item => item?.id !== 'empresa.nome');
    }
    return data;
  };

  // Finaliza edição de status
  // setTriggerUpdate(true) força atualização dos dados armazenados
  const handleDesativarAtivar = async truckId => {
    setLoadingLines([truckId]);
    const res = await changeStatus(truckId);
    if (res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
    } else if (res.data?.message) toast.error(res.data.message);
    setIdConfirmChangeStatus(null);
    setLoadingLines([]);
  };

  const handleDesativarAtivarMassa = async (newStatus, truckIds) => {
    const data = { newStatus, truckIds };
    setLoadingLines(truckIds);
    const res = await changeStatusMany(data);
    if (res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
    } else if (res.data?.message) toast.error(res.data.message);
    setIdsConfirmDesativar(null);
    setIdsConfirmAtivar(null);
    setLoadingLines([]);
  };

  // ---------------------  RENDER: FROTA  ---------------------

  // Titulo, botoes add e exportar, infocard e tabs
  const renderTop = () => {
    return (
      <>
        <S.TitleWrapper>
          <h1>Frota</h1>

          <div>
            <GhostButton
              startIcon={<SaveAlt />}
              size="medium"
              onClick={() => setOpenExcelModal(true)}
            >
              EXPORTAR
            </GhostButton>
          </div>
        </S.TitleWrapper>

        <div style={{ padding: '0px 0px' }}>
          <InfoCard message={infoMessage} key={1} />
        </div>
      </>
    );
  };

  const renderCards = () => {
    return (
      <Grid container spacing={2} marginBottom="25px">
        {cardsFrota?.map(card => {
          return (
            <Grid item key={card.name} xs={12} md={4} xl={4}>
              <Card
                value={card.value}
                icon={card.icon}
                text={card.text}
                handleClick={() => handleClickCards(card.name)}
                selected={card.selected}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  // ---------------------  RENDER: FROTA ---------------------

  const renderTableFrota = () => {
    const actions = [
      { title: 'Ver detalhes', function: handleEditar },
      {
        title: 'Histórico alterações',
        function: handleHistorico,
      },
      {
        title: 'Ativar/Desativar',
        function: handleSetDesativarAtivar,
      },
    ];

    const bulkActions = [
      {
        title: 'Desativar',
        function: () => {
          trackEvent(user, 'FROTA: DESATIVAR TODOS OS VEÍCULOS');
          setIdsConfirmDesativar(selectedData);
        },
      },
      {
        title: 'Ativar',
        function: () => {
          trackEvent(user, 'FROTA: ATIVAR TODOS OS VEÍCULOS');
          setIdsConfirmAtivar(selectedData);
        },
      },
    ];

    return (
      <DefaultTable
        data={data || []}
        columns={formatTable(columnsFrota2)}
        actions={actions}
        bulk={bulkActions}
        searchKeys={['placa', 'frota', 'modelo']}
        loading={loading}
        placeholder="Buscar por Placa, Frota ou Modelo"
        setSelectedRows={setSelectedData}
        sortBy={{ id: 'placa', order: 'ASC' }}
        loadingSelection={loadingLines}
        searchEvent={search =>
          trackEvent(user, 'Busca Gestão de Frotas', null, search)
        }
      />
    );
  };

  const renderModalEditFrota = () => {
    return (
      dataEdit && (
        <ModalAddFrota
          open={Boolean(dataEdit)}
          dataEdit={dataEdit}
          handleClose={() => setdataEdit(null)}
          setTriggerUpdate={setTriggerUpdate}
        />
      )
    );
  };

  // ---------------------  RENDER: DISPONIBILIDADE ---------------------

  const checkFilds = excelFields => {
    let tmp = excelFields;

    if (!hasTelemetria) {
      tmp = tmp.filter(item => item.value !== 'telemtria_ultima_posicao');
    }
    if (!hasMonitoramento) {
      tmp = tmp.filter(item => item.value !== 'fadiga_ultimo_evento');
    }
    if (!hasFadiga) {
      tmp = tmp.filter(item => item.value !== 'camera_ultimo_video');
    }
    return tmp;
  };

  // Componentes da página
  const renderMain = () => (
    <>
      <S.Main>
        {renderTop()}
        {renderCards()}
        {renderTableFrota()}
      </S.Main>

      {renderModalEditFrota()}

      {
        /* Confirmação de ativacao / desativacao */
        idConfirmChangeStatus && (
          <ConfirmModal
            open={Boolean(idConfirmChangeStatus)}
            handleClose={() => setIdConfirmChangeStatus(null)}
            title={
              idConfirmChangeStatus.status === 'ATIVO'
                ? 'Deseja desativar este veículo?'
                : 'Deseja reativar este veículo?'
            }
            titleIcon={
              idConfirmChangeStatus.status === 'ATIVO' ? (
                <DeleteOutline
                  sx={{
                    color: theme.palette.semantics.feedback.attention.natural,
                  }}
                  fontSize="medium"
                />
              ) : (
                <EditOutlined
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
              )
            }
            subtitle="Veículos inativos não geram avaliações e não são contabilizados na disponibilidade!"
            buttonText="Confirmar"
            onClick={() => {
              trackEvent(
                user,
                idConfirmChangeStatus.status === 'ATIVO'
                  ? 'FROTA: DESATIVA VEÍCULO'
                  : 'FROTA: ATIVAR VEÍCULO',
              );
              handleDesativarAtivar(idConfirmChangeStatus.id);
            }}
            loading={loading}
          />
        )
      }

      {
        /* Confirmação de ativacao em massa */
        idsConfirmAtivar && (
          <ConfirmModal
            open={Boolean(idsConfirmAtivar)}
            handleClose={() => setIdsConfirmAtivar(null)}
            title={`Deseja reativar ${idsConfirmAtivar.length} veículos?`}
            titleIcon={
              <EditOutlined
                sx={{ color: theme.palette.brand.secondary.natural }}
                fontSize="medium"
              />
            }
            subtitle="Todos os veículos marcados serão reativados!"
            buttonText="Confirmar"
            onClick={() =>
              handleDesativarAtivarMassa('ATIVO', idsConfirmAtivar)
            }
            loading={loading}
          />
        )
      }

      {
        /* Confirmação de desativacao em massa */
        idsConfirmDesativar && (
          <ConfirmModal
            open={Boolean(idsConfirmDesativar)}
            handleClose={() => setIdsConfirmDesativar(null)}
            title={`Deseja desativar ${idsConfirmDesativar.length} veículos?`}
            titleIcon={
              <DeleteOutline
                sx={{
                  color: theme.palette.semantics.feedback.attention.natural,
                }}
                fontSize="medium"
              />
            }
            subtitle="Todos os veículos marcados serão desativados!
          Veículos inativos não geram avaliações e não são contabilizados na disponibilidade."
            buttonText="Confirmar"
            onClick={() =>
              handleDesativarAtivarMassa('INATIVO', idsConfirmDesativar)
            }
            loading={loading}
          />
        )
      }

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={checkFilds(excelFields)}
          loading={loadingExcel}
        />
      )}
    </>
  );

  return loading ? <Loading /> : renderMain();
};

export default Frota;
