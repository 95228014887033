/* eslint-disable import/order */
import React, { useState, useEffect } from 'react';
// Styles
import * as S from './styled';

// Components
import { Select } from './Select';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { Popover, SvgIcon } from '@mui/material';

// Assets
import { ReactComponent as filterIcon } from 'images/icons/filter.svg';

export const Filter = ({
  sections = [],
  keys,
  keysDefault,
  setFilters,
  selecteds = null,
}) => {
  // Variables
  const pgFilters = keys;
  const glFilters = keysDefault;

  // Filter States
  const [countFilter, setCountFilter] = useState(0);
  const [filtersTemp, setFiltersTemp] = useState(selecteds);
  const [filterLabel, setFilterLabel] = useState('Filtros');
  const [selectOption, setSelectOption] = useState('Filtros');

  // Modal Infos
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  // Inicializa objetos
  const globalState = {};
  const pageState = {};
  if (glFilters) for (const key of glFilters) globalState[key] = '';
  if (pgFilters) for (const key of pgFilters) pageState[key] = '';

  const handleClick = e => setAnchorEl(e.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = () => {
    // Redefinir filtersTemp para os valores padrão
    setFiltersTemp(prev => {
      const newState = { ...prev };
      Object.keys(newState).forEach(key => (newState[key] = ''));
      return newState;
    });
    setSelectOption(null);
    const buttonApply = document.getElementById('aplicar');
    buttonApply?.focus();
  };

  const handleApply = () => {
    if (selectOption) {
      setFilterLabel(selectOption.label);
    } else {
      setFilterLabel('Filtros');
    }
    setFilters(filtersTemp);
    handleClose();
  };

  const handleChange = (value, name) => {
    setFiltersTemp(prev => ({ ...prev, [name]: value }));

    const section = sections.find(section => section.name === name);
    const selectedOption = section.data.find(option => option.value === value);
    setSelectOption(selectedOption);
  };

  useEffect(() => {
    const initialState = sections.reduce((acc, section) => {
      acc[section.name] = section.defaultValue || '';
      return acc;
    }, {});

    if (filtersTemp) {
      if (Object.keys(filtersTemp).length === 0 && sections.length > 0) {
        setFiltersTemp(initialState);
      }
    }
  }, []);

  return (
    <>
      <S.StyledButton
        // endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
        startIcon={<SvgIcon component={filterIcon} />}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        anchorel={anchorEl}
        style={{ backgroundColor: '#fff', overflow: 'hidden' }}
      >
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: 100,
          }}
        >
          {filterLabel}
        </div>
        <S.Count count={countFilter}>{countFilter}</S.Count>
      </S.StyledButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <S.Main>
          <S.Header>
            <h2>Filtros</h2>
            <S.ClearButton onClick={handleClear}>Limpar filtros</S.ClearButton>
          </S.Header>
          <S.SelectsContainer>
            {sections.map((section, idx) => (
              <S.SelectWrapper key={idx}>
                <Select
                  placeholder={section.placeholder}
                  data={section.data}
                  value={filtersTemp ? filtersTemp[section.name] || '' : ''}
                  setValue={val => handleChange(val, section.name)}
                />
              </S.SelectWrapper>
            ))}
          </S.SelectsContainer>

          <S.Footer>
            <GhostButton onClick={handleClose} size="medium" id="cancelar">
              CANCELAR
            </GhostButton>

            <DefaultButton onClick={handleApply} size="medium" id="aplicar">
              APLICAR
            </DefaultButton>
          </S.Footer>
        </S.Main>
      </Popover>
    </>
  );
};
