import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { subHours } from 'date-fns';

// components custom
import { PeriodPicker } from 'pages/Estatisticas/Components/PeriodPicker';
import Tabs from 'components/Tabs';
// styles
import * as S from './styled';

const CustomTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#95AAC9"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

export const HistoryPoints = ({
  tab = 'ptsHr',
  tabs = [],
  data = [],
  dates,
  handleDate,
  handleChangeTab = () => false,
  print = false,
}) => {
  const handleChange = value => {
    handleChangeTab(value);
  };

  return (
    <S.Container print={print}>
      <div className="container-filters">
        <div className="titletop">
          <div className="graphtitle">Histórico de pontos</div>
          <PeriodPicker
            finalDate={dates.finalDate}
            initialDate={dates.initialDate}
            period="lastYear"
            periodAdd="month"
            onChange={date => {
              handleDate({
                initialDate: date.initialDate,
                finalDate: subHours(date.finalDate, 3),
              });
            }}
          />
        </div>
        <div>
          <Tabs
            value={tab}
            onChange={(e, value) => handleChange(value)}
            items={tabs}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          height={300}
          data={data}
          margin={{ top: 10, right: 40, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            height={70}
            tick={<CustomTick />}
            interval={0}
          />
          <YAxis />
          <Tooltip />

          <Line
            type="linear"
            dataKey="x"
            name="Valor"
            stroke="#0C12F2"
            dot={props => {
              const { index, payload } = props;
              const isLastDot = index === data.length - 1;
              return (
                <circle
                  cx={props.cx}
                  cy={props.cy}
                  r={5}
                  fill={isLastDot ? 'none' : '#0C12F2'} // Se for o último ponto, não preenche
                  stroke={isLastDot ? '#0C12F2' : 'none'} // Se for o último ponto, define a cor da borda
                  strokeWidth={isLastDot ? 2 : 0} // Se for o último ponto, define a largura da borda
                />
              );
            }}
          />

          <Line
            type="linear"
            dataKey="meta"
            name="Meta"
            stroke="#F64E60"
            strokeDasharray={['5 5']}
            dot={props => {
              const { index, payload } = props;
              const isLastDot = index === 11; // Verifica se é o último ponto
              return (
                <circle
                  cx={props.cx}
                  cy={props.cy}
                  r={5}
                  fill={isLastDot ? 'none' : '#F64E60'}
                  stroke={isLastDot ? '#F64E60' : 'none'}
                  strokeWidth={isLastDot ? 2 : 0}
                />
              );
            }}
          />
        </LineChart>
      </ResponsiveContainer>
      <div className="legend-container">
        <div className="legend">
          <div className="format-point">
            <div className="point2" />
            Valor
          </div>
          <div className="format-point">
            <div className="point1" />
            Meta
          </div>
        </div>
      </div>
    </S.Container>
  );
};
