import api from 'services/api';
import { toast } from 'react-toastify';
import qs from 'qs';

// ------------------------------------ENDPOINTS DE LAYOUT-----------------------------------------//

export const requestOcorrenciasPad = async query => {
  const res = await api.get(`/ocorrencias`, { params: query });
  return res;
};

export const requestOcorrencia = async id => {
  const res = await api.get(`/ocorrencia/${id}`);
  return res.data;
};

export const updateOcorrencia = async data => {
  const res = await api.put('/ocorrencia-update', data);
  return res;
};

export const requestCards2 = async query => {
  const res = await api.get('/ocorrencias-cards', { params: query });
  return res;
};

export const requestExcel = async query => {
  const res = await api.get('/excel/pad', { params: query });
  return res;
};

export const finishOcorrencia = async data => {
  const res = await api.put('/ocorrencia-finalizar', { ids: data });
  return res.data;
};

export const deleteOcorrencia = async data => {
  const res = await api.put('/ocorrencia-deletar', { ids: data });
  return res.data;
};

export const refuseOcorrencia = async data => {
  const res = await api.put('/ocorrencia-recusar', { ids: data });
  return res.data;
};

export const createOcorrencia = async data => {
  const res = await api.post('/ocorrencia-store', data);
  return res;
};

export const selectResponsibles = async () => {
  const res = await api.get('/select-responsibles-ocorrencias');
  return res.data;
};

export const selectDrivers = async () => {
  const res = await api.get('/select-drivers?all=1');
  return res.data;
};

export const selectViolacoes = async () => {
  const res = await api.get('/select-violacoes-empresa');
  return res.data;
};

export const selectClients = async () => {
  const res = await api.get('/select-clients');
  return res.data;
};

export const requestOperations = async () => {
  const res = await api.get('/select-operations');
  return res.data;
};

export const reviewOcorrencia = async data => {
  const res = await api.put('/ocorrencia-revisar', data);
  return res.data;
};
