export const filtersGraficoParams = [
  {
    name: 'empresas',
    placeholder: 'Filtrar por Empresa',
    data: [],
  },
];

export const columns = [
  {
    header: 'Empresa',
    id: 'empresa',
    type: 'string',
    sort: true,
    width: 250,
  },
  {
    header: 'Km',
    id: 'kms',
    sort: true,
    type: 'number',
    align: 'center',
    plans: [100, 110],
  },
  {
    header: 'Horas',
    id: 'horas',
    sort: true,
    type: 'number',
    align: 'center',
    plans: [140],
  },
  {
    header: 'Ptos/Km',
    id: 'ptsKm',
    sort: true,
    type: 'number',
    align: 'center',
    plans: [100, 110],
    value: value =>
      Number(value).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
  },
  {
    header: 'Ptos/Hr',
    id: 'ptsHr',
    sort: true,
    type: 'number',
    align: 'center',
    plans: [140],
    value: value =>
      Number(value).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
  },
  {
    header: 'Motoristas Suspensos',
    id: 'motoristas_suspensos',
    sort: true,
    type: 'number',
    align: 'center',
  },
];
