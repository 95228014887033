import React, { useState, useEffect } from 'react';
import { PlayCircle, Close, OpenInFull } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import ImageModal from 'components/ImageModal';
import * as S from './styled';

const ImageOcorrencia = ({ ocorrencia, onlyImage }) => {
  const theme = useTheme();
  const [expandedImage, setExpandedImage] = useState(false);

  return (
    <>
      {(ocorrencia.desvio.img_ocorrencia || onlyImage) && (
        <S.ImageContainer>
          <img src={ocorrencia.desvio.img_ocorrencia} alt="imagem_desvio" />

          {ocorrencia.desvio.img_ocorrencia && (
            <div className="expandImage" onClick={() => setExpandedImage(true)}>
              <OpenInFull
                sx={{
                  color: theme.palette.brand.secondary.natural,
                  fontSize: 40,
                }}
              />
            </div>
          )}
        </S.ImageContainer>
      )}

      <ImageModal
        open={expandedImage}
        handleClose={() => setExpandedImage(false)}
        image={ocorrencia.desvio.img_ocorrencia}
      />
    </>
  );
};

export default ImageOcorrencia;
