export const columnsViolacoes2 = [
  {
    header: 'Ativo',
    id: 'status',
    sort: true,
  },
  {
    header: 'Violação',
    id: 'descricao',
    sort: true,
    type: 'string',
  },
  {
    header: 'Pontos',
    id: 'pontos',
    sort: true,
    type: 'number',
  },
  {
    header: 'Decaimento (dias)',
    id: 'decaimento_tempo',
    sort: true,
    type: 'number',
  },
  {
    header: 'Expiração (dias)',
    id: 'prazo',
    sort: true,
    type: 'number',
  },
  {
    header: 'Plano de ação',
    id: 'plano_de_acao',
    sort: true,
    type: 'string',
  },
];

export const columnsNiveis2 = [
  {
    header: 'Título',
    id: 'nivel',
    sort: true,
    type: 'string',
  },
  {
    header: 'Descrição',
    id: 'descricao',
    sort: true,
    type: 'string',
  },
  {
    header: 'Pontuacao inicial',
    id: 'pontuacao_inicial',
    sort: true,
    type: 'string',
  },
  {
    header: 'Restrições',
    id: 'restricoes',
    sort: true,
    type: 'string',
  },
];
