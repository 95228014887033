import api from 'services/api';

export const requestRanking = async query => {
  const res = await api.get(`/pad/ranking`, { params: query });
  return res.data;
};

export const requestNiveis = async () => {
  const res = await api.get(`/pad-nivel`);
  return res.data;
};

export const requestConfig = async () => {
  const res = await api.get('/select-padconfig');
  return res.data;
};

export const requestViolacoes = async () => {
  const res = await api.get('/pad/configuracoes/violacoes');
  return res.data;
};

export const requestViolacao = async id => {
  const res = await api.get(`/violacao/${id}`);
  return res.data;
};

export const registerViolacao = async violacao => {
  const res = await api.post(`/violacao`, violacao);
  return res.data;
};

export const editViolacao = async violacao => {
  const idViolacao = violacao.id;
  const res = await api.put(`/violacao/${idViolacao}`, violacao);
  return res.data;
};

export const registerNivel = async nivel => {
  const res = await api.post('/pad-nivel', nivel);
  return res.data;
};

export const deleteNivel = async id => {
  const res = await api.delete(`/pad-nivel/${id}`);
  return res.data;
};

export const updateNivel = async pontuacao => {
  const idPontuacao = pontuacao.id;
  const res = await api.put(`/pad-nivel/${idPontuacao}`, pontuacao);
  return res.data;
};
