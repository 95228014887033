import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFilter } from 'store/modules/filterDesvio/actions';
import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { SaveAlt, Check } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';

import { formatNameDate, formatNewDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import { useTheme } from 'styled-components';

import Tabs from 'components/Tabs';
import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import Card from 'components/Cards/Indicador';
import ExcelModal from 'components/ExcelModal';
import ConfirmModal from 'components/ConfirmModal';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';
import { trackEvent } from 'utils/mixpanel';
import ApproveModal from './ApproveModal';
import FinishModal from './FinishModal';
import DeleteModal from './DeleteModal';
import { DefaultTable } from 'components/_Table/templates/default';

import {
  infoMessage,
  statusTabs,
  fields,
  resetExcelFields,
  columns2,
} from './constants';

import {
  requestInfractions,
  requestCards,
  requestExcel,
  approveInfractions,
  deleteInfractions,
  finishInfractions,
  viewInfractions,
  sendPadInfractions,
} from './services';

import * as S from './styled';
import { getRouterQueryParams } from '../../utils/router-query-params';
import { useQuery } from 'react-query';

const Desvios = () => {
  const theme = useTheme();
  // Redux e hooks
  const filter = useSelector(state => state.filter);
  const filterDesvio = useSelector(state => state.filterDesvio);
  const user = useSelector(state => state.auth?.user?.user);
  const dispatch = useDispatch();

  const userLevel = user.nivel;

  const [query, setQuery] = useState(null);
  const [loadingTab, setLoadingTab] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedInfractions, setSelectedInfractions] = useState([]);

  const [idsApproveModal, setIdsApproveModal] = useState(null);
  const [idsFinishModal, setIdsFinishModal] = useState(null);
  const [idsViewModal, setIdsViewModal] = useState(null);
  const [desvioDeleteModal, setDesvioDeleteModal] = useState(false);
  const [idsPadModal, setIdsPadModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  // Excel Fields
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const navigate = useNavigate();

  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  const {
    refetch: fetchData,
    isFetching,
    isLoading: loadingInfractions,
    data: resData,
  } = useQuery(
    ['infractions-index', query],
    () => query && requestInfractions(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        setLoadingTab(false);
        resetTable && setResetTable(false);
      },
    },
  );

  const getExpirationDate = row => {
    const parameters = resData?.data?.parametros;
    if (parameters) {
      let newDate = new Date(row.createdAt);
      // console.log('initial date', newDate);
      if (row.id_da_distribuidora) {
        if (
          row.aprovado_distribuidora === 0 &&
          !!row.data_alteracao_distribuidora
        ) {
          newDate = new Date(row.data_alteracao_distribuidora);
          return formatNewDate(newDate);
        }
        if (
          row.aprovado_distribuidora === 3 &&
          !!row.data_revisao_distribuidora
        ) {
          newDate = new Date(row.data_revisao_distribuidora);
          return formatNewDate(newDate);
        }
        if (parameters?.clientes.length) {
          const newValue = parameters.clientes.find(
            item => item.id_distribuidora === row.id_da_distribuidora,
          );
          if (newValue) newDate.setDate(newDate.getDate() + +newValue.valor);
          else newDate.setDate(newDate.getDate() + +parameters.valor);
          return formatNewDate(newDate);
        }

        return formatNewDate(
          newDate.setDate(newDate.getDate() + +parameters.valor),
        );
      }
      newDate.setDate(newDate.getDate() + +parameters.valor);
      return formatNewDate(newDate);
    }

    return '-';
  };
  const handleColumns = () => {
    const col = [...columns2].map(item => {
      if (item.id === 'createdAt') {
        return {
          ...item,
          subRow: {
            prefix: 'Vencimento:',
            value: (_, item) =>
              formatNewDate(item ? item?.data_vencimento : '-'),
          },
        };
      }
      return item;
    });

    if (filterDesvio.status === 'FINALIZADO')
      col[5] = {
        header: 'Data Conclusão',
        id: 'data_encerramento',
        type: 'date',
        sort: true,
      };
    else if (filterDesvio.status === 'DELETADO')
      col[5] = {
        header: 'Data Exclusão',
        id: 'data_exclusao',
        type: 'date',
        sort: true,
      };

    return col;
  };

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cads
  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    ['infractions-cards', { ...filter, ...filterDesvio }],
    () =>
      requestCards({
        ...filter,
        ...filterDesvio,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const mode = getRouterQueryParams({ location: useLocation }).get('mode');

  useEffect(() => {
    mode &&
      dispatch(
        setFilter({
          status: mode,
        }),
      );
  }, [mode]);

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, filterDesvio, selectedCard]);

  // Troca do tab (filterDesvio.status)
  const handleStatusTab = async (event, newValue) => {
    setSelectedCard(null);
    setLoadingTab(true);
    dispatch(
      setFilter({
        status: newValue,
      }),
    );
  };

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor válido: nao nulo e maior que 0.
  const handleClickCard = type => {
    if (!loadingCards && !loadingInfractions) {
      const cards = resCards?.data?.data || [];
      const card = cards.find(item => item.type === type);
      if (card.value) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = { ...filter, ...filterDesvio, excelFields: newFields };

    const res = await requestExcel(newQuery);
    if (res.data && res.data?.data?.excel) {
      ExportToExcel({
        excel: res.data.data.excel,
        name: `desvios_${formatedDate}`,
      });
      toast.success(res.data.message);
    } else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // ********************  ACTIONS / BULKACTIONS *****************************
  // Funções das ações
  const handleAprovar = async data => {
    setLoadingModal(true);
    setLoadingLines(data?.ids || []);

    const res = await approveInfractions(data);
    if (res.data?.success) {
      fetchCards();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setIdsApproveModal(null);
  };

  const handleFinalizar = async data => {
    setLoadingModal(true);
    setLoadingLines(data?.ids || []);

    const res = await finishInfractions(data);
    if (res.data?.success) {
      fetchCards();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setIdsFinishModal(null);
  };

  const handleVisualizar = async () => {
    setLoadingModal(true);

    const data = { ids: idsViewModal };
    setLoadingLines(idsViewModal);
    const res = await viewInfractions(data);
    if (res.data?.success) {
      fetchCards();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setIdsViewModal(null);
  };

  const handleDeletar = async data => {
    setLoadingModal(true);
    setLoadingLines(data?.ids || []);
    const res = await deleteInfractions(data);
    if (res.data?.success) {
      fetchCards();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setDesvioDeleteModal(null);
  };

  const verifyIdsSendPad = ids => {
    let error = false;
    for (const id of ids) {
      const data = resData?.data?.rows || [];
      const infraction = data.find(i => i.id == id);
      if (infraction?.ocorrencia) {
        toast.error(`O desvio ${id} já foi enviado para o PAD.`);
        error = true;
      }
    }
    if (!error) setIdsPadModal(ids);
  };

  const handleSendPad = async () => {
    setLoadingModal(true);

    const data = { ids: idsPadModal };
    setLoadingLines(idsPadModal);
    const res = await sendPadInfractions(data);
    if (res.data?.success) {
      fetchCards();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setIdsPadModal(null);
  };

  const handleOpenNewTab = id => {
    return window.open(`/desvio/${id}`);
  };

  const handleOpenDetails = id => {
    navigate(`/desvio/${id}`);
  };

  // Controla se modal de finilizar vai ter ou nao justificativa
  const hasJustify = useMemo(() => {
    const data = resData?.data?.rows || [];
    if (idsFinishModal && data && idsFinishModal.length > 0) {
      const finded = data?.find(
        item =>
          idsFinishModal.includes(item.id) &&
          item.tipo.criticidade === 'GRAVÍSSIMA',
      );
      if (finded) return true;
    }
    return false;
  }, [resData?.data?.rows, idsFinishModal]);

  // Atualiza bulkActions de acordo com o status selecionado
  // Os itens selecionados podem alterar o a lista de ações
  const getBulkActions = () => {
    let actions = [];

    switch (filterDesvio.status) {
      case 'ABERTO':
        // Confere se seleção tem a mesma filial
        const filialIds = [];
        const data = resData?.data?.rows || [];
        for (const desvio of data) {
          const filialId = desvio.motorista?.filial?.id;
          if (
            selectedInfractions.includes(desvio.id) &&
            !filialIds.includes(filialId)
          )
            filialIds.push(filialId);
        }
        const mesmaFilial = filialIds.length < 2;

        actions = [
          // nao mostrar 'aprovar' quando há filiais diferentes
          mesmaFilial && {
            title: 'Aprovar',
            function: ids => {
              trackEvent(user, 'APROVAR TODOS OS DESVIOS');
              setIdsApproveModal(ids);
            },
          },
        ];
        break;

      case 'PENDENTE':
        actions = [
          {
            title: 'Finalizar',
            function: ids => {
              trackEvent(user, 'FINALIZAR TODOS OS DESVIOS');
              setIdsFinishModal(ids);
            },
          },
        ];
        break;

      case 'FINALIZADO':
        actions = [
          {
            title: 'Enviar para PAD',
            function: ids => {
              trackEvent(user, 'ENVIAR TODOS OS DESVIO PARA PAD');
              verifyIdsSendPad(ids);
            },
          },
        ];
        break;

      case 'DELETADO':
        break;

      default:
        break;
    }
    return actions.filter(Boolean);
  };

  // Atualiza ações de acordo com o status selecionado
  const getActions = () => {
    let actions = [{ name: 'null', text: 'Sem ações' }];

    switch (filterDesvio.status) {
      case 'ABERTO':
        actions = [
          {
            title: 'Marcar como visto',
            function: id => setIdsViewModal([parseInt(id)]),
            visible: line => {
              const data = resData?.data?.rows || [];
              const desvio = data?.find(item => item.id == line.id);
              if (!desvio?.visto) return true;
              return false;
            },
          },
          {
            title: 'Abrir em nova guia',
            function: handleOpenNewTab,
          },
          {
            title: 'Aprovar',
            function: id => {
              trackEvent(user, 'APROVAR DESVIOS');
              setIdsApproveModal([parseInt(id)]);
            },
          },
          userLevel < 4 && {
            title: 'Excluir',
            function: id => {
              trackEvent(user, 'Excluir DESVIO');
              const data = resData?.data?.rows || [];
              const desvio = data?.find(item => item.id == id);
              if (desvio) setDesvioDeleteModal(desvio);
            },
            visible: line => {
              const data = resData?.data?.rows || [];
              const desvio = data?.find(item => item.id == line.id);
              if (desvio?.aprovado_distribuidora !== null) return false;
              return true;
            },
          },
        ];
        break;

      case 'PENDENTE':
        actions = [
          {
            title: 'Abrir em nova guia',
            function: handleOpenNewTab,
          },
          {
            title: 'Finalizar',
            function: id => {
              trackEvent(user, 'FINALIZAR DESVIOS');
              setIdsFinishModal([parseInt(id)]);
            },
          },
        ];
        break;

      case 'FINALIZADO':
        actions = [
          {
            title: 'Abrir em nova guia',
            function: handleOpenNewTab,
          },
          {
            title: 'Enviar para o PAD',
            function: id => {
              trackEvent(user, 'ENVIAR DESVIO PARA PAD');
              verifyIdsSendPad([parseInt(id)]);
            },
          },
        ];
        break;

      case 'DELETADO':
        actions = [
          {
            title: 'Abrir em nova guia',
            function: handleOpenNewTab,
          },
        ];
        break;

      default:
        break;
    }
    return actions.filter(Boolean);
  };

  //  ------------------   RENDERIZACAO --------------------------------
  // Renderiza cards por status: ABERTO, PENDENTE, FINALIZADO, DELETADO
  const renderCards = () => {
    const cards = resCards?.data?.data || [{}, {}, {}];
    return (
      <Grid container spacing={2} marginBottom="25px">
        {cards &&
          cards.map(card => {
            return (
              <Grid item key={card.type} xs={12} md={4} xl={4}>
                <Card
                  value={card.value}
                  icon={card.icon}
                  text={card.text}
                  handleClick={() => handleClickCard(card.type)}
                  selected={selectedCard === card.type}
                  disabled={isFetching}
                  loading={loadingCards}
                />
              </Grid>
            );
          })}
      </Grid>
    );
  };

  return (
    <>
      <S.Main>
        <Grid
          marginBottom={1}
          item
          md={12}
          display="flex"
          justifyContent="space-between"
        >
          <h1>Desvios</h1>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={() => {
              trackEvent(user, 'EXPORTAR PLANILHA DESVIOS');
              setOpenExcelModal(true);
            }}
            style={{ marginLeft: '10px' }}
          >
            EXPORTAR
          </GhostButton>
        </Grid>

        <Grid marginBottom={0} item md={12} display="flex">
          <Tabs
            value={filterDesvio.status}
            items={statusTabs}
            onChange={handleStatusTab}
            disabled={loadingCards || loadingInfractions}
            last
          />
        </Grid>

        <Divider />

        <div style={{ padding: '15px 0px' }}>
          <InfoCard message={infoMessage} key="info" />
        </div>

        {renderCards()}

        <DefaultTable
          data={resData?.data?.rows || []}
          columns={handleColumns()}
          setSelectedRows={setSelectedInfractions}
          loading={loadingInfractions || loadingTab}
          pageCount={resData?.data?.total || 0}
          visualizedKey="visto"
          local={false}
          bulk={getBulkActions()}
          actions={getActions()}
          reset={resetTable}
          onClickRow={handleOpenDetails}
          loadingSelection={loadingLines}
          setQuery={q =>
            setQuery({ ...q, ...filter, ...filterDesvio, card: selectedCard })
          }
          searchEvent={search =>
            trackEvent(user, 'Busca Desvios de Direção', null, search)
          }
          sortBy={{ id: 'data_desvio', order: 'DESC' }}
          placeholder="Buscar por ID, Placa ou Motorista"
          empty={{
            title: 'Ops! Você não tem nenhum desvio disponível.',
            description: 'Verifique os filtros aplicados!',
          }}
        />
      </S.Main>

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      {idsApproveModal && (
        <ApproveModal
          handleConfirm={handleAprovar}
          ids={idsApproveModal}
          handleClose={() => setIdsApproveModal(null)}
          loading={loadingModal}
        />
      )}

      {idsFinishModal && (
        <FinishModal
          handleConfirm={handleFinalizar}
          ids={idsFinishModal}
          handleClose={() => setIdsFinishModal(null)}
          loading={loadingModal}
          hasJustify={hasJustify}
        />
      )}

      {idsViewModal && (
        <ConfirmModal
          handleClose={() => setIdsViewModal(null)}
          open={Boolean(idsViewModal)}
          title="Deseja marcar o desvio como visualizado?"
          titleIcon={
            <Check
              sx={{ color: theme.palette.brand.secondary.natural }}
              fontSize="medium"
            />
          }
          subtitle="Desvios não visualizados aparecem em negrito."
          buttonText="Confirmar"
          onClick={handleVisualizar}
          loading={loadingModal}
        />
      )}

      {desvioDeleteModal && (
        <DeleteModal
          handleConfirm={handleDeletar}
          desvio={desvioDeleteModal}
          handleClose={() => setDesvioDeleteModal(null)}
          loading={loadingModal}
        />
      )}

      {idsPadModal && (
        <ConfirmModal
          handleClose={() => setIdsPadModal(null)}
          open={Boolean(idsPadModal)}
          title={
            idsPadModal.length > 1
              ? `Deseja enviar desvios ao PAD? (${idsPadModal.length})`
              : 'Deseja enviar desvio ao PAD?'
          }
          subtitle="Os desvios serão enviados como ocorrências para o PAD"
          titleIcon={
            <Check
              sx={{ color: theme.palette.brand.secondary.natural }}
              fontSize="medium"
            />
          }
          buttonText="Confirmar"
          onClick={handleSendPad}
          loading={loadingModal}
        />
      )}
    </>
  );
};

export default Desvios;
