import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { subDays } from 'date-fns';
import { useTheme } from 'styled-components';
import { useReactToPrint } from 'react-to-print';

import { Divider, SvgIcon } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import GhostButton from 'components/Buttons/Ghost';
import { RoundedTabs } from 'components/RoundedTabs';
import { toast } from 'react-toastify';

import { Header } from './views/Header';
import { Desvios } from './views/Desvios';
import { Formularios } from './views/Formularios';
import { Estatisticas } from './views/Estatisticas';
import { AcoesSuspensoes } from './views/AcoesSuspensoes';
import { AvaliacoesDirecao } from './views/AvaliacoesDirecao';
import { AvaliacoesDescarga } from './views/AvaliacoesDescarga';
import { AcidentesMultas } from './views/AcidentesMultas';
import { CustomizedSelect } from 'components/SectionPeriod';
import { ReactComponent as filterIco } from 'images/icons/filter.svg';
import { StyledButton } from 'pages/Desvios/Estatisticas/DesempenhoGeral/styled';
import Filters from './components/Filters';
import { PDF } from './views/PDF';
import ExportModal from 'components/ExportModal';

import { tabs } from './constants';
import * as S from './styled';
import { usePlans } from 'hooks/usePlans';

// services
import * as API from '../services';
import { downloadFromLink } from 'utils/helpers/';

export const PerfilMotorista = () => {
  const theme = useTheme();
  const params = useParams();
  const componentRef = useRef();
  const promiseResolveRef = useRef(null);

  const { planosAtivos } = usePlans();
  const [tab, setTab] = useState(tabs[0]);
  const [printTabs, setPrintTabs] = useState(tabs.map(item => item.value));
  const [loadingPrint, setLoadingPrint] = useState(false);

  const [openExport, setOpenExport] = useState(false);
  const [period, setPeriod] = useState('30DIAS');
  const [periodExport, setPeriodExport] = useState(null);
  const [dataDriversExports, setDataDriversExports] = useState([]);
  const [driverBloqueio, setDriverBloqueio] = useState();
  const [lastTrip, setLastTrip] = useState();
  const [clientF, setClientF] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filtersPage, setFiltersPage] = useState({
    finalDate: new Date(),
    initialDate: subDays(new Date(), 30),
  });

  const fetchBloqueio = async () => {
    const bloqueioResponse = await API.requestDriverBloqueio(params.id);

    setDriverBloqueio(bloqueioResponse?.data?.data);
  };

  const fetchUltimaviagem = async () => {
    const lastTripResponse = await API.requestDriverLastTrip(params.id);
    setLastTrip(lastTripResponse?.data?.ultima_viagem);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise(resolve => {
        promiseResolveRef.current = resolve;
        setLoadingPrint(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setLoadingPrint(false);
    },
  });

  const handleExport = (exportType, selectedRadio, filters) => {
    const keys = {
      avaliacoes: 'DIRECAO',
      avaliacoes_descarga: 'DESCARGA',
      desvios: 'DESVIOS',
      formularios: 'FORMULARIOS',
      acoes: 'ACOES',
      estatisticas: 'ESTATISTICAS',
    };
    if (exportType === 'EXCEL') {
      handleRequestExcel({
        ...filters,
        page: keys[`${selectedRadio}`] || 'todos',
      });
    } else if (exportType === 'PDF') {
      setPrintTabs(
        selectedRadio ? [selectedRadio] : tabs.map(item => item.value),
      );
      handlePrint();
    }
  };

  const handleRequestExcel = useCallback(
    async query => {
      setLoadingPrint(true);
      const newQuery = {
        ...query,
        id_motorista: params.id,
      };

      const res = await API.requestDriversExportsExcel(params.id, newQuery);
      if (res.data.link) {
        downloadFromLink(res.data.link);
        toast.success(res.message);
      } else if (res.message) toast.error(res.message);

      setLoadingPrint(false);
    },
    [params.id],
  );

  useEffect(() => {
    if (loadingPrint && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [loadingPrint]);

  const fetchDriversExports = async () => {
    const paramsFilters = {
      page: 'todos',
      ...filtersPage,
    };

    const res = await API.requestDriversExports(params.id, paramsFilters);
    setDataDriversExports(res.data);
  };

  useEffect(() => {
    fetchDriversExports();
  }, [filtersPage, openExport]);

  useEffect(() => {
    fetchBloqueio();
    fetchUltimaviagem();
  }, []);

  return (
    <>
      <Header
        id={params.id}
        handleClickExport={() => setOpenExport(true)}
        motoristaBloqueado={
          driverBloqueio && driverBloqueio.some(e => e.motorista_bloqueado)
        }
      />
      <S.TabContainer>
        <div className="flex-1">
          <RoundedTabs
            items={tabs.filter(
              tab =>
                !tab.plans ||
                planosAtivos.some(plan => tab.plans.includes(plan.id_do_plano)),
            )}
            value={tab.value}
            onChange={value => setTab(tabs.find(_tab => _tab.value === value))}
          />
          <Divider />
        </div>
        {tab.link ? (
          <GhostButton
            icon={<ExitToAppIcon />}
            onClick={() => window.open(tab.link.url)}
          >
            IR PARA {tab.link.label}
          </GhostButton>
        ) : tab.filters ? (
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <CustomizedSelect
              today={filtersPage.finalDate}
              selectedDate={filtersPage.initialDate}
              selectedPeriod={period}
              handleChangeDate={value =>
                setFiltersPage(state => ({ ...state, initialDate: value }))
              }
              handleChangePeriod={value => setPeriod(value)}
            />
            <StyledButton
              textcolor={theme.palette.words.text.natural}
              backgroundcolor="transparent"
              startIcon={<SvgIcon component={filterIco} />}
              height="45px"
              style={{ marginTop: '3px' }}
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              Filtros
            </StyledButton>
          </div>
        ) : null}
      </S.TabContainer>
      <S.Content>
        {tab.value === 'estatisticas' && (
          <Estatisticas
            filters={filtersPage}
            motoristaBloqueado={
              driverBloqueio && driverBloqueio.some(e => e.motorista_bloqueado)
            }
            driverBloqueio={driverBloqueio}
            lastTrip={lastTrip}
          />
        )}
        {tab.value === 'avaliacoes' && <AvaliacoesDirecao />}
        {tab.value === 'avaliacoes_descarga' && <AvaliacoesDescarga />}
        {tab.value === 'desvios' && <Desvios />}
        {tab.value === 'formularios' && <Formularios />}
        {tab.value === 'acoes' && <AcoesSuspensoes />}
        {tab.value === 'acidentes_multas' && <AcidentesMultas />}
      </S.Content>
      <Filters
        id="simple-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        filter={filtersPage}
        setFilter={setFiltersPage}
        idEmpresa={params.id}
        multiple={false}
      />
      <ExportModal
        date
        open={openExport}
        handleClose={() => setOpenExport(false)}
        radioItems={tabs.filter(
          tab =>
            !tab.plans ||
            planosAtivos.some(plan => tab.plans.includes(plan.id_do_plano)),
        )}
        handleClick={handleExport}
        setFilterAnchorEl={setAnchorEl}
        filters={filtersPage}
        setFilters={setFiltersPage}
        setPeriod={setPeriodExport}
        period={periodExport}
      />
      <PDF
        id={params.id}
        ref={componentRef}
        filters={{
          ...filtersPage,
          filial: filtersPage.filial ? filtersPage.filial.split(',') : null,
        }}
        period={period}
        printTabs={printTabs}
        dataDriversExports={dataDriversExports}
      />
    </>
  );
};
