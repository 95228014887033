import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Components
import Podium from 'components/Podium';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';

// Components Material UI
import { Grid, Tooltip } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// Utils
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import * as services from './services';
import * as S from './styled';
import { columnsCompany2, columns2 } from './columns';
import { DefaultTable } from 'components/_Table/templates/default';

const RankingDirecao = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const filter = useSelector(state => state.filterProvider);
  const filterCustom = useSelector(state => state.filterDirecaoRankingProvider);

  const [data, setData] = useState([]);
  const [podium, setPodium] = useState([]);
  const [excelArray, setExcelArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaPtsHr, setMetaPtshr] = useState(null);
  const [metaDisp, setMetaDisp] = useState(null);

  const handleColumns = () => {
    const cols = [];
    const newCols = [...(filter.empresas ? columnsCompany2 : columns2)];
    newCols.forEach(col => {
      if (col.id === 'pontos_por_hora') {
        cols.push({
          ...col,
          conditional: [
            {
              condition: value => +value > (metaPtsHr || 10),
              style: theme => ({
                color: theme.palette.semantics.feedback.attention.natural,
                backgroundColor:
                  theme.palette.semantics.feedback.attention.light,
                textAlign: 'center',
              }),
            },
            {
              condition: value => +value <= (metaPtsHr || 10),
              style: theme => ({
                color: theme.palette.semantics.feedback.success.natural,
                backgroundColor: theme.palette.semantics.feedback.success.light,
                textAlign: 'center',
              }),
            },
          ],
        });
        return;
      }
      if (col.id === 'disponibilizacao_avaliacoes') {
        cols.push({
          ...col,
          conditional: [
            {
              condition: value => +value <= metaDisp,
              style: theme => ({
                color: theme.palette.semantics.feedback.attention.natural,
                textAlign: 'center',
              }),
            },
            {
              condition: value => +value > metaDisp,
              style: theme => ({
                color: theme.palette.semantics.feedback.success.natural,
                textAlign: 'center',
              }),
            },
          ],
        });
        return;
      }
      cols.push(col);
    });

    return cols;
  };

  useEffect(() => {
    getRanking();
  }, [filter, filterCustom]);

  const getRanking = async () => {
    setLoading(true);
    const res = await services.getList({
      ...filter,
      motoristas: filterCustom.motoristas,
      idDesvio: filterCustom.desvio,
      idVeiculo: filterCustom.veiculo,
    });
    if (res.success) {
      const { rows } = res;
      setData(res.rows);
      setMetaPtshr(res?.parametros?.ptsHr || 0);
      setMetaDisp(res?.parametros?.disponibilizacao || 0);
      setExcelArray(res.excel);

      // Format itens to send to Podium component
      const formatedPodium = [];
      if (rows.length) {
        for (let i = 0; i <= 2; i++) {
          if (rows.length > i) {
            formatedPodium.push({
              nameOne: rows[i].filial,
              nameTwo: rows[i].transportadora,
              position: rows[i].posicao,
              oldPosition: rows[i].oldPosition,
              logo: rows[i].logo_url,
              hours: rows[i].horas,
              points: rows[i].pontos,
            });
          }
        }
      }
      setPodium(formatedPodium);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  // -------------------------- EXCEL ------------------------------------------//
  const handleExcel = () => {
    const formatedDate = formatNameDate(new Date());
    ExportToExcel({
      excel: excelArray,
      name: `ranking_direcao_${formatedDate}`,
    });
  };

  // -----------------------TOTAL INFORMATIONS---------------------------------//
  useEffect(() => getInformations(), [data, filter, filterCustom]);

  const [totalBox, setTotalBox] = useState(null);

  const getInformations = () => {
    if (data.length < 0) {
      return null;
    }

    let pontos = 0;
    let horas = 0;
    let moto_aval = 0;
    let moto_cdst = 0;
    let quebras = 0;
    let exclusoes = 0;
    let disponibilizacoes = 0;
    let prazo = 0;

    for (const i in data) {
      data[i].pontos && (pontos += Number(data[i].pontos));
      data[i].horas && (horas += Number(data[i].horas));
      data[i].motoristas_avaliados &&
        (moto_aval += Number(data[i].motoristas_avaliados));
      data[i].motoristas_cadastrados &&
        (moto_cdst += Number(data[i].motoristas_cadastrados));
      data[i].quebra_requisitos &&
        (quebras += Number(data[i].quebra_requisitos));
      data[i].desvios_excluidos &&
        (exclusoes += Number(data[i].percentual_desvios_excluidos));
      data[i].disponibilizacao_avaliacoes &&
        (disponibilizacoes += Number(data[i].disponibilizacao_avaliacoes));
      data[i].atendimento_sla && (prazo += Number(data[i].atendimento_sla));
    }

    setTotalBox([
      {
        title: 'Pontos/Hora',
        count: (pontos / horas || 0).toFixed(2),
      },
      {
        title: 'Horas',
        count: horas,
      },
      {
        title: 'Motoristas Avaliados',
        count: moto_aval,
      },
      {
        title: 'Motoristas Cadastrados',
        count: moto_cdst,
      },
      {
        title: 'Quebra de Requisitos',
        count: quebras,
      },
      {
        title: 'Desvios Excluídos',
        count: `${(exclusoes / data.length || 0).toFixed(2)}%`,
      },
      {
        title: 'Disponibilização de Horas',
        count: `${disponibilizacoes}%`,
      },
      {
        title: 'Prazo Disponibilização',
        count: `${(prazo / data.length || 0).toFixed(2)}%`,
      },
    ]);
  };

  const handleVer = id => {
    if (id) navigate(`/empresas/${id}`);
    else toast.error('Não foi possível acessar os detalhes desta empresa.');
  };

  const actions = [{ title: 'Ver perfil empresa', function: handleVer }];

  return (
    <>
      {!loading && (
        <>
          <Podium data={podium} loading={loading} />

          <Grid
            p={2}
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginBottom: '-80px',
              marginTop: '10px',
            }}
          >
            <GhostButton children="EXPORTAR" onClick={() => handleExcel()} />
          </Grid>

          <DefaultTable
            data={data || []}
            columns={handleColumns()}
            actions={actions}
            searchKeys={['posicao', 'transportadora']}
            placeholder="Buscar por Empresa ou Posição..."
            sortBy={{ id: 'posicao', order: 'ASC' }}
          />

          <S.TotalTitle>
            <span>Resumo</span>
            <Tooltip title="Somatorios de todos os valores disponíveis na tabela">
              <HelpOutlineOutlinedIcon
                htmlColor={theme.palette.semantics.feedback.unknown.natural}
                style={{ marginLeft: '10px', cursor: 'pointer' }}
              />
            </Tooltip>
          </S.TotalTitle>
          <S.TotalContentBox>
            {totalBox?.map((item, key) => (
              <div className="boxItem" key={key}>
                <div className="title">{item.title}</div>
                <div className="count">{item.count}</div>
              </div>
            ))}
          </S.TotalContentBox>
        </>
      )}
      {loading && (
        <div>
          <Loading />
        </div>
      )}
    </>
  );
};

export default RankingDirecao;
