import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import ListPage from 'pages/_templates/ListPage';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { trackEvent } from 'utils/mixpanel';
import { getRouterQueryParams } from 'utils/router-query-params';
import { setFilter, setSortBy } from 'store/modules/filterDesvios/actions';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

import { AddCircleOutline, SaveAlt } from '@mui/icons-material';
import ExcelModal from 'components/ExcelModal';
import ResponsibleModal from './components/ResponsibleModal';
import FinishModal from './components/FinishModal';
import Deslocamento from './components/Deslocamento';

import {
  requestCards,
  requestDesvios,
  aprovarDesvios,
  atribuirResponsavel,
  finalizarDesvios,
  requestExcelProvider,
  requestCount,
  getDeslocamentos,
  tratarDesvios,
} from './services';
import {
  columns,
  columnsEmbarcador,
  fields,
  fieldsProvider,
  statusTabs,
  formatArrFilters,
} from './constants';
import useExportExcel from 'hooks/useExportExcel';
import { requestExcel } from './services';
import DesvioManualModal from './components/DesvioManualModal';
import { usePlans } from 'hooks/usePlans';

const Desvios = () => {
  // Redux e hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpLogistico, isProvider, hasTorrePlus } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);
  const userLevel = user.nivel;

  const filter = useSelector(state =>
    isProvider ? state.filterProvider : state.filter,
  );
  const filterDesvios = useSelector(state => state.filterDesvios);

  const [query, setQuery] = useState(null);
  const [loadingTab, setLoadingTab] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedInfractions, setSelectedInfractions] = useState([]);

  const [openDesvioManual, setOpenDesvioManual] = useState(false);
  const [responsibleModal, setResponsibleModal] = useState(null);
  const [idsFinishModal, setIdsFinishModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);

  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  const {
    handleRequestExcel,
    openExcelModal,
    setOpenExcelModal,
    loadingExcel,
    excelFields,
    setExcelFields,
  } = useExportExcel(
    'desvios',
    isProvider && !isOpLogistico ? fieldsProvider : fields,
    isProvider && !isOpLogistico ? requestExcelProvider : requestExcel,
    {
      ...filter,
      client: filter.client ? filter.client.split(',') : '',
      filial: filter.filial ? filter.filial.split(',') : '',
      filial_veiculo: filter.filial_veiculo
        ? filter.filial_veiculo.split(',')
        : '',
      empresas: filter.empresas ? filter.empresas.split(',') : '',
      ...formatArrFilters(filterDesvios),
      status: undefined,
    },
  );

  const {
    refetch: fetchData,
    isFetching,
    data: resData,
  } = useQuery(['desvios', query], () => query && requestDesvios(query), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setLoadingLines([]);
      setLoadingTab(false);
      resetTable && setResetTable(false);
    },
  });

  // ********************  Deslocamento *****************************
  const {
    refetch: fetchDeslocamento,
    isFetching: loadingDeslocamento,
    data: resDataDeslocamento,
  } = useQuery(
    ['deslocamentos', 'ABERTO'],
    () => getDeslocamentos({ tipo: 'ABERTO' }),
    {
      refetchOnWindowFocus: false,
      // onSuccess: () => {},
    },
  );

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cads
  // const {
  //   refetch: fetchCards,
  //   isLoading: loadingCards,
  //   data: resCards,
  // } = useQuery(
  //   [
  //     'cards-desvios',
  //     {
  //       ...filter,
  //       client: filter.client ? filter.client.split(',') : '',
  //       filial: filter.filial ? filter.filial.split(',') : '',
  //       filial_veiculo: filter.filial_veiculo
  //         ? filter.filial_veiculo.split(',')
  //         : '',
  //       empresas: filter.empresas ? filter.empresas.split(',') : '',
  //       ...formatArrFilters(filterDesvios),
  //     },
  //   ],
  //   () =>
  //     requestCards({
  //       ...filter,
  //       client: filter.client ? filter.client.split(',') : '',
  //       filial: filter.filial ? filter.filial.split(',') : '',
  //       filial_veiculo: filter.filial_veiculo
  //         ? filter.filial_veiculo.split(',')
  //         : '',
  //       empresas: filter.empresas ? filter.empresas.split(',') : '',
  //       ...formatArrFilters(filterDesvios),
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //   },
  // );
  const resCards = null;
  const loadingCards = false;

  // Atualiza totalizador apenas quando muda filtros ou cards
  const {
    refetch: fetchCount,
    isFetching: loadingCount,
    data: resCount,
  } = useQuery(
    [
      'count-desvios',
      {
        ...filter,
        client: filter.client ? filter.client.split(',') : '',
        filial: filter.filial ? filter.filial.split(',') : '',
        filial_veiculo: filter.filial_veiculo
          ? filter.filial_veiculo.split(',')
          : '',
        empresas: filter.empresas ? filter.empresas.split(',') : '',
        ...formatArrFilters(filterDesvios),
        card: selectedCard,
      },
    ],
    () =>
      requestCount({
        ...filter,
        client: filter.client ? filter.client.split(',') : '',
        filial: filter.filial ? filter.filial.split(',') : '',
        filial_veiculo: filter.filial_veiculo
          ? filter.filial_veiculo.split(',')
          : '',
        empresas: filter.empresas ? filter.empresas.split(',') : '',
        ...formatArrFilters(filterDesvios),
        card: selectedCard,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // Altera tab inicial em caso de url com parametro
  const status = getRouterQueryParams({ location: useLocation }).get('status');
  const card = getRouterQueryParams({ location: useLocation }).get('card');

  useEffect(() => {
    statusTabs.map(s => s.value).includes(status) &&
      dispatch(
        setFilter({
          status,
        }),
      );
    [
      'proximo_vencimento',
      'criticidade_alta',
      'exclusao_revisao',
      'desvios_analise',
    ].includes(card) && setSelectedCard(card);
  }, [statusTabs, status, card]);

  // Altera tab inicial em caso de Distribuidora
  useEffect(() => {
    if (
      isProvider &&
      !(hasTorrePlus || isOpLogistico) &&
      filterDesvios.status === 'ABERTO'
    )
      dispatch(
        setFilter({
          status: 'PENDENTE',
        }),
      );
  }, []);

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filterDesvios.status]);

  // ********************  ACTIONS / BULKACTIONS *****************************
  // Funções das ações
  const handleAtribuirResponsavel = async (data, approve) => {
    setLoadingModal(true);
    setLoadingLines(data?.ids || []);

    const res = approve
      ? await aprovarDesvios(data)
      : await atribuirResponsavel(data);
    if (res.data?.success) {
      // fetchCards();
      fetchCount();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setResponsibleModal(null);
  };

  const verifyIdsFinish = ids => {
    const valid = resData?.data?.data
      ?.filter(item => ids.includes(item.id))
      .every(
        item => item.responsavel_nome && item.plano_acao && item.data_previsao,
      );

    if (valid) {
      setIdsFinishModal(ids);
    } else {
      toast.warning(
        'Não é possível finalizar desvios sem usuário responsável, plano ação ou data de previsão.',
      );
    }
  };

  const handleFinalizar = async ids => {
    setLoadingModal(true);
    setLoadingLines(ids || []);

    const res = await finalizarDesvios({ ids });
    if (res.data?.success) {
      // fetchCards();
      fetchCount();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setIdsFinishModal(null);
  };

  // Troca do tab (filterDesvios.status)
  const handleStatusTab = async (event, newValue) => {
    // setSelectedCard(null);
    setLoadingTab(true);
    dispatch(
      setFilter({
        status: newValue,
      }),
    );
  };

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor válido: nao nulo e maior que 0.
  const handleClickCard = type => {
    if (!loadingCards && !isFetching) {
      const cards = resCards || [];
      const card = cards.find(item => item.type === type);
      if (card.value) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  // Atualiza as colunas de acordo com o status selecionado
  const handleColumns = () => {
    let _columns = isProvider && !isOpLogistico ? columnsEmbarcador : columns;
    if (isProvider && hasTorrePlus)
      _columns[1] = {
        header: 'Motorista/Transp',
        id: 'motorista_nome',
        type: 'string',
        tooltip: true,
        sort: true,
        subRow: {
          value: (_, item) => item?.empresa_nome,
        },
      };
    const col = [..._columns];

    if (filterDesvios.status === 'FINALIZADO')
      col[5] = {
        header: 'Data Conclusão',
        id: 'data_encerramento',
        type: 'date',
        sort: true,
      };
    else if (filterDesvios.status === 'DELETADO')
      col[5] = {
        header: 'Data Exclusão',
        id: 'data_exclusao',
        type: 'date',
        sort: true,
      };

    return col;
  };

  // Atualiza bulkActions de acordo com o status selecionado
  // Os itens selecionados podem alterar o a lista de ações
  const getBulkActions = () => {
    let actions = [];
    const data = resData?.data?.data || [];
    const desviosProprios =
      !isProvider ||
      !data
        .filter(item => selectedInfractions.includes(item.id))
        .some(item => item.id_empresa);

    if (desviosProprios && userLevel < 4) {
      switch (filterDesvios.status) {
        case 'ABERTO':
          // Confere se seleção tem a mesma filial
          const filialIds = [];
          for (const desvio of data) {
            const filialId = desvio.motorista?.filial?.id;
            if (
              selectedInfractions.includes(desvio.id) &&
              !filialIds.includes(filialId)
            )
              filialIds.push(filialId);
          }
          const mesmaFilial = filialIds.length < 2;

          // nao mostrar 'actions' quando há filiais diferentes
          actions = mesmaFilial
            ? [
                {
                  title: 'Atribuir responsável',
                  function: ids => {
                    trackEvent(user, 'ATRIBUIR RESPONSAVEL NOS DESVIOS');
                    setResponsibleModal({ approve: false, data: ids });
                  },
                },
                {
                  title: 'Aprovar',
                  function: ids => {
                    trackEvent(user, 'APROVAR TODOS OS DESVIOS');
                    setResponsibleModal({ approve: true, data: ids });
                  },
                },
              ]
            : [];
          break;

        case 'PENDENTE':
          actions = [
            {
              title: 'Finalizar',
              function: ids => {
                trackEvent(user, 'FINALIZAR TODOS OS DESVIOS');
                verifyIdsFinish(ids);
              },
            },
          ];
          break;

        default:
          break;
      }
    }
    return actions.filter(Boolean);
  };

  // Atualiza ações de acordo com o status selecionado
  const getActions = () => {
    let actions = [
      {
        title: 'Abrir em nova guia',
        function: handleOpenNewTab,
      },
    ];
    if ((!isProvider || isOpLogistico || hasTorrePlus) && userLevel < 4)
      switch (filterDesvios.status) {
        case 'ABERTO':
          actions = [
            ...actions,
            {
              title: 'Aprovar',
              function: id => {
                trackEvent(user, 'APROVAR DESVIOS');
                setResponsibleModal({ approve: true, data: [id] });
              },
            },
          ];
          break;

        case 'PENDENTE':
          actions = [
            ...actions,
            {
              title: 'Finalizar',
              function: id => {
                trackEvent(user, 'FINALIZAR DESVIOS');
                verifyIdsFinish([id]);
              },
            },
          ];
          break;

        default:
          break;
      }
    return actions.filter(Boolean);
  };

  const getIndex = id => {
    const _data = [...(resData?.data?.data ?? [])];
    dispatch(setSortBy(query.sortBy));

    const dataIndex = _data.findIndex(item => item.id == id);
    if (dataIndex !== -1) {
      return query.pageSize * query.pageIndex + dataIndex;
    }
    return '';
  };

  const handleOpenNewTab = id => {
    return window.open(`/desvios/${id}?index=${getIndex(id)}`);
  };

  const handleOpenDetails = id => {
    const url = `/desvios/${id}?index=${getIndex(id)}`;
    navigate(url);
  };

  // Configura os botões de ação do header
  const headerActions = [
    {
      title: 'Exportar',
      variant: 'ghost',
      icon: <SaveAlt />,
      function: () => setOpenExcelModal(true),
    },
  ];
  if ((!isProvider || isOpLogistico) && userLevel < 4)
    headerActions.push({
      title: 'Adicionar desvio manual',
      variant: 'default',
      icon: <AddCircleOutline />,
      function: () => setOpenDesvioManual(true),
    });

  // Configura as tabs da tela
  const headerTabs = {
    value: filterDesvios.status,
    items: statusTabs.filter(
      item =>
        item.value !== 'ABERTO' || !isProvider || isOpLogistico || hasTorrePlus,
    ),
    onChange: handleStatusTab,
    disabled: loadingCards || isFetching,
  };

  const navigateToDeslocamentos = () => {
    navigate(`/desvios/deslocamentos`);
  };

  useEffect(() => {
    setResetTable(true);
  }, [filter]);

  const handleTratarDesvio = async data => {
    const res = await tratarDesvios(data);
    if (res.data.success) {
      toast.success(res.data.message);
      fetchDeslocamento();
    }
  };

  return (
    <>
      {resDataDeslocamento?.data && resDataDeslocamento?.data.length > 0 && (
        <>
          <div
            style={{
              color: '#4B5166',
              fontWeight: '900',
              fontSize: 24,
              marginBottom: 15,
            }}
          >
            Esses deslocamentos precisam da sua atenção!
          </div>
          <Deslocamento
            data={resDataDeslocamento?.data.slice(0, 3)}
            onSave={handleTratarDesvio}
            loading={loadingDeslocamento}
          />

          <div
            onClick={() => navigateToDeslocamentos()}
            style={{
              textAlign: 'end',
              marginBottom: 30,
              color: ' #BDCC30',
              textDecoration: 'underline',
              fontSize: 16,
              cursor: 'pointer',
            }}
          >
            Ver todos os deslocamentos
          </div>
        </>
      )}
      <ListPage
        title="Desvios"
        headerActions={headerActions}
        headerTabs={headerTabs}
        cards={resCards?.map(card => ({ ...card, disabled: isFetching })) || []}
        selectedCard={selectedCard}
        handleClickCard={handleClickCard}
        loadingCards={loadingCards}
        tableProps={{
          data: resData?.data?.data || [],
          columns: handleColumns(),
          setSelectedRows:
            ['ABERTO', 'PENDENTE'].includes(filterDesvios.status) &&
            (!isProvider || isOpLogistico || hasTorrePlus)
              ? setSelectedInfractions
              : null,
          loading: isFetching || loadingTab, // || loadingCount,
          pageCount: resCount?.data?.total || 1000,
          visualizedKey: 'visto',
          local: false,
          bulk: getBulkActions(),
          actions: getActions(),
          reset: resetTable,
          onClickRow: handleOpenDetails,
          loadingSelection: loadingLines,
          setQuery: q =>
            setQuery({
              ...filter,
              ...formatArrFilters(filterDesvios),
              ...q,
              empresas: filter.empresas ? filter.empresas.split(',') : '',
              client: filter.client ? filter.client.split(',') : '',
              filial: filter.filial ? filter.filial.split(',') : '',
              filial_veiculo: filter.filial_veiculo
                ? filter.filial_veiculo.split(',')
                : '',
              card: selectedCard,
            }),
          searchEvent: search =>
            trackEvent(user, 'Busca Desvios de Direção', null, search),
          sortBy: { id: 'data_desvio', order: 'DESC' },
          placeholder: `Buscar por ID, Placa ou ${
            isProvider && !(isOpLogistico || hasTorrePlus)
              ? 'Empresa'
              : 'Motorista'
          }`,
          empty: {
            title: 'Ops! Você não tem nenhum desvio disponível.',
            description: 'Verifique os filtros aplicados!',
          },
        }}
      />
      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      {responsibleModal && (
        <ResponsibleModal
          approve={responsibleModal?.approve}
          handleConfirm={handleAtribuirResponsavel}
          ids={responsibleModal?.data}
          handleClose={() => setResponsibleModal(null)}
          loading={loadingModal}
        />
      )}

      {idsFinishModal && (
        <FinishModal
          handleConfirm={() => handleFinalizar(idsFinishModal)}
          ids={idsFinishModal}
          handleClose={() => setIdsFinishModal(null)}
          loading={loadingModal}
        />
      )}
      {openDesvioManual && (
        <DesvioManualModal
          open={openDesvioManual}
          handleClose={() => setOpenDesvioManual(false)}
          refetchDesvios={fetchData}
        />
      )}
    </>
  );
};

export default Desvios;
