export const columnsNiveis = [
  {
    Header: 'Título',
    id: 'nivel',
    accessor: d => (d.nivel ? String(d.nivel) : 'N/A'),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Descrição',
    id: 'descricao',
    accessor: d => String(d.descricao),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Pontuacao inicial',
    id: 'pontuacao_inicial',
    accessor: d => Number(d.pontuacao_inicial),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Pontuação final',
    id: 'pontuacao_final',
    accessor: d => Number(d.pontuacao_final),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Restrições',
    id: 'restricoes',
    accessor: d => (d.restricoes ? String(d.restricoes) : 'N/A'),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Ações',
    id: 'acoes',
    accessor: 'acoes',
    show: true,
  },
];
