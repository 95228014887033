import { formatNewDate } from 'utils/dates';
import { calculaDecaimento, calculaExpiracao } from './actions';

export const defaultColumns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista',
    id: 'motorista.nome',
    type: 'string',
    sort: true,
    subRow: {
      prefix: 'Filial:',
      value: (_, item) => item?.motorista?.filial?.nome,
      type: 'date',
    },
  },
  {
    header: 'Violação',
    id: 'violacao.descricao',
    type: 'string',
    sort: true,
    subRow: {
      prefix: 'Decaimento:',
      value: (_, item) =>
        `${calculaDecaimento(item?.violacao)}pts a cada ${
          item?.violacao.decaimento_tempo
            ? item?.violacao.decaimento_tempo
            : item?.violacao.prazo && item?.violacao.prazo
        } dias`,
    },
  },
  {
    header: 'Pontuação',
    id: 'pontuacao',
    type: 'number',
    align: 'center',
    sort: true,
    conditional: [
      {
        condition: value => true,
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
    ],
    subRow: {
      value: (_, item) =>
        calculaExpiracao(item?.violacao.prazo, item?.data_ocorrencia)
          .restantes === 0
          ? `Ocorrência Expirada`
          : `Expira em: ${
              calculaExpiracao(item?.violacao.prazo, item?.data_ocorrencia)
                .restantes
            } dias`,
    },
  },
  {
    header: 'Data Ocorrência',
    id: 'data_ocorrencia',
    type: 'date',
    sort: true,
    subRow: {
      value: (_, item) =>
        item.status !== 'ANALISE'
          ? `Data feedback: ${
              item.data_feedback ? formatNewDate(item.data_feedback) : '-'
            }`
          : `Prazo análise: ${
              item.prazo_analise ? formatNewDate(item.prazo_analise) : '-'
            }`,
    },
  },
];

export const statusColumn = {
  header: 'Status',
  id: 'status',
  type: 'string',
  sort: true,
  align: 'center',
  conditional: [
    {
      condition: value => value === 'APROVADA',
      style: theme => ({
        color: theme.palette.semantics.feedback.success.natural,
        backgroundColor: theme.palette.semantics.feedback.success.light,
        textAlign: 'center',
      }),
    },
    {
      condition: value => value === 'ANALISE',
      style: theme => ({
        color: theme.palette.semantics.feedback.warning.natural,
        backgroundColor: theme.palette.semantics.feedback.warning.light,
        textAlign: 'center',
      }),
    },
    {
      condition: value => value === 'PENDENTE' || value === 'DELETADA',
      style: theme => ({
        color: theme.palette.semantics.feedback.attention.natural,
        backgroundColor: theme.palette.semantics.feedback.attention.light,
        textAlign: 'center',
      }),
    },
  ],
};

export const columnsPendente = [...defaultColumns, statusColumn];

export const columnsAnalise = [
  ...defaultColumns,
  {
    header: 'Responsável Análise',
    id: 'analista.nome',
    type: 'string',
    sort: true,
  },
  statusColumn,
];

export const columnsFinalizado = [
  ...defaultColumns,
  {
    header: 'Responsável',
    id: 'analista.nome',
    type: 'string',
    sort: true,
  },
  statusColumn,
];

export const columnsDeletado = [
  ...defaultColumns,
  {
    header: 'Responsável',
    id: 'analista.nome',
    type: 'string',
    sort: true,
  },
  statusColumn,
];

export const columns = [
  columnsPendente,
  columnsAnalise,
  columnsFinalizado,
  columnsDeletado,
];
