import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Grid } from '@mui/material';
import { SwitchTable } from 'components/Inputs/SwitchTable';

import TableLocal from 'components/TableLocal';
import EmptyCard from 'components/Cards/EmptyDataCard';
import Button from 'components/Buttons/Default';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';
import { AddCircleOutline } from '@mui/icons-material';
import * as S from './styled';

// Services
import * as request from '../services';

// Columns
import { columnsViolacoes } from './columns';

// Modal
import AddViolacaoModal from '../AddViolacaoModal';
import EditViolacaoModal from '../EditViolacaoModal';

// Components

const PadViolacoesConfig = () => {
  // const filter = useSelector(state => { return state.filter })

  const [query, setQuery] = useState({});
  const [violacoes, setViolacoes] = useState([]);
  const [editViolacao, setEditViolacao] = useState(null);
  const [columns, setColumns] = useState(columnsViolacoes);
  const [loading, setLoading] = useState(false);

  const [openAddViolacaoaModal, setOpenAddViolacaoModal] = useState(false);
  const [openEditViolacaoModal, setOpenEditViolacaoModal] = useState(false);

  const [activeViolations, setActiveViolations] = useState(0);
  const [disableButton, setDisableButton] = useState(true);
  const [updated, setUpdated] = useState(false);

  const [checkbox, setCheckbox] = useState(null);

  const navigate = useNavigate();

  // const handleChangeSwitch = async (row) => {
  //     let violacao = row
  //     if (!violacao.status || violacao.status === "INATIVO") violacao.status = "ATIVO"
  //     else violacao.status = "INATIVO"

  //     for (const key in violacao) {
  //         if (violacao[key] === null) {
  //             delete (violacao[key])
  //         }
  //     }
  //     // if (row.padrao) {
  //     //     delete (violacao.id)
  //     //     delete (violacao.createdAt)
  //     //     delete (violacao.updatedAt)
  //     //     const res = await request.registerViolacao(violacao)
  //     //     violacao = res.data
  //     // } else {
  //     //     const res = await request.editViolacao(violacao)
  //     // }

  //     fetchViolacoes()

  //     setDisableButton(false)
  //     //Se ativar, deve editar imediatamente!
  //     // if (violacao.status === "ATIVO") {
  //     //   violacao.isNew = true
  //     //   handleOpenEdit(violacao)
  //     // }
  // }

  const handleChangeSwitch = async id => {
    const violacao = (violacoes || [])?.find(
      item => String(item.id) === String(id),
    );

    if (violacao.status === 'ATIVO') handleDesativar(id);
    else handleAtivar(id);
  };

  const handleAtivar = async id => {
    let violacao = violacoes.find(item => String(item.id) === String(id));
    if (violacao.status && violacao.status === 'ATIVO')
      return toast.success('Essa violação já está ativa');

    if (violacao.padrao) {
      delete violacao.id;
      delete violacao.createdAt;
      delete violacao.updatedAt;
      const res = await request.registerViolacao(violacao);
      violacao = res.data;
    } else {
      violacao.status = 'ATIVO';
      const res = await request.editViolacao(violacao);
    }

    fetchViolacoes();

    setDisableButton(false);
    // Se ativar, deve editar imediatamente!
    if (violacao.status === 'ATIVO') {
      violacao.isNew = true;
      setEditViolacao(violacao);
      setOpenEditViolacaoModal(true);
    }
  };

  const handleDesativar = async id => {
    const violacao = violacoes.find(item => String(item.id) === String(id));

    if (!violacao.status || violacao.status === 'INATIVO')
      return toast.success('Essa violação já está inativa');

    violacao.status = 'INATIVO';
    const res = await request.editViolacao(violacao);
    if (res.success) toast.success(res.message);
    else toast.error(res.message);
  };

  const handleEdit = id => {
    const violacao = violacoes.find(item => String(item.id) === id);

    if (violacao) {
      setEditViolacao(violacao);
      setOpenEditViolacaoModal(true);
    } else toast.error('Não foi possível encontrar essa violação');
  };

  const handleFinishConfigViolacoes = () => {
    navigate('/pad/configuracoes');
  };

  const sortBy = [
    {
      id: 'status',
      desc: false,
    },
  ];

  const fetchViolacoes = async () => {
    setLoading(true);

    const res = await request.requestViolacoes();
    if (res.success) {
      setViolacoes(res.rows);
      const active = res.rows.filter(item => item.status === 'ATIVO');
      setActiveViolations(active.length);
    } else toast.error('Não foi possível encontrar a lista de violações.');

    setUpdated(false);
    setLoading(false);
  };
  const actions = [
    {
      name: 'ativar',
      text: 'Ativar',
      action: handleAtivar,
    },
    {
      name: 'desativar',
      text: 'Desativar',
      action: handleDesativar,
    },
    {
      name: 'editar',
      text: 'Editar',
      action: handleEdit,
    },
  ];

  useEffect(() => {
    setColumns(formatColumns(columnsViolacoes));
  }, []);

  // useEffect(() => {
  //     fetchViolacoes()
  // }, [])

  useEffect(() => {
    fetchViolacoes();
  }, [updated]);

  const formatColumns = async col => {
    col.forEach(i => {
      if (i.id === 'status') {
        i.Cell = function ({ row }) {
          return (
            <S.Status>
              {!row.original.status || row.original.status === 'INATIVO'
                ? 'INATIVO'
                : 'ATIVO'}
            </S.Status>
          );
        };
      }
    });
  };

  const renderTop = () => (
    <>
      <S.TitleWrapper>
        <h1>Configurar Violações</h1>
        <div>
          <DefaultButtonPopover
            startIcon={<AddCircleOutline />}
            size="medium"
            sx={{ marginLeft: '10px' }}
            onClick={() => setOpenAddViolacaoModal(true)}
          >
            ADICIONAR VIOLAÇÃO
          </DefaultButtonPopover>
        </div>
      </S.TitleWrapper>
    </>
  );

  const renderTable = () => {
    if (violacoes && violacoes.length > 0) {
      return (
        <TableLocal
          columns={columnsViolacoes}
          data={violacoes}
          sortBy={sortBy}
          permitIsSortedOccur
          actions={actions}
          search={{
            inputLabel: 'Buscar Violação',
            keys: ['descricao', 'pontos'],
          }}
          loading={loading}
          // fullObj={true}
        />
      );
    }
    return (
      <EmptyCard
        image="padconfig.png"
        title="Ops! Não foi encontrado violação para essa busca."
        subtitle="Verifique o campo de busca!"
      />
    );
  };

  const renderFooter = () => {
    return (
      <Grid container marginTop="10px" display="flex">
        <Grid item marginLeft="5%">
          <S.Paragraph>{activeViolations} violações cadastradas</S.Paragraph>
        </Grid>
        <Grid item marginLeft="75%">
          <Button
            onClick={() => handleFinishConfigViolacoes()}
            children="PROSSEGUIR"
            width="156px"
            disabled={disableButton}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <S.Main>
      {renderTop()}
      {renderTable()}
      <S.Wrapper>{renderFooter()}</S.Wrapper>

      {openAddViolacaoaModal && (
        <AddViolacaoModal
          open={openAddViolacaoaModal}
          handleClose={() => setOpenAddViolacaoModal(false)}
          title="Criar Nova Ocorrência"
          closeModal={setOpenAddViolacaoModal}
          setUpdated={setUpdated}
          stopProgress={setDisableButton}
        />
      )}

      {openEditViolacaoModal && (
        <EditViolacaoModal
          open={openEditViolacaoModal}
          handleClose={() => setOpenEditViolacaoModal(false)}
          title="Editar Violação"
          violations={violacoes}
          edited={editViolacao}
          closeModal={setOpenEditViolacaoModal}
          setUpdated={setUpdated}
        />
      )}
    </S.Main>
  );
};

export default PadViolacoesConfig;
