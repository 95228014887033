// React
import React, { useState } from 'react';
import { subDays } from 'date-fns';
import { useQuery } from 'react-query';

// components pagina
import Cards from './Cards';
import { GraficoFatorDeRisco } from './GraficoFatorRisco';
import GraficoBarraVertical from './GraficoBarraVertical';
import GraficoBarraHorizontal from './GraficoBarraHorizontal';
import GraficoMotoristaOfensores from './GraficoMotoristaOfensores';
import RadarGraph from './RadarGraph';

// Styles
import * as S from './styled';

// services
import * as API from './services';
import { usePlans } from 'hooks/usePlans';
import { Skeleton } from '@mui/material';

const FatorRisco = () => {
  const { isProvider: hasProvider, hasTorrePlus, hasTorre } = usePlans();
  const isProvider = hasProvider;

  const today = new Date();
  const initialDate = subDays(today, 30);
  const finalDate = today;

  const [period, setPeriod] = useState({
    initialDate,
    finalDate,
  });

  const [filters, setFilters] = useState(null);
  const [desviosGraves, setDesviosGraves] = useState([]);
  const [fatoresRiscoDiasSemana, setFatoresRiscoDiasSemana] = useState([]);
  const [fatoresRiscoPeriodoDia, setFatoresRiscoPeriodoDia] = useState([]);
  const [filiais, setFiliais] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [radarRisco, setRadarRisco] = useState([]);

  // states provider
  const [vencimentosProvider, setVencimentosProvider] = useState([]);
  const [criticidadeProvider, setCriticidadeProvider] = useState([]);

  // Desvios x Vencimentos (Provider)
  const { isFetching: loadingVencimentos } = useQuery(
    ['desvios-vencimentos-provider', filters, period],
    () =>
      API.getVencimentosProvider({
        ...filters,
        ...period,
      }),
    {
      enable: isProvider,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        setVencimentosProvider(res?.data || []);
      },
    },
  );

  // Desvios x Criticidade (Provider)
  const { isFetching: loadingCriticidade } = useQuery(
    ['desvios-criticidade-provider', filters, period],
    () =>
      API.getCriticidadeProvider({
        ...filters,
        ...period,
      }),
    {
      enable: isProvider,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        setCriticidadeProvider(res?.data || []);
      },
    },
  );

  // Desvios x Clientes (Transportador)
  const { isFetching: loadingClientes } = useQuery(
    ['desvios-clientes', filters, period],
    () =>
      API.getClients({
        ...filters,
        ...period,
      }),
    {
      enable: !isProvider,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        setClientes(res?.data || []);
      },
    },
  );

  // Desvios x Filiais (Transportador)
  const { isFetching: loadingFiliais } = useQuery(
    ['desvios-filiais', filters, period],
    () =>
      API.getFiliais({
        ...filters,
        ...period,
      }),
    {
      enable: !isProvider,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        setFiliais(res?.data || []);
      },
    },
  );

  // Desvios x Periodo dia
  const { isFetching: loadingPeriodoDia } = useQuery(
    ['desvios-periodo-dia', filters, period],
    () =>
      API.getFatoresRiscoPeriodoDia({
        ...filters,
        ...period,
      }),
    {
      enable: !isProvider,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        setFatoresRiscoPeriodoDia(res?.data || []);
      },
    },
  );

  // Desvios x Dias semana
  const { isFetching: loadingDiaSemana } = useQuery(
    ['desvios-dias-semana', filters, period],
    () =>
      API.getFatoresDiasdaSemana({
        ...filters,
        ...period,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: res => {
        setFatoresRiscoDiasSemana(res?.data || []);
      },
    },
  );

  // Radar de risco
  const { isFetching: loadingRadarRisco } = useQuery(
    ['radar-risco-home', filters],
    () => API.getRadarDeRisco({ ...filters }),
    {
      refetchOnWindowFocus: false,
      onSuccess: res => {
        setRadarRisco(res?.data || []);
      },
    },
  );

  // Desvios graves
  const { isFetching: loadingDesviosGraves } = useQuery(
    ['desvios-graves-home', filters],
    () => API.getFatoresRiscoGraves({ ...filters }),
    {
      refetchOnWindowFocus: false,
      onSuccess: res => {
        setDesviosGraves(res?.data || []);
      },
    },
  );

  return (
    <S.Container>
      <section className="grid grid-template-columns-2">
        <div className="item">
          <Cards handleFilters={setFilters} selecteds={filters} />
        </div>
        <div className="item item-grid">
          <div className="sub-item sub-item-grid">
            <div className="inner-item inner-item-grid">
              <div className="line-item line-item-grid">
                <div className="column-item style-container">
                  <RadarGraph
                    data={radarRisco}
                    loading={loadingRadarRisco}
                    title="Radar de fatores de risco"
                  />
                </div>
                <div className="column-item style-container container-centered-column">
                  <div>
                    <div className="title">Desvios graves e gravíssimos</div>
                    {loadingDesviosGraves ? (
                      <Skeleton animation="wave">
                        <div className="sub-title">
                          Não houve alteração no período.
                        </div>
                      </Skeleton>
                    ) : desviosGraves?.porcentagemCriticidade?.valueReal ? (
                      <>
                        <div className="sub-title">
                          <span
                            style={{
                              color:
                                desviosGraves?.porcentagemCriticidade?.color,
                            }}
                          >
                            {desviosGraves?.porcentagemCriticidade?.isUp
                              ? '▲'
                              : '▼'}{' '}
                            {desviosGraves?.porcentagemCriticidade?.text || ''}{' '}
                          </span>
                          no último mês
                        </div>
                        <div className="sub-container">
                          <div className="text1">
                            Desvio crítico mais recorrente
                          </div>
                          <div className="text2">
                            {desviosGraves?.desvio?.fator_risco}{' '}
                            {desviosGraves?.desvio?.quantidade &&
                              `(${desviosGraves?.desvio?.quantidade})`}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="sub-title">
                        <span>Não houve alteração no período.</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="line-item style-container"
                style={{
                  padding: '20px',
                }}
              >
                <div
                  style={{
                    marginLeft: 60,
                    fontWeight: 900,
                    fontSize: 24,
                    color: '#4B5166',
                  }}
                >
                  Principais fatores de risco
                </div>
                <GraficoFatorDeRisco filters={filters} />
              </div>
            </div>
            <div
              className="inner-item style-container"
              style={{ backgroundColor: 'white' }}
            >
              <GraficoMotoristaOfensores
                filters={filters}
                title={
                  isProvider && !hasTorrePlus && !hasTorre
                    ? 'Top 10 ofensores'
                    : 'Motoristas mais ofensores'
                }
              />
            </div>
          </div>
          <div className="sub-item style-container" style={{ height: '50%' }}>
            <div className="container-bar-grafico">
              <div style={{ width: '30%' }}>
                <GraficoBarraHorizontal
                  isLoading={loadingDiaSemana}
                  handleDate={setPeriod}
                  title="Desvios X Dia da semana"
                  data={fatoresRiscoDiasSemana}
                />
              </div>
              <div style={{ width: '22%' }}>
                <GraficoBarraVertical
                  isLoading={loadingPeriodoDia}
                  title="Desvios X Período do dia"
                  dataKey1="period"
                  data={fatoresRiscoPeriodoDia}
                />
              </div>
              <div style={{ width: '22%' }}>
                <GraficoBarraVertical
                  isLoading={loadingFiliais || loadingVencimentos}
                  title={
                    isProvider ? 'Desvios X Vencimento' : 'Desvios X Filial'
                  }
                  data={isProvider ? vencimentosProvider : filiais}
                  dataKey1={isProvider ? 'titulo' : 'nome_filial'}
                  orderId={filters?.filial ?? null}
                />
              </div>
              <div style={{ width: '20%' }}>
                <GraficoBarraVertical
                  isLoading={loadingCriticidade || loadingClientes}
                  title={isProvider ? 'Desvios X Empresa' : 'Desvios X Cliente'}
                  data={isProvider ? criticidadeProvider : clientes}
                  dataKey1={isProvider ? 'criticidade' : 'nome_distribuidora'}
                  orderId={filters?.client ?? null}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </S.Container>
  );
};

export default FatorRisco;
