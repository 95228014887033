import styled, { css } from 'styled-components';
import Card from '@mui/material/Card';

const Container = styled(Card)`
  width: 100%;
  height: ${props => props.height || '400px'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  padding: 20px;
  margin-top: ${props => props.margintop || '30px'};
`;

const Image = styled.img`
  height: 70%;
`;

const TextButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-height: 400px;
    padding: 20px;

    h1 {
      font: normal normal 900 28px/45px Texta;
      color: ${theme.palette.words.title.natural};
    }

    h2 {
      font: normal normal 600 16px/23px Texta;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
`;

export { Container, TextButtons, Image, ButtonsContainer };
