import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Components
import Podium from 'components/Podium';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';

// Components Material UI
import { Grid, Tooltip } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// Utils
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import * as services from './services';
import * as S from './styled';
import { columns2, columnsCompany2 } from './columns';
import { DefaultTable } from 'components/_Table/templates/default';

const RankingDescarga = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const filter = useSelector(state => state.filterProvider);
  const filterCustom = useSelector(
    state => state.filterDescargaRankingProvider,
  );

  const [data, setData] = useState([]);
  const [podium, setPodium] = useState([]);
  const [excelArray, setExcelArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const sortBy = [
    {
      id: 'posicao',
      desc: false,
    },
  ];

  useEffect(() => {
    getRanking();
  }, [filter, filterCustom]);

  const getRanking = async () => {
    setLoading(true);
    const res = await services.getList({
      ...filter,
      motoristas: filterCustom.motoristas,
      idDesvio: filterCustom.desvio,
      idVeiculo: filterCustom.veiculo,
    });
    if (res.success) {
      const { rows } = res;
      setData(res.rows);
      setExcelArray(res.excel);

      // Format itens to send to Podium component
      const formatedPodium = [];
      if (rows.length) {
        for (let i = 0; i <= 2; i++) {
          if (rows.length > i) {
            formatedPodium.push({
              nameOne: rows[i].filial,
              nameTwo: rows[i].transportadora,
              position: rows[i].posicao,
              oldPosition: rows[i].oldPosition,
              logo: rows[i].logo_url,
              hours: rows[i].horas,
              points: rows[i].pontos,
            });
          }
        }
      }
      setPodium(formatedPodium);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  // -------------------------- EXCEL ------------------------------------------//
  const handleExcel = () => {
    const formatedDate = formatNameDate(new Date());
    ExportToExcel({
      excel: excelArray,
      name: `ranking_descarga_${formatedDate}`,
    });
  };

  // -----------------------TOTAL INFORMATIONS---------------------------------//
  useEffect(() => getInformations(), [data, filter, filterCustom]);

  const [totalBox, setTotalBox] = useState(null);

  const getInformations = () => {
    if (data.length < 0) {
      return null;
    }

    let pontos = 0;
    let exclusoes = 0;
    let qt_desc = 0;
    let desv_ped = 0;
    let desv_fim = 0;

    for (const i in data) {
      data[i].pontos && (pontos += Number(data[i].pontos));
      data[i].quantidade_descarga &&
        (qt_desc += Number(data[i].quantidade_descarga));
      data[i].desvios_excluidos &&
        (exclusoes += Number(data[i].desvios_excluidos));
      data[i].desvios_pendentes &&
        (desv_ped += Number(data[i].desvios_pendentes));
      data[i].desvios_finalizados &&
        (desv_fim += Number(data[i].desvios_finalizados));
    }

    setTotalBox([
      {
        title: 'Pontos',
        count: pontos,
      },
      {
        title: 'Quantidade de Descarga',
        count: qt_desc,
      },
      {
        title: 'Desvios Excluídos',
        count: exclusoes,
      },
      {
        title: 'Desvios Pendentes',
        count: desv_ped,
      },
      {
        title: 'Desvios Finalizados',
        count: desv_fim,
      },
    ]);
  };

  const handleVer = id => {
    if (id) navigate(`/empresas/${id}`);
    else toast.error('Não foi possível acessar os detalhes desta empresa.');
  };

  const actions = [{ title: 'Ver perfil empresa', function: handleVer }];

  return (
    <>
      {!loading && (
        <>
          <Podium data={podium} loading={loading} />

          <Grid
            p={2}
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginBottom: '-80px',
              marginTop: '10px',
            }}
          >
            <GhostButton children="EXPORTAR" onClick={() => handleExcel()} />
          </Grid>

          <DefaultTable
            data={data || []}
            columns={filter.empresas ? columnsCompany2 : columns2}
            actions={actions}
            searchKeys={['posicao', 'transportadora']}
            placeholder="Buscar por Empresa ou Posição..."
            sortBy={{ id: 'posicao', order: 'ASC' }}
          />

          <S.TotalTitle>
            <span>Resumo</span>
            <Tooltip title="Somatorios de todos os valores disponíveis na tabela">
              <HelpOutlineOutlinedIcon
                htmlColor={theme.palette.semantics.feedback.unknown.natural}
                style={{ marginLeft: '10px', cursor: 'pointer' }}
              />
            </Tooltip>
          </S.TotalTitle>
          <S.TotalContentBox>
            {totalBox?.map((item, key) => (
              <div className="boxItem" key={key}>
                <div className="title">{item.title}</div>
                <div className="count">{item.count}</div>
              </div>
            ))}
          </S.TotalContentBox>
        </>
      )}
      {loading && (
        <div>
          <Loading />
        </div>
      )}
    </>
  );
};

export default RankingDescarga;
