import React, { useEffect, useState } from 'react';
import { addMonths } from 'date-fns';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Collapse, Divider, Grid } from '@mui/material';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import GhostButton from 'components/Buttons/Ghost';
import { ProgressBar } from 'components/ProgressBar';
import DefaultButton from 'components/Buttons/Default';
import { Controller, useForm } from 'react-hook-form';
import Calendar from 'components/Inputs/_withController/Calendar';
import DateHour from 'components/Inputs/_withController/DateHour';
import SelectOne from 'components/Inputs/_withController/SelectOne';
import TextField from 'components/Inputs/_withController/TextField';
import InputAttachedFile from 'components/Inputs/_withController/InputAttachedFile';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import Loading from 'components/Loading';
import { GetLocation } from '../components/GetLocation';
import { usePlans } from 'hooks/usePlans';
import {
  CollapseContent,
  Container,
  Flex,
  Footer,
  ListItemButton,
  Option,
  OptionsContainer,
  QuizContainer,
  SpacedRow,
} from './styled';

import {
  getCausas,
  getTiposAcidente,
  getEstadosFisicos,
  saveAcidente,
  getAcidente,
} from '../services';

const defaultValues = {
  id: null,
  id_motorista: '',
  id_placa: '',
  id_tipo_acidente: '',
  data_acidente: '',
  endereco: '',
  km: null,
  cep: '',
  latitude: null,
  longitude: null,
  id_estado_fisico: null,
  id_distribuidora: null,
  acidente_evitavel: null,
};

const infoReqFields = [
  'id_motorista',
  'id_placa',
  'id_tipo_acidente',
  'data_acidente',
];
const localReqFields = ['endereco', 'km', 'cep', 'latitude', 'longitude'];
const _detailReqFields = [
  'id_causa',
  'id_estado_fisico',
  'prazo_investigacao',
  'observacao',
  'arquivo',
  'acidente_evitavel',
];
const _allReqFields = [
  ...infoReqFields,
  ...localReqFields,
  ..._detailReqFields,
];

export const CriarAcidente = ({ edit }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { isProvider } = usePlans();

  const [collapseOpen, setCollapseOpen] = useState('info');
  const [filledPercentage, setFilledPercentage] = useState(0);
  const [infoPercentage, setInfoPercentage] = useState(0);
  const [localPercentage, setLocalPercentage] = useState(0);
  const [detailPercentage, setDetailPercentage] = useState(0);
  const [loading, setLoading] = useState(false);

  const selects = useSelector(state => state.selects);
  const drivers = isProvider
    ? selects['drivers-viagens']?.map(d => ({
        value: d.id,
        text: d.nome,
        agregado: d.agregado,
        filial: d.filial.nome,
      }))
    : selects.drivers.map(d => ({
        value: d.id,
        text: d.nome,
        agregado: d.agregado,
        filial: d.filial.nome,
      }));
  const clients = selects.clients.map(d => ({ value: d.id, text: d.nome }));
  const empresas = selects.empresas.map(d => ({ value: d.id, text: d.nome }));
  const trucks = isProvider
    ? selects['trucks-viagens'].map(item => ({
        text: item.placa,
        value: item.id,
      }))
    : selects.trucks.map(item => ({
        text: item.placa,
        value: item.id,
      }));

  const { data: acidente, isFetching } = useQuery(
    ['acidente', params.id],
    () => getAcidente(params.id),
    {
      onError: error => toast.error(error),
      enabled: edit,
      refetchOnWindowFocus: false,
    },
  );

  const { data: _tiposAcidente = [] } = useQuery(
    ['tipos-acidente'],
    () => getTiposAcidente(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: _causasAcidente = [] } = useQuery(
    ['causas'],
    () => getCausas(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: _estados_fisicos = [] } = useQuery(
    ['estados-fisicos'],
    () => getEstadosFisicos(),
    { refetchOnWindowFocus: false },
  );

  const tiposAcidente = _tiposAcidente.map(item => ({
    text: item.titulo,
    value: item.id,
  }));

  const causas = _causasAcidente.map(item => ({
    text: item.causa,
    value: item.id,
  }));

  const estados_fisicos = _estados_fisicos.map(item => ({
    text: item.estado_fisico,
    value: item.id,
  }));

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    register,
    formState: { errors },
  } = useForm({ defaultValues });

  const retorno_operacao = watch('retorno_operacao');
  const motorista = watch('id_motorista');
  const watchedFields = watch([
    ..._allReqFields,
    'id_empresa',
    'id_distribuidora',
  ]);
  const watchedInfoFields = watch(infoReqFields);
  const watchedLocalFields = watch(localReqFields);
  const watchedDetailFields = watch([
    ..._detailReqFields,
    'id_empresa',
    'id_distribuidora',
  ]);

  useEffect(() => {
    edit && acidente && reset(acidente);
  }, [acidente]);

  useEffect(() => {
    register('endereco', {
      required: { value: true, message: 'Campo obrigatório.' },
    });
  }, [register]);

  useEffect(() => {
    // get filled fields
    const allReqFields = [
      ..._allReqFields,
      isProvider ? 'id_empresa' : 'id_distribuidora',
    ];
    const detailReqFields = [
      ..._detailReqFields,
      isProvider ? 'id_empresa' : 'id_distribuidora',
    ];
    const filledFields = allReqFields.filter(
      (_, index) => !!watchedFields[index] || watchedFields[index] === 0,
    );
    const filledInfoFields = infoReqFields.filter(
      (_, index) =>
        !!watchedInfoFields[index] || watchedInfoFields[index] === 0,
    );
    const filledLocalFields = localReqFields.filter(
      (_, index) =>
        !!watchedLocalFields[index] || watchedLocalFields[index] === 0,
    );
    const filledDetailFields = detailReqFields.filter(
      (_, index) =>
        !!watchedDetailFields[index] || watchedDetailFields[index] === 0,
    );
    // Calculate the percentage
    const percentage = (filledFields.length / allReqFields.length) * 100;
    setFilledPercentage(Number(percentage.toFixed(2)));

    const infoPercentage =
      (filledInfoFields.length / infoReqFields.length) * 100;
    setInfoPercentage(Number(infoPercentage.toFixed(2)));

    const localPercentage =
      (filledLocalFields.length / localReqFields.length) * 100;
    setLocalPercentage(Number(localPercentage.toFixed(2)));

    const detailPercentage =
      (filledDetailFields.length / detailReqFields.length) * 100;
    setDetailPercentage(Number(detailPercentage.toFixed(2)));
  }, [watchedFields]);

  useEffect(() => {
    if (motorista) {
      const driver = drivers.find(d => d.value === motorista);
      setValue('filial', driver?.filial);
      setValue('agregado', driver?.agregado);
    } else {
      setValue('filial', '');
      setValue('agregado', '');
    }
  }, [motorista, drivers]);

  const onSubmit = async data => {
    try {
      setLoading(true);
      const req = await saveAcidente({
        ...data,
      });
      if (req?.success) {
        toast.success(req?.message);
        navigate('/acidentes');
      } else toast.error(req?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.warn('Erro ao salvar.', error);
    }
  };

  // #region Render
  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <Container>
          <SpacedRow>
            <h1>{edit ? `Editar` : `Adicionar`} acidente</h1>
            {edit && (
              <GhostButton
                startIcon={<HistoricoIcon />}
                size="medium"
                onClick={() => navigate(`/logs/acidentes/${params.id}`)}
              >
                HISTÓRICO DE ALTERAÇÕES
              </GhostButton>
            )}
          </SpacedRow>
          <form onSubmit={handleSubmit(onSubmit, err => console.log(err))}>
            <ProgressBar
              label={`${filledPercentage}% concluído`}
              value={filledPercentage}
            />
            <Divider />
            <ListItemButton
              onClick={() => setCollapseOpen('info')}
              color={infoPercentage < 100 ? 'warning' : 'success'}
            >
              <h2>
                Informações Gerais <span>({infoPercentage}%)</span>
              </h2>
              {collapseOpen === 'info' ? (
                <ExpandLessRounded />
              ) : (
                <ExpandMoreRounded />
              )}
            </ListItemButton>
            <Collapse in={collapseOpen === 'info'} timeout="auto">
              <CollapseContent>
                <Grid container columnSpacing={2} rowSpacing={1}>
                  <Grid item xs={12} md={9}>
                    <SelectOne
                      required
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Campo obrigatório.',
                        },
                      }}
                      data={drivers}
                      name="id_motorista"
                      label="Motorista"
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      control={control}
                      name="filial"
                      label="Filial"
                      disabled
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      control={control}
                      name="agregado"
                      label="Agregado"
                      disabled
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectOne
                      required
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Campo obrigatório.',
                        },
                      }}
                      data={trucks}
                      name="id_placa"
                      label="Placa do veículo"
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectOne
                      required
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Campo obrigatório.',
                        },
                      }}
                      data={tiposAcidente}
                      name="id_tipo_acidente"
                      label="Tipo do acidente"
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateHour
                      control={control}
                      errors={errors}
                      rules={{
                        required: {
                          value: true,
                          message: 'Campo obrigatório.',
                        },
                      }}
                      required
                      label="Data do acidente"
                      name="data_acidente"
                      minDate={addMonths(new Date(), -6)}
                    />
                  </Grid>
                </Grid>
              </CollapseContent>
            </Collapse>

            <Divider />
            <ListItemButton
              onClick={() => setCollapseOpen('local')}
              color={localPercentage < 100 ? 'warning' : 'success'}
            >
              <h2>
                Localização <span>({localPercentage}%)</span>
              </h2>
              {collapseOpen === 'local' ? (
                <ExpandLessRounded />
              ) : (
                <ExpandMoreRounded />
              )}
            </ListItemButton>
            <Collapse in={collapseOpen === 'local'} timeout="auto">
              <CollapseContent>
                <GetLocation
                  control={control}
                  watch={watch}
                  errors={errors}
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  originalPos={
                    edit && acidente
                      ? {
                          lat: Number(acidente.latitude),
                          lng: Number(acidente.longitude),
                        }
                      : null
                  }
                />
              </CollapseContent>
            </Collapse>

            <Divider />
            <ListItemButton
              onClick={() => setCollapseOpen('detail')}
              color={detailPercentage < 100 ? 'warning' : 'success'}
            >
              <h2>
                Detalhes <span>({detailPercentage}%)</span>
              </h2>
              {collapseOpen === 'detail' ? (
                <ExpandLessRounded />
              ) : (
                <ExpandMoreRounded />
              )}
            </ListItemButton>
            <Collapse in={collapseOpen === 'detail'} timeout="auto">
              <CollapseContent>
                <Grid container columnSpacing={2} rowSpacing={1}>
                  <Grid item xs={12} md={6}>
                    <SelectOne
                      control={control}
                      data={causas}
                      name="id_causa"
                      label="Causa do acidente"
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectOne
                      required
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Campo obrigatório.',
                        },
                      }}
                      data={estados_fisicos}
                      name="id_estado_fisico"
                      label="Estado fisico"
                      errors={errors}
                    />
                  </Grid>
                  {isProvider ? (
                    <Grid item xs={12} md={6}>
                      <SelectOne
                        required
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Campo obrigatório.',
                          },
                        }}
                        data={empresas}
                        name="id_empresa"
                        label="Empresa"
                        errors={errors}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6}>
                      <SelectOne
                        required
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Campo obrigatório.',
                          },
                        }}
                        data={clients}
                        name="id_distribuidora"
                        label="Cliente"
                        errors={errors}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <Calendar
                      control={control}
                      errors={errors}
                      label="Prazo de investigação"
                      name="prazo_investigacao"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      control={control}
                      label="Observação"
                      name="observacao"
                      placeholder="Preencha a observação"
                      errors={errors}
                      InputProps={{ multiline: true }}
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputAttachedFile
                      control={control}
                      name="arquivo"
                      label="Arquivo"
                      inputLabel="Anexar Arquivo"
                      fileDir="acidentes/arquivos"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="acidente_evitavel"
                    rules={{
                      required: { value: true, message: 'Campo obrigatório.' },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <QuizContainer>
                        <div style={{ flex: 1 }}>
                          <h2>
                            O acidente foi evitável?{' '}
                            <span>
                              *
                              {errors.acidente_evitavel
                                ? ` (${errors.acidente_evitavel.message})`
                                : ''}
                            </span>
                          </h2>
                          <span>
                            Considerando todas as ameaças presentes, o motorista
                            tomou as ações necessárias? Para os acidentes
                            causados por falha mecânica, deve ser analisada a
                            possibilidade do motorista ter identificado algum
                            indício que possibilitasse uma ação preventiva
                            (poucos freios, ruídos, etc). Em caso positivo, será
                            considerado evitável, caso contrário, o acidente
                            deve ser considerado inevitável.
                          </span>
                        </div>
                        <OptionsContainer>
                          <Option
                            type="button"
                            active={value}
                            onClick={() => onChange(1)}
                          >
                            Evitável
                          </Option>
                          <Option
                            type="button"
                            active={!value && value != null}
                            onClick={() => onChange(0)}
                          >
                            Inevitável
                          </Option>
                        </OptionsContainer>
                      </QuizContainer>
                    )}
                  />
                </Grid>
              </CollapseContent>
            </Collapse>
            <Divider />
            <br />
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="retorno_operacao"
                  render={({ field: { value, onChange } }) => (
                    <QuizContainer>
                      <div style={{ flex: 1 }}>
                        <h2>Motorista voltou à operação?</h2>
                      </div>
                      <OptionsContainer>
                        <Option
                          type="button"
                          active={value}
                          onClick={() => onChange(1)}
                        >
                          SIM
                        </Option>
                        <Option
                          type="button"
                          active={!value && value != null}
                          onClick={() => onChange(0)}
                        >
                          NÃO
                        </Option>
                      </OptionsContainer>
                    </QuizContainer>
                  )}
                />
              </Grid>
              {!!retorno_operacao && (
                <Grid item xs={12}>
                  <InputAttachedFile
                    control={control}
                    name="exame_psicologico"
                    label="Anexar exame psicológico"
                    inputLabel="Anexar arquivo"
                    fileDir="acidentes/arquivos"
                  />
                </Grid>
              )}
            </Grid>
            <Footer>
              <GhostButton
                disabled={loading}
                size="medium"
                icon={null}
                onClick={() => navigate(-1)}
              >
                Cancelar
              </GhostButton>

              <DefaultButton loading={loading} size="medium" type="submit">
                Salvar
              </DefaultButton>
            </Footer>
          </form>
        </Container>
      )}
    </>
  );
};
