/* eslint-disable import/order */

// Styles
import * as S from './styled';

// Components
import { Expire } from './Expire';
import Header from 'components/Header';
import Loading from 'components/Loading';
import SidebarExpanded from 'components/Sidebar';
import { NotificationsPush } from './Notifications';
import ConstantsUpdater from 'services/updateConstants';

// Guide
import { InitialGuide } from 'pages/Guia/Inicio';
import { NavigationGuide } from 'pages/Guia/Navigation';

// React && Hooks
import { useTemplate } from './use';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Services
import * as services from './services';

// Constants
import { fixedScroll } from './constants';

export const Template = ({ children }) => {
  // Hooks
  const path = useLocation().pathname;
  const { currentUser, user, contract } = useTemplate();
  const { pageMap, currentPlan } = useSelector(state => state.pageMap);
  const header = useSelector(state => state.header);

  // Sidebar States
  const [bar, setBar] = useState(false);
  const [expire, setExpire] = useState(false);

  // Guide States
  const [infoGuide, setInfoGuide] = useState(null);
  const [dataGuide, setDataGuide] = useState(null);

  // Select States
  const [loaded, setLoaded] = useState(false);

  // ------------------------------------GUIDE-----------------------------------------//
  useEffect(() => {
    !user?.provider && currentUser && getGuideStatus();
    setExpire(contract && !contract?.isActive);
  }, [currentUser]);

  const getGuideStatus = async () => {
    const res = await services.getGuideCheck();
    setInfoGuide((res.data && res?.data[0]) || []);
    if (res.data) {
      const guide = await services.getGuide();
      if (guide.success) {
        setDataGuide(guide.data);
      }
    }
  };

  // ------------------------------------SCROLL-----------------------------------------//
  useEffect(() => {
    if (!fixedScroll.includes(path))
      document
        .getElementById('bodyGlobalWrapper')
        ?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [path]);

  // ------------------------------------RENDER-----------------------------------------//

  return (
    <>
      {currentUser && user && (
        <ConstantsUpdater
          emptyOnly
          setLoaded={setLoaded}
          names={
            user?.provider
              ? ['empresas', 'operations', 'cargas', 'users']
              : ['all']
          }
        />
      )}

      {!currentUser || !pageMap || !currentPlan || !loaded ? (
        <Loading />
      ) : (
        <>
          <NotificationsPush />
          {!header.fullScreenMode && (
            <SidebarExpanded
              sidebarState={bar}
              onClose={() => setBar(false)}
              onOpenSidebar={() => setBar(true)}
            />
          )}
          <S.Body
            id="bodyGlobalWrapper"
            isFullScreenMode={header.fullScreenMode}
          >
            <S.HeaderMain onMouseOver={() => setBar(false)} route={path}>
              {!header.fullScreenMode && <Header />}
              <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
            </S.HeaderMain>
          </S.Body>
          <Expire handleClose={() => setExpire(false)} open={expire} />

          {!user.provider && dataGuide && (
            <InitialGuide
              open={!!dataGuide}
              handleClose={() => setDataGuide(null)}
              data={dataGuide}
              info={infoGuide}
            />
          )}

          {!dataGuide && <NavigationGuide />}
        </>
      )}
    </>
  );
};
