/* eslint-disable prettier/prettier */
import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';

// Material
import { Grid, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SaveAlt from '@mui/icons-material/SaveAlt';

// Estilos
import { useTheme } from 'styled-components';

// Components
import Input from 'components/Inputs/TextField';
import SelectInput from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextField';
import Calendar from 'components/Inputs/Calendar';
import Loading from 'components/Loading';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import ConfirmModal from 'components/ConfirmModal';

import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { formatNewDate, formatNewHour } from 'utils/dates';
import { ExportOcorrenciaPdf } from './Export';
import ImageOcorrencia from './ImageOcorrencia';
// Servicos
import * as request from '../services';

import DropZone from './DropZone';
import ReviewModal from '../ReviewModal';
import * as S from './styled';

const PadDetail = () => {
  const theme = useTheme();
  const [ocorrencia, setOcorrencia] = useState(null);
  const [responsaveis, setResponsaveis] = useState(null);
  const [clients, setClients] = useState(null);
  const [operations, setOperations] = useState(null);

  const [loadingPdf, setLoadingPdf] = useState(false);
  const [printMode, setPrintMode] = useState(false);

  const [fileUrl, setFileUrl] = useState(null);

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [openConfirmRefuseModal, setOpenConfirmRefuseModal] = useState(false);
  const [openConfirmFinishModal, setOpenConfirmFinishModal] = useState(false);

  const {
    user: { user },
  } = useSelector(state => state.auth);

  const componentRef = useRef();
  const navigate = useNavigate();

  const { id } = useParams();

  const fetchData = async () => {
    const res = await request.requestOcorrencia(id);
    if (res.data) {
      if (
        res.data.id_do_desvio &&
        res.data.desvio &&
        res.data.desvio.avaliacao
      ) {
        res.data.id_da_distribuidora = res.data.desvio.id_da_distribuidora;
        res.data.operacao = res.data.desvio.avaliacao.operacao;
      }
      setOcorrencia(res.data);
      setFileUrl(res.data ? res.data.arquivo_evidencia : null);
    }

    const res2 = await request.selectResponsibles();
    if (res2.data) {
      const resps = res2.data.map(usr => ({ name: usr.nome, value: usr.id }));
      setResponsaveis(resps);
    }

    const res3 = await request.selectClients();
    if (res3.data) {
      const client = res3.data.map(clt => ({ name: clt.nome, value: clt.id }));
      setClients(client);
    }

    const res4 = await request.requestOperations();
    if (res4.data) {
      const operation = res4.data.map(op => ({
        name: op.operacao,
        value: op.operacao,
      }));
      setOperations(operation);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (printMode) handlePrint();
  }, [printMode]);

  const handleChange = (key, value) => {
    setOcorrencia(prev => {
      return { ...prev, [key]: value };
    });
  };

  const handleDeleteOcorrencies = async id => {
    setOpenConfirmDeleteModal(true);
  };

  const deleteOcorrencia = async () => {
    const res = await request.deleteOcorrencia([id]);

    if (res.success) {
      toast.success(res.message);
      fetchData();
    } else {
      toast.error(res.message);
    }

    setOpenConfirmDeleteModal(false);
  };

  const handleSalvar = async () => {
    const data = {
      id,
      arquivo_aplicacao: ocorrencia.arquivo_aplicacao
        ? ocorrencia.arquivo_aplicacao
        : null,
      arquivo_evidencia: fileUrl || null,
      id_da_violacao: ocorrencia.id_da_violacao
        ? ocorrencia.id_da_violacao
        : null,
      id_do_desvio: ocorrencia.id_do_desvio ? ocorrencia.id_do_desvio : null,
      id_do_motorista: ocorrencia.id_do_motorista
        ? ocorrencia.id_do_motorista
        : null,
      id_do_responsavel: ocorrencia.id_do_responsavel
        ? ocorrencia.id_do_responsavel
        : null,
      id_do_responsavel_analise: ocorrencia.id_do_responsavel_analise
        ? ocorrencia.id_do_responsavel_analise
        : null,
      justificativa_contestacao: ocorrencia.justificativa_contestacao
        ? ocorrencia.justificativa_contestacao
        : null,
      observacao_evidencia: ocorrencia.observacao_evidencia
        ? ocorrencia.observacao_evidencia
        : '',
      prazo_analise: ocorrencia.prazo_analise ? ocorrencia.prazo_analise : null,
      status: ocorrencia.status ? ocorrencia.status : 'DELETADA',
      data_feedback: ocorrencia.data_feedback ? ocorrencia.data_feedback : null,
      prazo_analise: ocorrencia.prazo_analise ? ocorrencia.prazo_analise : null,
      id_da_distribuidora: ocorrencia.id_da_distribuidora
        ? ocorrencia.id_da_distribuidora
        : null,
      operacao: ocorrencia.operacao ? ocorrencia.operacao : null,
    };

    const res = await request.updateOcorrencia(data);

    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchData();
    } else if (res.data?.message) toast.error(res.data.message);
  };

  const [anchorMenu, setAnchorMenu] = useState(false);
  const openMenu = Boolean(anchorMenu);

  const handleMenu = e => setAnchorMenu(e.currentTarget);

  // const pageStyle = `{ size: 2.5in 4in }`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setPrintMode(false);
      setLoadingPdf(false);
    },
  });

  const [loadingReview, setLoadingReview] = useState(false);
  const [openReview, setOpenReview] = useState(false);

  const handleReviewOcorrencies = async () => {
    setOpenReview(true);
  };

  const reviewOcorrencies = async data => {
    setLoadingReview(true);

    const res = await request.reviewOcorrencia(data);
    if (res.success) {
      toast.success(res.message);
      fetchData();
    } else if (res.message) toast.error(res.message);

    setOpenReview(false);
    setLoadingReview(false);
  };

  const handleRefuseOcorrencies = async () => {
    setOpenConfirmRefuseModal(true);
  };

  const refuseOcorrencies = async () => {
    const res = await request.refuseOcorrencia([id]);

    if (res.success) {
      toast.success(res.message);
      fetchData();
    } else {
      toast.error(res.message);
    }

    setOpenConfirmRefuseModal(false);
  };

  const handleFinishOcorrencies = async () => {
    setOpenConfirmFinishModal(true);
  };

  const finishOcorrencies = async () => {
    const res = await request.finishOcorrencia([id]);

    if (res.success) {
      toast.success(res.message);
      fetchData();
    } else {
      toast.error(res.message);
    }

    setOpenConfirmFinishModal(false);
  };

  const renderActionMenu = () => {
    switch (ocorrencia.status) {
      case 'PENDENTE':
        return (
          <Menu
            open={!!openMenu}
            anchorEl={anchorMenu}
            onClose={() => setAnchorMenu(false)}
            onClick={() => setAnchorMenu(false)} // Fechar ao clicar no item
          >
            <Grid container>
              <Grid item md={12}>
                <MenuItem onClick={() => console.log('Imprimir')}>
                  <Grid item md={9}>
                    <p
                      style={{
                        color: theme.palette.words.title.natural,
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Imprimir
                    </p>
                  </Grid>
                </MenuItem>
                <MenuItem onClick={() => handleReviewOcorrencies()}>
                  <Grid item md={9}>
                    <p
                      style={{
                        color: theme.palette.words.title.natural,
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Enviar para análise
                    </p>
                  </Grid>
                </MenuItem>
                <MenuItem onClick={() => handleFinishOcorrencies()}>
                  <Grid item md={9}>
                    <p
                      style={{
                        color: theme.palette.words.title.natural,
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Finalizar
                    </p>
                  </Grid>
                </MenuItem>
                <MenuItem onClick={() => handleDeleteOcorrencies()}>
                  <Grid item md={9}>
                    <p
                      style={{
                        color: theme.palette.words.title.natural,
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Deletar
                    </p>
                  </Grid>
                </MenuItem>
              </Grid>
            </Grid>
          </Menu>
        );
      case 'ANALISE':
        return (
          <Menu
            open={!!openMenu}
            anchorEl={anchorMenu}
            onClose={() => setAnchorMenu(false)}
            onClick={() => setAnchorMenu(false)} // Fechar ao clicar no item
          >
            <Grid container>
              <Grid item md={12}>
                <MenuItem onClick={() => console.log('Imprimir')}>
                  <Grid item md={9}>
                    <p
                      style={{
                        color: theme.palette.words.title.natural,
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Imprimir
                    </p>
                  </Grid>
                </MenuItem>
                <MenuItem onClick={() => handleFinishOcorrencies()}>
                  <Grid item md={9}>
                    <p
                      style={{
                        color: theme.palette.words.title.natural,
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Finalizar
                    </p>
                  </Grid>
                </MenuItem>
                <MenuItem onClick={() => handleRefuseOcorrencies()}>
                  <Grid item md={9}>
                    <p
                      style={{
                        color: theme.palette.words.title.natural,
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Recusar
                    </p>
                  </Grid>
                </MenuItem>
                <MenuItem onClick={() => handleDeleteOcorrencies()}>
                  <Grid item md={9}>
                    <p
                      style={{
                        color: theme.palette.words.title.natural,
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Deletar
                    </p>
                  </Grid>
                </MenuItem>
              </Grid>
            </Grid>
          </Menu>
        );

      case 'FINALIZADA':
        return (
          <Menu
            open={!!openMenu}
            anchorEl={anchorMenu}
            onClose={() => setAnchorMenu(false)}
            onClick={() => setAnchorMenu(false)} // Fechar ao clicar no itemm
          >
            <Grid container>
              <Grid item md={12}>
                <MenuItem onClick={() => console.log('Imprimir')}>
                  <Grid item md={9}>
                    <p
                      style={{
                        color: theme.palette.words.title.natural,
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Imprimir
                    </p>
                  </Grid>
                </MenuItem>
                <MenuItem onClick={() => handleDeleteOcorrencies()}>
                  <Grid item md={9}>
                    <p
                      style={{
                        color: theme.palette.words.title.natural,
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Deletar
                    </p>
                  </Grid>
                </MenuItem>
              </Grid>
            </Grid>
          </Menu>
        );

      default:
        return (
          <Menu
            open={!!openMenu}
            anchorEl={anchorMenu}
            onClose={() => setAnchorMenu(false)}
            onClick={() => setAnchorMenu(false)} // Fechar ao clicar no item
          >
            <Grid container>
              <Grid item md={12}>
                <MenuItem onClick={() => console.log('Imprimir')}>
                  <Grid item md={9}>
                    <p
                      style={{
                        color: theme.palette.words.title.natural,
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Imprimir
                    </p>
                  </Grid>
                </MenuItem>
              </Grid>
            </Grid>
          </Menu>
        );
    }
  };

  const renderHeader = () => {
    return (
      <S.SpacedRow>
        <h1>Detalhes do ocorrência</h1>

        <S.SpacedRow>
          {/* <GhostButton
                        startIcon={<HistoricoIcon />}
                        size="medium"
                        onClick={() => toast.success('Histórico!')}
                        sx={{ marginLeft: "20px" }}
                    >
                        HISTÓRICO DE ALTERAÇÕES
                    </GhostButton> */}

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={e => setPrintMode(true)}
            style={{ marginLeft: '10px' }}
            loading={loadingPdf}
          >
            IMPRIMIR
          </GhostButton>
        </S.SpacedRow>
      </S.SpacedRow>
    );
  };

  const renderInfo1 = () => {
    const hasImage = ocorrencia.motorista.foto_perfil
      ? ocorrencia.motorista.foto_perfil
      : false;
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={2}>
          {ocorrencia.id_do_desvio &&
          ocorrencia.desvio &&
          ocorrencia.desvio.img_ocorrencia ? (
            <Grid item xs={12} sm={12}>
              <p
                style={{
                  color: theme.palette.words.subtitle.natural,
                  font: 'normal medium 14px/20px Texta',
                }}
              >
                Arquivo evidência
              </p>
              <ImageOcorrencia ocorrencia={ocorrencia} onlyImage={printMode} />
            </Grid>
          ) : (
            <Grid item xs={12} sm={12}>
              <p
                style={{
                  color: theme.palette.words.subtitle.natural,
                  font: 'normal medium 14px/20px Texta',
                }}
              >
                Arquivo evidência
              </p>
              <DropZone
                width="100%"
                height="300px"
                fileUrl={fileUrl}
                setFileUrl={setFileUrl}
                image_ref="arquivo_evidencia/"
                deleteIcon
                company_name={user.filial.empresa.nome}
                text="Arquivo evidência"
              />
            </Grid>
          )}

          <Grid item xs={9} sm={9} display="flex">
            <S.StyledLogoWrapper>
              <Tooltip
                title={ocorrencia.motorista.filial?.nome}
                placement="top"
              >
                <S.StyledLogo backgroundImage={hasImage}>
                  {!hasImage && <h2>{ocorrencia.motorista.nome[0]}</h2>}
                </S.StyledLogo>
              </Tooltip>
            </S.StyledLogoWrapper>
            <S.StyledTextWrapper>
              <h1>
                {ocorrencia.motorista.nome.toLowerCase()}
                {` (${
                  ocorrencia?.driverStatus?.pontuacao
                    ? ocorrencia?.driverStatus?.pontuacao
                    : '0'
                } pts)`}
              </h1>
              {/* <p>Filial: {ocorrencia.motorista.filial?.nome}</p> */}
              <p>
                {ocorrencia.driverStatus &&
                ocorrencia.driverStatus.nivel.nivel &&
                ocorrencia.driverStatus.nivel.restricoes
                  ? `Nível: ${ocorrencia.driverStatus.nivel.nivel} - Restrição: ${ocorrencia.driverStatus.nivel.restricoes}`
                  : `Nível: N/A - Restrição: N/A`}
              </p>
            </S.StyledTextWrapper>
          </Grid>

          <Grid item xs={12} sm={12} md={9}>
            <Input
              label="Violação"
              value={ocorrencia.violacao.descricao || ''}
              name="violacao_descricao"
              id="violacao_descricao"
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <Input
              label="Pontuação"
              value={ocorrencia.violacao.pontos || ''}
              name="violacao_pontos"
              id="violacao_pontos"
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <Input
              label="Reportado em"
              value={formatNewHour(ocorrencia.createdAt) || ''}
              name="createdAt"
              id="createdAt"
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <Input
              label="Data ocorrencia"
              value={formatNewHour(ocorrencia.data_ocorrencia) || ''}
              name="data_ocorrencia"
              id="data_ocorrencia"
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <Input
              label="Status"
              value={ocorrencia.status || ''}
              name="data_ocorrencia"
              id="data_ocorrencia"
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          {renderFooterButtons1()}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  const renderInfo2 = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={2}>
          {ocorrencia.status === 'ANALISE' && (
            <>
              <Grid item xs={8} sm={8}>
                <SelectInput
                  id="responsavel_revisao"
                  label="Responsável pela análise"
                  value={ocorrencia.id_do_responsavel_analise}
                  // defaultValue={ocorrencia?.nome}
                  onChange={e =>
                    handleChange('id_do_responsavel_analise', e.target.value)
                  }
                  placeholder="Atribuir por Responsável"
                  data={responsaveis}
                  disabled
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <Calendar
                  id="prazo_analise"
                  value={ocorrencia.prazo_analise}
                  onChange={newDate => handleChange('data_analise', newDate)}
                  futureDate
                  pastDate={false}
                  label="Prazo para análise"
                  required
                />
              </Grid>
            </>
          )}

          {ocorrencia.status === 'ANALISE' ? (
            <Grid item xs={12} sm={12}>
              <SelectInput
                id="responsavel"
                label={
                  ocorrencia.id_do_responsavel
                    ? 'Responsável'
                    : 'Atribuir Responsável'
                }
                value={ocorrencia.id_do_responsavel}
                disabled={ocorrencia.status !== 'PENDENTE'}
                onChange={e =>
                  handleChange('id_do_responsavel', e.target.value)
                }
                placeholder="Atribuir por Responsável"
                data={responsaveis}
                required
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={8} sm={8}>
                <SelectInput
                  id="responsavel"
                  label={
                    ocorrencia.id_do_responsavel
                      ? 'Responsável'
                      : 'Atribuir Responsável'
                  }
                  value={ocorrencia.id_do_responsavel}
                  disabled={ocorrencia.status !== 'PENDENTE'}
                  onChange={e =>
                    handleChange('id_do_responsavel', e.target.value)
                  }
                  placeholder="Atribuir por Responsável"
                  data={responsaveis}
                  required
                />
              </Grid>

              <Grid item xs={4} sm={4}>
                <Calendar
                  id="data_feedback"
                  value={ocorrencia.data_feedback}
                  onChange={newDate => handleChange('data_feedback', newDate)}
                  futureDate
                  pastDate={false}
                  label="Data Feedback"
                  required
                  disabled={
                    ocorrencia.status === 'APROVADA' ||
                    ocorrencia.status === 'DELETADA'
                  }
                />
              </Grid>
            </>
          )}

          {clients && (
            <Grid item xs={6} sm={6}>
              <SelectInput
                id="cliente"
                label="Cliente"
                value={ocorrencia.id_da_distribuidora}
                disabled={
                  !!ocorrencia.id_do_desvio ||
                  ocorrencia.status === 'APROVADA' ||
                  ocorrencia.status === 'DELETADA'
                }
                onChange={e =>
                  handleChange('id_da_distribuidora', e.target.value)
                }
                placeholder="Atribuir cliente"
                data={clients}
              />
            </Grid>
          )}

          {operations && (
            <Grid item xs={6} sm={6}>
              <SelectInput
                id="operations"
                label="Operação"
                value={ocorrencia.operacao}
                disabled={
                  !!ocorrencia.id_do_desvio ||
                  ocorrencia.status === 'APROVADA' ||
                  ocorrencia.status === 'DELETADA'
                }
                onChange={e => handleChange('operacao', e.target.value)}
                placeholder="Atribuir operação"
                data={operations}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <TextInput
              label="Plano de ação"
              placeholder="plano de ação"
              onChange={e =>
                handleChange('violacao.plano_de_acao', e.target.value)
              }
              multiline
              disabled
              rows={4}
              value={ocorrencia.violacao.plano_de_acao}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              label="Observações"
              placeholder="Inserir observações"
              onChange={e =>
                handleChange('observacao_evidencia', e.target.value)
              }
              multiline
              rows={4}
              value={ocorrencia.observacao_evidencia}
              disabled={
                ocorrencia.status === 'APROVADA' ||
                ocorrencia.status === 'DELETADA'
              }
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputAttachedFile
              idInput="input_file_detalhe_pad"
              label="Arquivo Aplicação"
              inputLabel="Anexar arquivo"
              fileUrl={ocorrencia.arquivo_aplicacao}
              setFileUrl={value => handleChange('arquivo_aplicacao', value)}
              fileDir={`/ocorrencia/${id}`}
              disabled={
                ocorrencia.status === 'APROVADA' ||
                ocorrencia.status === 'DELETADA'
              }
            />
          </Grid>

          {renderFooterButtons2()}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  const renderFooterButtons1 = () => {
    return (
      !printMode && (
        <Grid item xs={12} sm={12} display="flex" justifyContent="flex-start">
          {ocorrencia.status !== 'DELETADA' && (
            <GhostButton
              size="medium"
              customcolor={theme.palette.semantics.feedback.attention.natural}
              onClick={() => handleDeleteOcorrencies()}
            >
              EXCLUIR
            </GhostButton>
          )}
        </Grid>
      )
    );
  };

  const renderFooterButtons2 = () => {
    return (
      !printMode && (
        <>
          <Grid item xs={3} sm={3}>
            <GhostButton
              size="medium"
              onClick={() => navigate('/ocorrencias')}
              display="flex"
              justifyContent="flex-start"
            >
              VOLTAR
            </GhostButton>
          </Grid>
          <Grid item xs={9} sm={9} display="flex" justifyContent="flex-end">
            <GhostButton size="medium" onClick={() => handleSalvar()}>
              SALVAR
            </GhostButton>

            {ocorrencia.status === 'PENDENTE' && (
              <GhostButton
                size="medium"
                customcolor={theme.palette.semantics.feedback.warning.natural}
                onClick={() => handleReviewOcorrencies()}
                style={{ marginLeft: '10px' }}
              >
                Enviar para análise
              </GhostButton>
            )}

            {ocorrencia.status === 'ANALISE' && (
              <GhostButton
                size="medium"
                customcolor={theme.palette.semantics.feedback.attention.natural}
                onClick={() => handleRefuseOcorrencies()}
                style={{ marginLeft: '10px' }}
              >
                Recusar
              </GhostButton>
            )}

            {ocorrencia.status !== 'APROVADA' &&
              ocorrencia.status !== 'DELETADA' && (
                <DefaultButton
                  size="medium"
                  onClick={() => handleFinishOcorrencies()}
                  style={{ marginLeft: '10px' }}
                >
                  Finalizar
                </DefaultButton>
              )}
          </Grid>
        </>
      )
    );
  };

  const renderAssinaturas = () => {
    return (
      printMode && (
        <S.Card>
          <S.CardHeader>
            <h1>Assinaturas</h1>
          </S.CardHeader>
          <S.CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <S.Assinatura>
                  <S.AsignTitle>
                    {ocorrencia.responsavel ? (
                      ocorrencia.responsavel.nome.toLowerCase()
                    ) : (
                      <S.NoContent>Aguardando Assinatura</S.NoContent>
                    )}
                  </S.AsignTitle>
                  <S.AsignDate>{formatNewDate(new Date())}</S.AsignDate>
                  <hr />
                  <h4>Responsavel</h4>
                </S.Assinatura>
              </Grid>
              <Grid item xs={4}>
                <S.Assinatura>
                  <S.AsignTitle> Aguardando Assinatura</S.AsignTitle>
                  <S.AsignDate>{formatNewDate(new Date())}</S.AsignDate>
                  <hr />
                  <h4>Testemunha (opcional)</h4>
                </S.Assinatura>
              </Grid>
              <Grid item xs={4}>
                <S.Assinatura>
                  <S.AsignTitle>
                    {ocorrencia.motorista.nome.toLowerCase()}
                  </S.AsignTitle>
                  <S.AsignDate>{formatNewDate(new Date())}</S.AsignDate>
                  <hr />
                  <h4>Motorista</h4>
                </S.Assinatura>
              </Grid>
            </Grid>
          </S.CardContent>
        </S.Card>
      )
    );
  };

  // TODO: aqui acontece a impressão
  return ocorrencia && responsaveis ? (
    <>
      {renderHeader()}
      <S.Main ref={componentRef}>
        <Grid
          container
          spacing="20px"
          marginTop="10px"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid item xs={12} sm={12} md={printMode ? 12 : 6}>
            {renderInfo1()}
          </Grid>

          {printMode && <Grid item xs={12} height="350px" />}

          <Grid item xs={12} sm={12} md={printMode ? 12 : 6}>
            {renderInfo2()}
          </Grid>
          {printMode && (
            <ExportOcorrenciaPdf
              ocorrencia={ocorrencia}
              aluno={ocorrencia.motorista}
              printRef={componentRef}
              responsaveis={responsaveis}
            />
          )}
        </Grid>
      </S.Main>

      <ConfirmModal
        handleClose={() => setOpenConfirmDeleteModal(false)}
        open={!!openConfirmDeleteModal}
        title="Deseja excluir ocorrência?"
        subtitle="Seus pontos deixarão de contar para este motorista."
        buttonText="Confirmar"
        onClick={() => deleteOcorrencia()}
      />

      <ConfirmModal
        handleClose={() => setOpenConfirmRefuseModal(false)}
        open={!!openConfirmRefuseModal}
        title="Deseja recusar ocorrência?"
        subtitle="O status desta ocorrência será atualizado para Pendente."
        buttonText="Confirmar"
        onClick={() => refuseOcorrencies()}
      />

      <ConfirmModal
        handleClose={() => setOpenConfirmFinishModal(false)}
        open={!!openConfirmFinishModal}
        title="Deseja finalizar esta ocorrência?"
        subtitle="O status desta ocorrência será atualizado para Finalizado."
        buttonText="Confirmar"
        onClick={() => finishOcorrencies()}
      />

      {openReview && (
        <ReviewModal
          handleConfirm={reviewOcorrencies}
          ids={id}
          handleClose={() => setOpenReview(false)}
          responsaveis={responsaveis}
          loading={loadingReview}
        />
      )}
    </>
  ) : (
    <Loading />
  );
};

export default PadDetail;
