const listOptionsSelect = [
  { value: 'Motorista foi contatado.', label: 'Motorista foi contatado.' },
  {
    value: 'Veículo impedido de continuar viagem.',
    label: 'Veículo impedido de continuar viagem.',
  },
  {
    value: 'Viagem foi interrompida e novo motorista foi solicitado.',
    label: 'Viagem foi interrompida e novo motorista foi solicitado.',
  },
];
export { listOptionsSelect };
