import { formatNewDate } from 'utils/dates';

export const columns = [
  {
    header: 'Desvio',
    id: 'desvio',
    type: 'string',
    sort: true,
  },
  {
    header: 'Data Desvio',
    id: 'data_desvio',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewDate(item?.data_desvio),
  },
  {
    header: 'Pontuação',
    id: 'pontos',
    type: 'number',
    sort: true,
  },
];

export const formatData = data => {
  return data.map(item => ({
    ...item,
    desvio:
      `(${String(
        item.desvio_tipo?.desvio_tipo_padrao?.categoria,
      ).toLocaleLowerCase()}) ${
        item.desvio_tipo?.desvio_tipo_padrao?.titulo
      }` ?? '-',
  }));
};
