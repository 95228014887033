export const columns = [
  {
    header: 'Seguindo',
    id: 'seguindo',
    type: 'boolean',
    align: 'center',
    sort: false,
    width: 80,
  },
  {
    header: 'Cliente',
    id: 'nome',
    type: 'string',
    sort: true,
    subRow: {
      prefix: 'IBM:',
      value: (_, item) => item?.ibm,
    },
  },
  {
    header: 'Executor',
    id: 'executor.nome',
    type: 'string',
    sort: false,
    subRow: {
      prefix: 'Filial:',
      value: (_, item) => item.executor.filial.nome,
    },
  },
  {
    header: 'Tam max caminhão (m³)',
    id: 'tamanho_maximo',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Atualizado em',
    id: 'updatedAt',
    type: 'date',
    sort: true,
    align: 'center',
    subRow: {
      prefix: 'Vencimento:',
      value: (_, item) => item?.vencimento,
      type: 'date',
    },
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    align: 'center',
    conditional: [
      {
        condition: value => value === 'LIBERADO',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'RESTRITO',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'BLOQUEADO',
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
    attention: (_, item) => {
      if (item.cae.length)
        return {
          text: 'Ver CAE.',
        };
    },
  },
];
