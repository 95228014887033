import { React, useRef, useMemo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Icon from 'components/Icons';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ReactComponent as add } from 'images/Grupo 12749.svg';

import { Button, SvgIcon, Grid, IconButton } from '@mui/material';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import SelectInput from 'components/Inputs/Select';
import SelectOne from 'components/Inputs/SelectOne';
import Calendar from 'components/Inputs/Calendar';
import DataHour from 'components/Inputs/DataHour';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import TextInput from 'components/Inputs/TextField';
import { useTheme } from 'styled-components';
import DropZone from '../Detalhe/DropZone';
import * as S from './styled';

import * as request from '../services';

const AddOcorrencia = ({
  handleClose,
  open,
  disabled,
  drivers,
  violations,
  responsibles,
  clients,
  closeModal,
  setUpdated,
}) => {
  const theme = useTheme();
  const motoristaRef = useRef(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [ocorrencia, setOcorrencia] = useState({
    id_do_motorista: null,
    data_ocorrencia: null,
    data_feedback: null,
    id_da_violacao: null,
    plano_de_acao: null, // Plano de ação está atrelado ao id_da_violacao
    pontuacao: null,
    id_do_responsavel: null,
    arquivo_evidencia: null,
    arquivo_aplicacao: null,
    id_da_distribuidora: null,
  });
  const {
    user: { user },
  } = useSelector(state => state.auth);
  const driver = useMemo(
    () => drivers.find(item => item.id === ocorrencia.id_do_motorista),
    [drivers, ocorrencia.id_do_motorista],
  );

  const handleChange = (name, value) => {
    if (name !== 'id_da_violacao')
      setOcorrencia(prev => {
        return { ...prev, [name]: value };
      });
    else {
      setOcorrencia(prev => {
        return { ...prev, [name]: value };
      });
      setOcorrencia(prev => {
        return {
          ...prev,
          plano_de_acao: violations.find(item => item.id === value)
            .plano_de_acao,
        };
      });
      setOcorrencia(prev => {
        return {
          ...prev,
          pontuacao: violations.find(item => item.id === value).pontos,
        };
      });
    }
  };

  useEffect(() => {
    if (fileUrl)
      setOcorrencia(prev => {
        return { ...prev, arquivo_evidencia: fileUrl };
      });
  }, [fileUrl]);

  const handleCreation = async data => {
    if (
      !data.id_do_motorista ||
      !data.data_ocorrencia ||
      !data.data_feedback ||
      !data.id_da_violacao ||
      !data.id_da_violacao
    )
      toast.error('Preencha todos os campos obrigatórios!');
    else {
      const req = await request.createOcorrencia(data);

      if (req.data.success) toast.success(req.data.message);
      else toast.error(req.data.message);
      setUpdated(true);
      closeModal(false);
    }
  };

  const body = (
    <>
      <S.Container>
        <S.Header>
          <div style={{ alignItems: 'center' }}>
            <>
              <SvgIcon
                sx={{ color: theme.palette.brand.secondary.natural }}
                component={add}
                fontSize="medium"
              />
              <h2>Criar Nova Ocorrência</h2>
            </>
          </div>

          <IconButton size="small" onClick={handleClose}>
            <Icon
              sx={{ color: theme.palette.words.subtitle.natural }}
              name="close"
            />
          </IconButton>
        </S.Header>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} marginTop="20px">
              <p
                style={{
                  color: disabled
                    ? theme.palette.system.black
                    : theme.palette.words.subtitle.natural,
                  font: 'normal medium 14px/20px Texta',
                }}
              >
                Selecione um motorista
                <span
                  style={{ fontSize: '20px', fontWeight: 500, color: 'red' }}
                >
                  *
                </span>
              </p>
            </Grid>

            <Grid item xs={12} sm={12} display="flex">
              <S.ImageArea>
                {driver?.foto && <img src={driver.foto} />}
                {driver?.foto === null && (
                  <span className="letters">{driver.nome[0]}</span>
                )}
                {driver === null ||
                  (driver === undefined && <AddOutlinedIcon />)}
              </S.ImageArea>
              <S.TextArea style={{ width: '38%' }}>
                <SelectOne
                  value={ocorrencia.id_do_motorista}
                  handleChange={value => handleChange('id_do_motorista', value)}
                  data={drivers}
                  tam="365px"
                  // required
                  // label={driver ? "Motorista selecionado:" : "Selecione um motorista"}
                  type="driver"
                  refs={motoristaRef}
                />
              </S.TextArea>
            </Grid>

            {/* <Grid item xs={6} sm={6}>
                            <Calendar
                                value={ocorrencia.data_ocorrencia}
                                onChange={(newDate) => handleChange("data_ocorrencia", newDate)}
                                futureDate={false}
                                pastDate={true}
                                label={"Data Ocorrência"}
                                required={true}
                            />
                        </Grid> */}

            <Grid item xs={6} sm={6}>
              <DataHour
                value={ocorrencia.data_ocorrencia}
                onChange={newDate => handleChange('data_ocorrencia', newDate)}
                futureDate={false}
                pastDate
                label="Data Ocorrência"
                required
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Calendar
                value={ocorrencia.data_feedback}
                onChange={newDate => handleChange('data_feedback', newDate)}
                futureDate
                pastDate={false}
                label="Data Feedback"
                required
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SelectInput
                required
                handleChange={value =>
                  handleChange('id_da_violacao', value.target.value)
                }
                name="id_da_violacao"
                label="Violação"
                data={violations.map(item => {
                  return { name: item.descricao, value: item.id };
                })}
                value={ocorrencia.id_da_violacao}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SelectInput
                required
                handleChange={value =>
                  handleChange('id_do_responsavel', value.target.value)
                }
                name="responsavel"
                label="Responsável"
                data={responsibles}
                value={ocorrencia.id_do_responsavel}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SelectInput
                required
                handleChange={value =>
                  handleChange('id_da_distribuidora', value.target.value)
                }
                name="cliente"
                label="Cliente"
                data={clients}
                value={ocorrencia.id_da_distribuidora}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextInput
                label="Plano de ação"
                placeholder="plano de ação"
                onChange={e => handleChange('plano_de_acao', e.target.value)}
                multiline
                disabled
                rows={4}
                value={ocorrencia.plano_de_acao}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <p
                style={{
                  color: disabled
                    ? theme.palette.system.black
                    : theme.palette.words.subtitle.natural,
                  font: 'normal medium 14px/20px Texta',
                }}
              >
                {'Arquivo evidência'}{' '}
              </p>
              <DropZone
                width="100%"
                height="300px"
                fileUrl={fileUrl}
                setFileUrl={setFileUrl}
                image_ref="arquivo_evidencia/"
                deleteIcon
                company_name={user.filial.empresa.nome}
                text="Arquivo Evidência"
                // isNew={isNew}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <InputAttachedFile
                idInput="input_aplicacao_ocorrencia"
                label="Arquivo aplicação"
                inputLabel="Anexar aplicação"
                fileUrl={ocorrencia.arquivo_aplicacao}
                setFileUrl={value => handleChange('arquivo_aplicacao', value)}
                fileDir="/ocorrencia/nova"
              />
            </Grid>
          </Grid>
        </S.Main>

        <S.Footer>
          <div>
            <GhostButton onClick={handleClose} size="medium">
              CANCELAR
            </GhostButton>

            <DefaultButton
              onClick={() => handleCreation(ocorrencia)}
              size="medium"
              sx={{ marginLeft: '20px' }}
            >
              CRIAR
            </DefaultButton>
          </div>
        </S.Footer>
      </S.Container>
    </>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      {disabled && <Loading />}
    </div>
  );
};

export default AddOcorrencia;
