import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { formatNewDate, formatNewHourClean } from 'utils/dates';
import { firestorage } from 'utils/firebase';
import { useCallback } from 'react';
import MapDesvio from '../../components/MapDesvio';
import { getResponsaveis } from '../../services';
import { useQuery } from 'react-query';
// Columns

import * as S from './styled';
import { getTipoDesvio } from 'pages/Desvios/constants';

export const ExportToPdf = ({ infraction, responsavel, cliente, printRef }) => {
  const logo = require('images/onisys.png');
  const [responsaveis, setResponsaveis] = useState([]);
  const getFileName = useCallback(fileUrl => {
    try {
      let newName = '-';
      if (fileUrl) newName = firestorage.refFromURL(fileUrl)?.name;
      return newName;
    } catch (error) {
      console.log(error);
    }
  }, []);

  function getIdsComCheckTrue(data) {
    const idsComCheckTrue = data
      .filter(item => item.check === true)
      .map(item => item.nome);
    return idsComCheckTrue;
  }

  useQuery(
    ['usuario-responsaveis', infraction?.id],
    () => getResponsaveis(infraction?.id),
    {
      onSuccess: (data = []) => {
        setResponsaveis(getIdsComCheckTrue(data));
      },
      refetchOnWindowFocus: false,
    },
  );

  const tipoDesvio = getTipoDesvio(infraction);

  return (
    <S.ModalMUI onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="formIdent">
            <img src={logo} />
          </div>

          <div className="formIdent">
            <div className="titleForm">
              <span>Motorista: {infraction?.motorista?.nome}</span>
            </div>
            <div className="titleForm">
              <span>Desvio: {infraction?.id}</span>
            </div>
            {cliente?.logo_url && (
              <div className="logoArea">
                <img src={cliente?.logo_url} />
              </div>
            )}
          </div>
        </S.Infos>
        <S.CardHeader>
          <h1>Informações do desvio</h1>
        </S.CardHeader>
        <S.Header>
          <div className="topInfos">
            <div className="coverArea">
              {tipoDesvio === 'MANUAL' ? (
                <S.Image bg={infraction.desvio_info?.arquivo_evidencia} />
              ) : infraction.latitude ? (
                <MapDesvio
                  position={{
                    lat: parseFloat(infraction.latitude),
                    lng: parseFloat(infraction.longitude),
                  }}
                />
              ) : (
                <S.Image bg={infraction.desvio_info?.imagem} />
              )}
            </div>
            <div className="textsArea">
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Motorista:</div>
                  <S.InputToPDF>
                    {infraction.motorista?.nome || 'Motorista não identificado'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Filial:</div>
                  <S.InputToPDF>
                    {infraction.motorista?.filial?.nome ||
                      infraction.caminhao?.filial?.nome ||
                      'Não definida'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="normalText">Desvio:</div>
                  <S.InputToPDF>
                    {infraction.desvio_tipo?.titulo ?? '-'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="normalText">Data do desvio:</div>
                  <S.InputToPDF>
                    {(infraction?.data_desvio &&
                      formatNewHourClean(infraction?.data_desvio, true)) ||
                      '-'}
                  </S.InputToPDF>
                </Grid>
                {!!infraction?.valor && (
                  <>
                    <Grid item xs={12} md={4}>
                      <div className="normalText">Velocidade:</div>
                      <S.InputToPDF>{`${infraction.valor} Km/h`}</S.InputToPDF>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <div className="normalText">Limite de velocidade:</div>
                      <S.InputToPDF>
                        {infraction.desvio_tipo?.limiar ||
                        infraction.velocidade_via
                          ? `${
                              infraction.desvio_tipo?.limiar ||
                              infraction.velocidade_via
                            } Km/h`
                          : 'Não informado'}
                      </S.InputToPDF>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={4}>
                  <div className="normalText">Pontuação:</div>
                  <S.InputToPDF>
                    {` ${infraction?.pontos || '-'}`}
                    {'  Pontos'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="normalText">Criticidade:</div>
                  <S.InputToPDF>
                    {` ${infraction?.criticidade || '-'}`}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Tipo de Veiculo:</div>
                  <S.InputToPDF>
                    {infraction?.caminhao?.tipo_veiculo?.tipo ||
                      'Não informado'}
                  </S.InputToPDF>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="normalText">Placa:</div>
                  <S.InputToPDF>
                    {infraction?.caminhao?.placa || '-'}
                  </S.InputToPDF>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="normalText">Localização:</div>
                  <S.InputToPDF>
                    {infraction?.endereco
                      ? infraction.endereco
                      : infraction?.latitude
                      ? `lat: ${infraction.latitude}, lng: ${infraction.longitude}`
                      : 'Não informado'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="normalText">Condições:</div>
                  <S.InputToPDF>
                    {`${
                      infraction?.carregado
                        ? 'Veículo carregado; '
                        : infraction?.carregado === false
                        ? 'Veículo vazio; '
                        : ''
                    }${
                      infraction?.pista_molhada
                        ? 'Pista molhada;'
                        : infraction?.pista_molhada === false
                        ? 'Pista seca;'
                        : ''
                    }`}
                  </S.InputToPDF>
                </Grid>
              </Grid>
            </div>
          </div>
        </S.Header>
        <S.CardHeader>
          <h1>Informações Complementares</h1>
        </S.CardHeader>
        <S.Header>
          <div className="bottomInfos">
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <div className="normalText">Responsável:</div>
                {responsaveis?.length ? (
                  <div style={{ display: 'flex' }}>
                    {responsaveis.map(nome => (
                      <div className="label-hilight">{nome}</div>
                    ))}
                  </div>
                ) : (
                  <S.InputToPDF>{responsavel?.nome || '-'}</S.InputToPDF>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="normalText">Previsão de conclusão:</div>
                <S.InputToPDF>
                  {(infraction?.data_previsao &&
                    formatNewDate(infraction?.data_previsao)) ||
                    '-'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="normalText">Plano de ação:</div>
                <S.InputToPDF lines={4}>
                  {infraction?.desvio_info?.plano_acao || '-'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="normalText">Arquivo:</div>
                <S.InputToPDF>
                  {getFileName(infraction?.desvio_info?.arquivo_observacao)}
                </S.InputToPDF>
              </Grid>
              {infraction.status === 'DELETADO' &&
                !!infraction.desvio_info?.arquivo_exclusao && (
                  <Grid item xs={12} md={12}>
                    <div className="normalText">
                      Arquivo Justificativa Exclusão:
                    </div>
                    <S.InputToPDF>
                      {getFileName(infraction.desvio_info?.arquivo_exclusao)}
                    </S.InputToPDF>
                  </Grid>
                )}
              {infraction?.desvio_info?.motivo_exclusao && (
                <Grid item xs={12} md={12}>
                  <div className="normalText">Motivo da exclusão</div>
                  <S.InputToPDF>
                    {infraction?.desvio_info?.motivo_exclusao || '-'}
                  </S.InputToPDF>
                </Grid>
              )}
              {infraction?.desvio_info?.observacao_exclusao && (
                <Grid item xs={12} md={12}>
                  <div className="normalText">
                    Observação da exclusão (transportadora)
                  </div>
                  <S.InputToPDF>
                    {infraction?.desvio_info?.observacao_exclusao || '-'}
                  </S.InputToPDF>
                </Grid>
              )}
            </Grid>
          </div>
        </S.Header>
      </S.Container>
    </S.ModalMUI>
  );
};
