import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { subHours } from 'date-fns';

// components custom
import { Skeleton } from '@mui/material';
import { PeriodPicker } from 'pages/Estatisticas/Components/PeriodPicker';

// styles
import * as S from './styled';

const CustomTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#95AAC9"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

export const History = ({
  titulo = '',
  data = [],
  dates,
  handleDate,
  loading,
}) => {
  return (
    <S.Container print={print}>
      <div className="container-filters">
        <div className="graphtitle">{titulo}</div>
        <div className="titletop">
          <PeriodPicker
            finalDate={dates.finalDate}
            initialDate={dates.initialDate}
            period="lastYear"
            periodAdd="month"
            onChange={date => {
              handleDate({
                initialDate: date.initialDate,
                finalDate: subHours(date.finalDate, 3),
              });
            }}
          />
        </div>
      </div>
      {loading ? (
        <Skeleton
          style={{ margin: '1rem' }}
          width="98%"
          height={174}
          animation="wave"
          variant="rounded"
        />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            height={300}
            data={data}
            margin={{ top: 10, right: 40, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              height={70}
              tick={<CustomTick />}
              interval={0}
            />
            <YAxis />
            <Tooltip />

            <Line
              type="linear"
              dataKey="x"
              name="Valor"
              stroke="#0C12F2"
              dot={props => {
                const { index, payload } = props;
                const isLastDot = index === data.length - 1;
                return (
                  <circle
                    cx={props.cx}
                    cy={props.cy}
                    r={5}
                    fill={isLastDot ? 'none' : '#0C12F2'} // Se for o último ponto, não preenche
                    stroke={isLastDot ? '#0C12F2' : 'none'} // Se for o último ponto, define a cor da borda
                    strokeWidth={isLastDot ? 2 : 0} // Se for o último ponto, define a largura da borda
                  />
                );
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </S.Container>
  );
};
