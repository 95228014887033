export const calculaDecaimento = violacao => {
  let decaimento_pontos = violacao.pontos;

  if (violacao.prazo && violacao.decaimento_tempo) {
    if (violacao.decaimento_tempo > 0 && violacao.prazo > 0) {
      const periodos = violacao.prazo / violacao.decaimento_tempo;
      decaimento_pontos = Math.round((violacao.pontos / periodos) * 10) / 10;
    } else decaimento_pontos = violacao.prazo;
  }
  return decaimento_pontos;
};

export const calculaExpiracao = (prazo, data_ocorrencia) => {
  const hoje = new Date();
  const diasDecorridos =
    (hoje - new Date(data_ocorrencia)) / 1000 / 60 / 60 / 24;
  let diasRestantes = Math.round(prazo - diasDecorridos);
  if (diasRestantes < 0) diasRestantes = 0;
  return { restantes: diasRestantes, decorridos: diasDecorridos };
};
