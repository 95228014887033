import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
`;

export const Header = styled.div`
  display: flex;
  gap: 1rem;
`;

export const TabContainer = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 4fr 3fr;
  gap: 1rem;

  @media screen and (max-width: 1400px) {
    grid-template-columns: 1fr;
  }

  .bw {
    filter: saturate(0.3) !important;
  }
`;
