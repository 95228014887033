import {
  getAgregados,
  getMotoristas,
  getStatusMotorista,
  getVeiculos,
  getDesviosTiposPadrao,
} from 'constants/_SERVICES/provider';

// PAGES
import Drivers from 'pages/Provider/Motoristas';
import LogMotorista from 'pages/Provider/Motoristas/History';
import DriverDetail from 'pages/Provider/Motoristas/PerfilMotorista';
import ModalAddEmMassa from 'pages/Provider/Motoristas/ModalAddEmMassa';
import RankingMotoristas from 'pages/Provider/RankingMotoristas';

export default {
  '/motoristas': {
    date: true,
    defaults: ['empresas', 'filials'],
    pageFilterName: 'filterDriverProvider',
    filters: [
      {
        name: 'agregado',
        placeholder: 'Filtrar por Agregado',
        mode: 'single',
        section: 'Motoristas',
        data: async () => getAgregados(),
      },
      {
        name: 'status',
        placeholder: 'Filtrar por Status',
        mode: 'single',
        section: 'Motoristas',
        data: async () => getStatusMotorista(),
      },
    ],
    page: <Drivers />,
  },
  '/motoristas/add-motoristas': {
    date: true,
    back: -1,
    defaults: [],
    page: <ModalAddEmMassa />,
  },

  '/motoristas/:id': {
    date: true,
    back: -1,
    defaults: [],
    page: <DriverDetail />,
  },
  '/logs/driver/:id': {
    back: -1,
    defaults: [],
    page: <LogMotorista />,
  },

  '/motoristas/rankings': {
    date: true,
    defaults: ['empresas'],
    pageFilterName: 'filterRankingDriverProvider',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        mode: 'multiple',
        section: 'Desvio, Veículo e Motorista',
        data: async () => getDesviosTiposPadrao(),
      },
      {
        name: 'veiculo',
        placeholder: 'Filtrar por Veículo',
        mode: 'single',
        section: 'Desvio, Veículo e Motorista',
        data: async () => getVeiculos(),
      },
      {
        name: 'motoristas',
        placeholder: 'Filtrar por Motoristas',
        mode: 'single',
        section: 'Desvio, Veículo e Motorista',
        data: async () => getMotoristas(),
      },
    ],
    page: <RankingMotoristas />,
  },
};
