import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Divider } from '@mui/material';
import Tabs from 'components/Tabs';
import InfoCard from 'components/Cards/InfoCard';
import { trackEvent } from 'utils/mixpanel';

import Cards from 'pages/_templates/ListPage/components/Cards';
import { FilterTable } from 'components/_Table/templates/filter';
import FinishModal from '../FinishModal';

import {
  getAcoesSuspensoes,
  getCards,
  fetchClientsWithFaixas,
} from './services';
import { columns, columnsProvider, getStatus } from './constants';

import { setFilter } from 'store/modules/filterAcoesSuspensoes/actions';
import { finishAcaoSuspensao } from '../Detalhe/services';
import {
  getAcaoDisciplinar,
  getFaixasAcoes,
  getStatusAcaoSuspensao,
} from 'constants/_SERVICES/user';
import { usePlans } from 'hooks/usePlans';
import { setFilterProvider } from 'store/modules/provider/filterAcoesSuspensoesProvider/actions';

const ListAcoes = ({ query, setQuery, resetTable, setResetTable }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth?.user?.user);
  const { isProvider, isOpLogistico } = usePlans();
  const filter = useSelector(state =>
    isProvider ? state.filterProvider : state.filter,
  );
  const filterAcoesSuspensoes = useSelector(state =>
    isProvider
      ? state.filterAcoesSuspensoesProvider
      : state.filterAcoesSuspensoes,
  );

  const { data: clients = [] } = useQuery(
    ['clients-with-faixas'],
    () => fetchClientsWithFaixas(),
    {
      enabled: !isProvider,
      staleTime: Infinity,
    },
  );

  const [tab, setTab] = useState('');
  const [selectedData, setSelectedData] = useState([]);

  const [loadingLines, setLoadingLines] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [finishModal, setFinishModal] = useState({
    ids: [],
    open: false,
    isSuspensao: false,
  });

  const {
    data: resData,
    isFetching,
    refetch,
  } = useQuery(['acoes-suspensoes', query], () => getAcoesSuspensoes(query), {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: () => setResetTable(false),
  });

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cards
  const {
    refetch: fetchCards,
    isLoading: fetchingCards,
    data: resCards,
  } = useQuery(
    [
      'cards-acoes-suspensoes',
      {
        ...filter,
        client: filterAcoesSuspensoes.client,
        empresa: filterAcoesSuspensoes.empresa,
        card: '',
      },
    ],
    () =>
      getCards({
        ...filter,
        client: filterAcoesSuspensoes.client,
        empresa: filterAcoesSuspensoes.empresa,
        card: '',
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const handleChangeTab = tab => {
    setTab(tab);
    dispatch(setFilter({ client: tab }));
    setResetTable(true);
    fetchCards();
  };

  // Tabs de transportador funcionam como filtro
  const headerTabs = {
    value: tab,
    items: [{ value: '', label: 'Minhas ações' }, ...clients],
    onChange: (_, newValue) => handleChangeTab(newValue),
    disabled: isFetching || fetchingCards,
  };

  // avisa a tabela que houve alteracao nos filtros
  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
    fetchCards();
  }, [filter]);

  // executa a busca quando a tabela atualiza a query
  useEffect(() => {
    if (!initial.current && query) {
      dispatch(
        setFilter({
          acaoDisciplinar: query.acaoDisciplinar,
          faixa: query.faixa,
          status: query.status,
        }),
      );
      refetch();
    }
  }, [query]);

  const handleFinalizar = async data => {
    setLoadingModal(true);

    const res = await finishAcaoSuspensao(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
    } else {
      if (res.data?.message) toast.error(res.data.message);
    }
    setResetTable(true);
    setLoadingModal(false);
    setFinishModal({ isSuspensao: false, ids: [], open: false });
  };

  // Verifica os itens selecionados para finalizar
  const verifyIdsFinish = useCallback(
    ids => {
      const selectedItems = resData?.data?.data?.length
        ? resData.data.data.filter(item => ids.includes(item.id))
        : [];
      // Confere se seleção tem a mesma filial
      const filialIds = [];
      for (const acao of selectedItems) {
        const filialId = acao.id_filial;
        if (!filialIds.includes(filialId)) filialIds.push(filialId);
      }

      if (filialIds.length > 1) {
        toast.error('Selecione apenas motoristas de uma única filial.');
        return;
      }
      if (selectedItems.some(item => item.status === 'LIBERADO')) {
        toast.error('Selecione apenas motoristas ainda não liberados.');
        return;
      }
      if (selectedItems.every(item => getStatus(item.status) === 'SUSPENSO')) {
        setFinishModal({ isSuspensao: true, ids, open: true });
      } else if (
        selectedItems.every(item => getStatus(item.status) !== 'SUSPENSO')
      ) {
        setFinishModal({ isSuspensao: false, ids, open: true });
      } else {
        toast.error(
          'Selecione apenas motoristas suspensos ou apenas não suspensos.',
        );
      }
    },
    [resData?.data],
  );

  const handleClickCard = type => {
    if (!fetchingCards && !isFetching) {
      const cards = resCards || [];
      const card = cards.find(item => item.type === type);
      if (card.value) {
        let _card = '';
        if (type !== filterAcoesSuspensoes.card) _card = type;
        dispatch(
          isProvider
            ? setFilterProvider({ card: _card })
            : setFilter({ card: _card }),
        );
        setResetTable(true);
      }
    }
  };

  // calcula index para passar para detalhe
  const getIndex = id => {
    const _data = [...(resData?.data?.data ?? [])];
    isProvider
      ? dispatch(setFilterProvider({ sortBy: query.sortBy }))
      : dispatch(setFilter({ sortBy: query.sortBy }));

    const dataIndex = _data.findIndex(item => item.id == id);
    if (dataIndex !== -1) {
      return query.pageSize * query.pageIndex + dataIndex;
    }
    return '';
  };

  // açoes da tabela
  const actions = [
    {
      title: 'Ver detalhe',
      function: id => navigate(`/acoes-suspensoes/${id}?index=${getIndex(id)}`),
    },
  ];

  // bulkActions
  const bulkActions = [
    {
      title: 'Finalizar',
      function: ids => {
        trackEvent(user, 'FINALIZAR AÇÔES');
        verifyIdsFinish(ids);
      },
    },
  ];

  const sortBy = {
    id: 'pontos',
    order: 'DESC',
  };

  // FILTER OPTIONS
  const { data: faixas = [] } = useQuery(
    ['faixas-filter', tab],
    () => getFaixasAcoes(!isProvider ? { client: tab } : {}),
    { refetchOnWindowFocus: false },
  );
  const { data: acoesDisciplinares = [] } = useQuery(
    ['acao-disciplinar-filter'],
    () => getAcaoDisciplinar(),
    { staleTime: Infinity },
  );
  const { data: status = [] } = useQuery(
    ['status-filter'],
    () => getStatusAcaoSuspensao(),
    { staleTime: Infinity },
  );

  let filters = [
    {
      label: 'Filtrar por Faixa',
      placeholder: 'Selecione a faixa',
      data: faixas,
      mode: 'single',
      key: 'faixa',
    },
    {
      label: 'Filtrar por Ação disciplinar',
      placeholder: 'Selecione a ação disciplinar',
      data: acoesDisciplinares,
      mode: 'single',
      key: 'acaoDisciplinar',
    },
    {
      label: 'Filtrar por Status',
      placeholder: 'Selecione o status',
      data: status,
      mode: 'single',
      key: 'status',
    },
  ];

  return (
    <>
      {!isProvider && (
        <>
          <Tabs {...headerTabs} />
          <Divider />
        </>
      )}

      {tab && (
        <InfoCard
          message="As ações disciplinares do cliente são apenas sugestões de ações a serem tomadas para cada faixa de pontuação. A suspensão pode ser obrigatória, de acordo com a configuração do cliente."
          key="info"
        />
      )}

      <Cards
        cards={resCards?.map(card => ({ ...card, disabled: isFetching })) || []}
        selectedCard={filterAcoesSuspensoes.card}
        loadingCards={fetchingCards}
        handleClickCard={handleClickCard}
      />

      <FilterTable
        data={resData?.data?.data || []}
        columns={isProvider && !isOpLogistico ? columnsProvider : columns}
        filters={filters}
        loading={isFetching}
        pageCount={resData?.data?.total || 0}
        local={false}
        actions={actions}
        reset={resetTable}
        bulk={bulkActions}
        onClickRow={id =>
          navigate(`/acoes-suspensoes/${id}?index=${getIndex(id)}`)
        }
        setSelectedRows={
          !isProvider || !filterAcoesSuspensoes.empresa ? setSelectedData : null
        }
        loadingSelection={loadingLines}
        setQuery={q =>
          setQuery({
            ...filter,
            card: filterAcoesSuspensoes.card,
            client: filterAcoesSuspensoes.client,
            empresa: filterAcoesSuspensoes.empresa,
            ...q,
          })
        }
        searchEvent={search =>
          trackEvent(user, 'Busca Ações e Suspensões de empresa', null, search)
        }
        sortBy={sortBy}
        placeholder={
          isProvider && !isOpLogistico
            ? 'Buscar Matrícula ou Faixa'
            : 'Buscar Motorista'
        }
        empty={{
          title: 'Ops! Você não tem nenhuma ação ou suspensão disponível.',
          description: 'Verifique os filtros aplicados!',
        }}
      />

      {finishModal?.open && (
        <FinishModal
          handleConfirm={handleFinalizar}
          ids={finishModal?.ids}
          handleClose={() =>
            setFinishModal({ isSuspensao: false, open: false, ids: [] })
          }
          loading={loadingModal}
          isSuspensao={finishModal.isSuspensao}
        />
      )}
    </>
  );
};

export default ListAcoes;
