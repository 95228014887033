import React from 'react';
import { Grid } from './styled';
import Card from 'components/Cards/Indicador';

const Cards = ({
  cards = [],
  selectedCard,
  disableCards,
  loadingCards,
  handleClickCard,
}) => {
  return (
    <Grid>
      {loadingCards ? (
        <Card loading={loadingCards} />
      ) : (
        cards.map(card => {
          return (
            <Card
              key={card.type}
              value={card.value}
              icon={card.icon}
              text={card.text}
              handleClick={() => {
                if (handleClickCard instanceof Function)
                  handleClickCard(card.type);
              }}
              selected={selectedCard === card.type}
              disabled={disableCards}
              loading={card.loading}
            />
          );
        })
      )}
    </Grid>
  );
};

export default Cards;
