import { defaultStaticRanges } from 'components/Header/DateRangePicker/defined';
import { differenceInHours, addDays, startOfDay } from 'date-fns';
import * as types from './types';

const today = new Date();
const last30 = startOfDay(addDays(new Date(), -30));

const INITIAL_STATE = {
  client: '',
  filial: '',
  filial_veiculo: '',
  operation: '',
  initialDate: last30,
  finalDate: today,
  dateLabel: 'Últimos 30 dias',
};

export default function filter(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_FILTER: {
      return { ...state, ...action.payload };
    }
    case types.SET_INITIAL_STATE_DATE: {
      // Verifica se há um preset de data selecionado de defaultStaticRanges (dateLabel)
      // Se sim, atualiza as datas
      if (state.dateLabel) {
        const findedRange = defaultStaticRanges.find(
          item => item.label === state.dateLabel,
        );
        if (findedRange) {
          const range = findedRange.range();
          // Há diferençã entre os horarios registrados e novos valores?
          if (
            Math.abs(
              differenceInHours(
                new Date(range.endDate),
                new Date(state.finalDate),
              ),
            ) > 6 ||
            Math.abs(
              differenceInHours(
                new Date(range.startDate),
                new Date(state.initialDate),
              ),
            ) > 6
          ) {
            return {
              ...state,
              initialDate: range.startDate,
              finalDate: range.endDate,
            };
          }
        } else return state;
      }
      // Quando não há um preset, não faz nada
      return state;
    }
    default:
      return state;
  }
}
