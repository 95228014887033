import api from 'services/api';

const requestInfractions = async query => {
  const res = await api.get('/infractions', { params: query });
  return res;
};

const fetchInfraction = async id => {
  const res = await api.get(`/infraction/${id}`);
  return res;
};

const requestHistory = async id => {
  const res = await api.get(`/infraction-logs/${id}`);
  return res;
};

const requestCards = async query => {
  const res = await api.get('/infraction-cards', { params: query });
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel/infractions', { params: query });
  return res;
};

const saveInfractions = async data => {
  const res = await api.put(`/infractions-update`, data);
  return res;
};

const deleteInfractions = async data => {
  const res = await api.put(`/infractions-delete`, data);
  return res;
};

const approveInfractions = async data => {
  const res = await api.put(`/infractions-approve`, data);
  return res;
};

const viewInfractions = async data => {
  const res = await api.put(`/infractions-view`, data);
  return res;
};

const finishInfractions = async data => {
  const res = await api.put(`/infractions-finish`, data);
  return res;
};

const sendPadInfractions = async data => {
  const res = await api.post(`/infractions-send-pad`, data);
  return res;
};

export {
  requestInfractions,
  fetchInfraction,
  requestCards,
  requestExcel,
  saveInfractions,
  approveInfractions,
  deleteInfractions,
  finishInfractions,
  viewInfractions,
  sendPadInfractions,
  requestHistory,
};
