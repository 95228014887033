import * as S from './styled';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from 'components/Cards/Indicador';
import { DefaultTable } from 'components/_Table/templates/default';

import { Grid } from '@mui/material';

import { trackEvent } from 'utils/mixpanel';

// Services
import * as request from './services';

// Columns
import { columns } from './columns';

const PadRanking = () => {
  const filter = useSelector(state => state.filter);
  const user = useSelector(state => state.auth?.user?.user);

  const [niveis, setNiveis] = useState(null);
  const [ranking, setRanking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mediaPontos, setMediaPontos] = useState(0);
  const [cardsRanking, setCardsRanking] = useState([{}, {}, {}]);

  const navigate = useNavigate();

  const handleViewDriver = id => {
    if (id && id !== undefined) navigate(`/motoristas/${id}`);
    else toast.error('Id não encontrado');
  };

  const actions = [{ title: 'Ver motorista', function: handleViewDriver }];

  const fetchRanking = async () => {
    setLoading(true);
    const filters = { ...filter };

    const res = await request.requestNiveis();
    if (res.success) setNiveis(res.data);
    else toast.error('Não foram encontrados níveis cadastrados');

    const res2 = await request.requestRanking(filters);
    if (res2.success) {
      const totalPontos = res2.data.reduce((acc, cur) => {
        return acc + cur.pontuacao;
      }, 0);
      const media = Math.round(totalPontos / res2.data.length);
      setMediaPontos(media);
      setRanking(res2.data);
      setCardsRanking(res2.cards);
    } else toast.error('Não foi possível encontrar a lista de motoristas.');

    setLoading(false);
  };

  useEffect(() => {
    fetchRanking();
  }, [filter]);

  const filterCards = () => {
    const newData = ranking;
    let selectedCard = false;
    if (cardsRanking && cardsRanking.length > 0) {
      const cards = cardsRanking.map(card => {
        if (card.selected) selectedCard = card;
      });
    }

    if (selectedCard) {
      const teste = newData.filter(item => selectedCard.ids.includes(item.id));
      setRanking(teste);
      trackEvent(user, `PAD Ranking - ${selectedCard.text}`);
    } else fetchRanking();
  };

  const handleClickCards = name => {
    const newCards = cardsRanking.map(card => {
      if (name === card.name) {
        if (card.selected !== undefined) {
          card.selected = !card.selected;
        }
      } else if (card.selected) card.selected = false;
      return card;
    });
    setCardsRanking(newCards);
    filterCards();
  };

  const renderTop = () => {
    return (
      <S.TitleWrapper>
        <h1>PAD - Ranking</h1>
      </S.TitleWrapper>
    );
  };

  const renderCards = () => {
    const cards = cardsRanking;
    return (
      <Grid container spacing={2} marginBottom="25px">
        {cards?.map(card => {
          return (
            <Grid item key={card.name} xs={12} md={4} xl={4}>
              <Card
                value={card.value}
                icon={card.icon}
                text={card.text}
                handleClick={() => handleClickCards(card.name)}
                selected={card.selected}
                loading={loading}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderTable = () => {
    return (
      <DefaultTable
        data={ranking || []}
        columns={columns}
        actions={actions}
        searchKeys={['nome', 'matricula']}
        loading={loading}
        placeholder="Buscar Motorista"
        sortBy={{ id: 'pontuacao', order: 'DESC' }}
        searchEvent={search =>
          trackEvent(user, 'Busca Ranking do PAD', null, search)
        }
        empty={{
          title: 'Nenhum motorista encontrado',
          description: 'Você ainda não possui nenhum motorista no ranking.',
        }}
      />
    );
  };

  return (
    <S.Main>
      {renderTop()}
      {renderCards()}
      {renderTable()}
    </S.Main>
  );
};

export default PadRanking;
