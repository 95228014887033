import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { startOfMonth, subMonths } from 'date-fns';

import { usePlans } from 'hooks/usePlans';
import { trackEvent } from 'utils/mixpanel';

// Components
import ConstantsUpdater from 'services/updateConstants';
import {
  AddCircleOutlineOutlined,
  FileDownloadOutlined,
} from '@mui/icons-material';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import { DefaultTable } from 'components/_Table/templates/default';
import CustomDateRangePicker from 'components/Header/DateRangePicker';
import { History } from '../Acidentes/components/History';
import { TotaisPorTipos } from '../Acidentes/components/TotaisPorTipos';
import { Header, Container, TableHeader, Title } from './styled';
import { downloadFromLink } from 'utils/helpers';

import {
  colorsGravidade,
  columns,
  columnsProvider,
  fields,
  fieldsProvider,
} from './constants';
import {
  getMultas,
  getHistoricoMultas,
  getTotais,
  requestExcel,
} from './services';
import { toast } from 'react-toastify';

export const Multas = () => {
  const navigate = useNavigate();
  const { isProvider } = usePlans();
  const filter = useSelector(state =>
    isProvider ? state.filterProvider : state.filter,
  );
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const [loadingExcel, setLoadingExcel] = useState(false);
  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);
  const [historyDates, setHistoryDates] = useState({
    finalDate: new Date(),
    initialDate: startOfMonth(subMonths(new Date(), 11)),
  });

  const { isLoading, data: resData } = useQuery(
    ['multas', query],
    () => query && getMultas(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        resetTable && setResetTable(false);
      },
    },
  );

  const { isLoading: loadingHistorico, data: resHistorico } = useQuery(
    ['multas-historico', historyDates],
    () => getHistoricoMultas(historyDates),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { isLoading: loadingTotais, data: resTotais } = useQuery(
    ['multas-totais', filter],
    () => getTotais(filter),
    {
      refetchOnWindowFocus: false,
    },
  );

  // request de exportação
  const handleRequestExcel = useCallback(async () => {
    setLoadingExcel(true);
    const newQuery = {
      ...query,
      excelFields: isProvider ? fieldsProvider : fields,
    };
    const res = await requestExcel(newQuery);
    if (res.link) {
      downloadFromLink(res.link);
      toast.success(res.message);
    } else if (res.message) toast.error(res.message);

    setLoadingExcel(false);
  }, [query]);

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter]);

  const getIndex = id => {
    const _data = [...(resData?.data ?? [])];

    const dataIndex = _data.findIndex(item => item.id == id);
    if (dataIndex !== -1) {
      return query.pageSize * query.pageIndex + dataIndex;
    }
    return '';
  };

  const handleOpenDetails = id => {
    const url = `/multas/${id}?index=${getIndex(id)}&sortBy=${
      query.sortBy?.id ?? ''
    }&order=${query.sortBy?.order ?? ''}`;
    navigate(url);
  };

  const handleOpenNewTab = id => {
    const url = `/multas/${id}?index=${getIndex(id)}&sortby=${
      query.sortBy?.id ?? ''
    }&order=${query.sortBy?.order ?? ''}`;
    window.open(url);
  };

  const actions = [
    {
      title: 'Abrir em nova guia',
      function: handleOpenNewTab,
    },
    {
      title: 'Editar',
      function: id => navigate(`/multas/editar/${id}`),
      visible: item =>
        (isProvider && item.provider) || (!isProvider && !item.provider),
    },
  ];

  // #region Render
  return (
    <Container>
      {isProvider && (
        <ConstantsUpdater
          emptyOnly
          names={['drivers-viagens', 'trucks-viagens']}
        />
      )}
      <Header>
        <GhostButton
          children="Adicionar em massa"
          icon={
            <FileDownloadOutlined style={{ transform: 'rotate(180deg)' }} />
          }
          onClick={() => navigate('/multas/add-multas')}
        />
        <DefaultButton
          children="Novo registro"
          icon={<AddCircleOutlineOutlined />}
          onClick={() => navigate(`/multas/criar`)}
        />
      </Header>

      <br />

      <History
        titulo="Histórico de multas:"
        data={resHistorico || []}
        dates={historyDates}
        handleDate={dates => setHistoryDates(dates)}
        loading={loadingHistorico}
      />

      <br />

      <TableHeader>
        <Title>Registros</Title>
        <div className="flex">
          <CustomDateRangePicker height="40px" />
          <GhostButton
            children="Exportar"
            icon={<FileDownloadOutlined />}
            loading={loadingExcel}
            onClick={() => {
              trackEvent(user, 'EXPORTAR PLANILHA');
              handleRequestExcel();
            }}
          />
        </div>
      </TableHeader>

      <br />

      <TotaisPorTipos
        titulo="Total de multas:"
        data={
          resTotais
            ? [
                {
                  tipo_acidente: 'LEVE',
                  total: resTotais.LEVE ?? 0,
                  cor: colorsGravidade.LEVE,
                },
                {
                  tipo_acidente: 'MÉDIA',
                  total: resTotais.MÉDIA ?? 0,
                  cor: colorsGravidade.MÉDIA,
                },
                {
                  tipo_acidente: 'GRAVE',
                  total: resTotais.GRAVE ?? 0,
                  cor: colorsGravidade.GRAVE,
                },
                {
                  tipo_acidente: 'GRAVÍSSIMA',
                  total: resTotais.GRAVÍSSIMA ?? 0,
                  cor: colorsGravidade.GRAVÍSSIMA,
                },
              ]
            : []
        }
        loading={loadingTotais}
      />

      <br />

      <DefaultTable
        data={resData?.data || []}
        columns={isProvider ? columnsProvider : columns}
        loading={isLoading}
        pageCount={resTotais?.total || 0}
        local={false}
        actions={actions}
        reset={resetTable}
        loadingSelection={loadingLines}
        onClickRow={handleOpenDetails}
        setQuery={q => setQuery({ ...q, ...filter })}
        searchEvent={search => trackEvent(user, 'Buscar Multa', null, search)}
        sortBy={{ id: 'data_multa', order: 'DESC' }}
        placeholder="Busca por motorista, tipo e local"
        empty={{
          title: 'Ops! Você não tem nenhum multa disponível.',
          description: 'Verifique os filtros aplicados!',
        }}
      />
    </Container>
  );
};
