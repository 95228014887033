import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  faixa: '',
  acaoDisciplinar: '',
  status: '',
  dashboard: '',
  client: '',
  card: '',
  sortBy: { id: 'pontos', order: 'DESC' },
};

export default function filterAcoesSuspensoes(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER: {
        return { ...state, ...action.payload };
      }
      case types.RESET_FILTER: {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
