import { getStatusItem, getResponsaveisPad } from 'constants/_SERVICES/user';

// Pages
import Pad from 'pages/OLD_Pad';
import PadDetail from 'pages/OLD_Pad/Detalhe';
import PadRanking from 'pages/OLD_PadRanking';
import PadConfiguration from 'pages/OLD_PadConfiguration';
import PadConfigurationNiveis from 'pages/OLD_PadConfiguration/ConfigNiveis';
import PadConfigurationViolacoes from 'pages/OLD_PadConfiguration/ConfigViolacoes';

export default {
  '/ocorrencias': {
    defaults: ['filials', 'clients'],
    pageFilterName: 'filterPad',
    date: true,
    filters: [
      {
        name: 'responsavel',
        placeholder: 'Filtrar por Responsáveis',
        mode: 'multiple',
        section: 'Motoristas e Responsáveis',
        data: async () => getResponsaveisPad(),
      },
      {
        name: 'status',
        placeholder: 'Filtrar por Status',
        mode: 'single',
        section: 'Motoristas e Responsáveis',
        data: async () => getStatusItem(),
      },
    ],
    page: <Pad />,
  },

  '/ocorrencia/:id': {
    back: -1,
    title: 'Ocorrência',
    id: true,
    defaults: [],
    page: <PadDetail />,
  },

  '/pad/ranking': {
    date: true,
    back: -1,
    defaults: ['filials'],
    page: <PadRanking />,
  },

  '/pad/configuracoes': {
    back: -1,
    defaults: [],
    page: <PadConfiguration />,
  },

  '/pad/configuracoes/niveis': {
    back: -1,
    defaults: [],
    page: <PadConfigurationNiveis />,
  },

  '/pad/configuracoes/violacoes': {
    back: -1,
    defaults: [],
    page: <PadConfigurationViolacoes />,
  },
};
