import { Modal } from '@mui/material';
import styled, { css } from 'styled-components';
import colors from 'styles/colors';

export const ModalMUI = styled.div`
  position: relative;
  display: none;
  overflow: hidden;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    padding: 10px 10px;
    overflow-y: scroll;
    overflow-x: hidden;

    .box {
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    .lineGraph {
      position: relative;
      background-color: red;
      overflow: hidden;
    }
  `}
`;

export const Infos = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    .formIdent {
      font-weight: bold;
      color: ${theme.palette.words.title.natural};
      display: flex;
      flex-direction: column;
      font-size: 8px;
    }
    img {
      width: 60px;
    }
  `}
`;
export const InputToPDF = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 18px;
  border-radius: 2px;
  font-size: 10px;
  color: ${colors.greyTitle};
  border: 1px solid ${colors.greyTiny};
  padding-left: 4px;
  overflow: hidden;
`;
export const Header = styled.div`
  ${({ theme }) => css`
    border-left: 1px solid ${colors.grey};
    border-right: 1px solid ${colors.grey};
    border-bottom: 1px solid ${colors.grey};
    border-radius: 1px;
    padding: 5px;
    .lineApprover {
      width: 100%;
      border-bottom: 1px solid ${colors.greyTiny};
    }
    .containerSignatures {
      //margin-top: 15px;
      display: flex;
      flex-direction: column;

      .signaturesUser {
        font-size: 10px;
        color: ${colors.greyTitle};
      }
    }
    .containerSignaturesTipe {
      font-size: 8px;
      color: ${colors.greySubtitle};
    }
    .topInfos {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 15px;

      .coverArea {
        flex: 1;
        width: 100%;
        height: 200px;
        border-radius: 5px;
        .background {
          background-image: url(${props => props.bg});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          width: ${props => (props.bg ? '30vw' : '0vw')};
          min-height: 50%;
          border-radius: 5px;
        }
      }
      .textsArea {
        margin-left: ${props => (props.bg ? '10px' : '0px')};

        .titleForm {
          font-weight: 800;
          font-size: 25px;
          color: ${colors.greyTitle};
        }

        .normalText {
          font-weight: 600;
          font-size: 8px;
          color: #9a9ea8;
          margin-top: 2px;
        }

        .normalTexttwo {
          font-weight: 600;
          font-size: 8px;
        }
        .descriptionForm {
        }
      }
    }

    .bottomInfos {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      .card {
        flex: 1;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        .coverArea {
          // flex: 2;
          width: 100%;
          height: 200px;
          border-radius: 5px;

          .background {
            background-image: url(${props => props.bg});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100px;
            height: 80px;
            border-radius: 5px;
          }
        }

        .textsArea {
          //flex: 4;
          margin-left: 20px;

          .titleForm {
            font-weight: 800;
            font-size: 25px;
            color: ${theme.palette.words.title.natural};
          }

          .normalText {
            font-weight: 600;
            font-size: 10px;
            color: ${theme.palette.words.subtitle.natural};
          }

          .normalTexttwo {
            font-weight: 600;
            font-size: 8px;
          }

          .descriptionForm {
          }
        }
      }

      .bottomInfos {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        .card {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          .logoCompany {
            // margin-right: 30px;

            img {
              max-height: 80px;
            }
          }

          .cardInfos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title {
              font-size: 16px;
              font-weight: 500;
              color: ${theme.palette.words.subtitle.natural};
              text-align: center;
            }

            .numbers {
              font-weight: 600;
              font-size: 18px;
              color: ${theme.palette.words.title.natural};
            }
          }
        }

        .bar {
          height: 100%;
          min-height: 100px;
          width: 1px;
          background-color: ${theme.palette.semantics.feedback.unknown.natural};
        }
      }
    }
  `}
`;

export const Header2 = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableArea = styled.div`
  ${({ theme }) => css`
    position: relative;

    .coverInput {
      width: 100%;
      height: 100px;
      background-color: ${theme.palette.system.overlay};
      position: absolute;
      top: 0;
      z-index: 3;
    }

    .coverInputBottom {
      width: 100%;
      height: 100px;
      background-color: ${theme.palette.system.overlay};
      position: absolute;
      bottom: 0;
      z-index: 3;
    }
  `}
`;

export const Paragraph = styled.p`
  ${({ theme }) => css`
    font-weight: bold;
    color: ${theme.palette.brand.primary.natural};
    font-size: 14px;
    text-align: ${props => (props.center ? 'center' : 'start')};

    &.normal {
      font-size: 16px;
    }

    &.name {
      text-transform: capitalize;
    }

    &.small {
      font-weight: 500;
      font-size: 12px;
    }

    &.green {
      color: ${theme.palette.semantics.feedback.success.natural};
      background-color: ${theme.palette.semantics.feedback.success.light};
      border-radius: 20px;
      text-align: center;
    }

    &.orange {
      color: ${theme.palette.semantics.feedback.warning.natural};
      background-color: ${theme.palette.semantics.feedback.warning.light};
      border-radius: 20px;
      text-align: center;
    }

    &.red {
      color: ${theme.palette.semantics.feedback.attention.natural};
      background-color: ${theme.palette.semantics.feedback.attention.light};
      border-radius: 20px;
      text-align: center;
    }
  `}
`;

export const ColDriver = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    h1 {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.brand.primary.natural};
    }
  `}
`;

export const CardHeader = styled.div`
  ${({ theme }) => css`
    background: #373f54;
    margin-top: 10px;
    border-radius: 5px 5px 0px 0px;

    h1 {
      color: ${theme.palette.words.title.contrast};
      font-size: 10px;
      font-weight: 900;
      text-align: center;
    }
  `}
`;

export const Assinatura = styled.div`
  text-align: center;

  hr {
    margin: 20px 0;
  }

  h4 {
    color: #9a9ea8;
  }
`;

export const AsignTitle = styled.p`
  font-size: 10px;
  text-transform: capitalize;
`;

export const NoContent = styled.p`
  height: 42px;
  font-size: 10px;
`;

export const AsignDate = styled.p`
  font-size: 8px;
  text-transform: capitalize;
`;
