import * as types from './types';

export function setFilter(payload) {
  return {
    type: types.SET_FILTER,
    payload,
  };
}

export function setInitialStateDate() {
  return {
    type: types.SET_INITIAL_STATE_DATE,
    payload: {},
  };
}
