import React, { useState, useEffect } from 'react';

// react dom
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// components
import Divider from '@mui/material/Divider';
import Button from 'components/Buttons/Default';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { toast } from 'react-toastify';
import { DefaultTable } from 'components/_Table/templates/default';
import { trackEvent } from 'utils/mixpanel';
import { exportToExcel } from 'utils/exportToCvs';

// styles
import { useTheme } from 'styled-components';

// icons
import { AddCircleOutline, SaveAlt } from '@mui/icons-material';

// components
import GhostButton from 'components/Buttons/Ghost/index.js';
import CadastroGestor from 'pages/Configuracoes/Usuarios/ModaUsuarios';
import CadastroMotorista from 'pages/Motoristas/ModalAddMotorista';
import * as S from './style.js';
import { getMotoristas, inativateDriver, inativateGestor } from './services';

// colums
import { columnsAluno } from './columns';
import { formatNameDate } from 'utils/dates/index.js';

const Alunos = () => {
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const theme = useTheme();
  const navigate = useNavigate();

  const [openModalAddGestor, setOpenModalAddGestor] = useState(false);
  const [openModalAddMotorista, setOpenModalAddMotorista] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDriver, setIsDriver] = React.useState(true);

  const [dataMotorista, setDataMotorista] = useState([]);
  const [dataUsuario, setDataUsuario] = useState([]);

  // const [actionsAlunos, setActionsAlunos] = useState([]);
  const [currentUserEditing, setActionsUserEditing] = useState({});

  const [loading, setLoading] = useState(false);

  const fetchUsers = () => {
    setLoading(true);
    getMotoristas().then(res => {
      const motoristas = res.data.drivers;
      const { users } = res.data;
      motoristas.map(item => {
        Object.assign(item, {
          filial_name: item.filial.nome,
          turmas: item.turmas,
          conteudos: item.conteudos,
        });
        return item;
      });
      users.map(item => {
        Object.assign(item, {
          filial_name: item.filial.nome,
          turmas: item.turmas,
          conteudos: item.conteudos,
        });
        return item;
      });
      setDataMotorista(motoristas);
      setDataUsuario(users);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const sortBy = [];

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetExcluir = async (id, type) => {
    const status = [...dataMotorista, ...dataUsuario].some(item =>
      !item.status ? 'ATIVO' : 'INATIVO',
    );
    if (type === 'GESTOR') {
      const res = await inativateGestor(id, { status });
      if (res.data.success) {
        toast.success(res.data.message);
        fetchUsers();
      } else {
        toast.error(res.data.message);
      }
    } else if (type === 'MOTORISTA') {
      const res = await inativateDriver(id);
      if (res.data.success) {
        toast.success(res.data.message);
        fetchUsers();
      } else {
        toast.error(res.data.message);
      }
    }
  };

  const handleEditDriver = id => {
    const item = dataMotorista.find(item => item.id === id);
    delete item.turmas;
    delete item.nomes_turmas;
    delete item.conteudos;
    delete item.clients;
    delete item.status;
    item.id_da_empresa = item.filial.id_da_empresa;
    delete item.filial;
    Object.assign(item, {
      agregado: item.agregado ? 'SIM' : 'NÃO',
    });
    setActionsUserEditing(item);
    setOpenModalAddMotorista(true);
  };
  const handleEditUser = id => {
    const item = dataUsuario.find(item => item.id === id);
    const newObj = {};
    Object.assign(newObj, {
      id: item.id,
      nome: item.nome,
      email: item.email,
      telefone_de_contato: item.telefone_de_contato,
      status: item.status,
      funcao: item.funcao,
      filial: { id: item.id_da_filial },
      matricula: item.matricula,
      nivel: item.nivel,
      foto: item.foto,
    });
    setActionsUserEditing(newObj);
    setOpenModalAddGestor(true);
  };

  // Exportção excel
  const handleExcel = async () => {
    try {
      setLoadingExcel(true);
      const excelData = {
        Motoristas: dataMotorista.map(item =>
          columnsAluno.reduce((acc, curr) => {
            acc[curr.header] = item[curr.id];
            return acc;
          }, {}),
        ),
        Gestores: dataUsuario.map(item =>
          columnsAluno.reduce((acc, curr) => {
            acc[curr.header] = item[curr.id];
            return acc;
          }, {}),
        ),
      };

      const formatedDate = formatNameDate(new Date());
      exportToExcel(excelData, `capacitacao_alunos_${formatedDate}`);
      toast.success('Arquivo gerado com sucesso!');
    } catch (err) {
      // console.log(err);
      toast.error('Falha ao gerar arquivo.');
    }
    setLoadingExcel(false);
  };

  const actionsAlunos = [
    {
      function: id => {
        navigate(
          `/capacitacao/perfil-do-aluno/info-aluno?id=${id}&tipo=${
            isDriver ? 'motorista' : 'gestor'
          }`,
        );
      },
      title: 'Ver Perfil',
    },
    {
      title: 'Ativar/Inativar',
      function: id => handleSetExcluir(id, isDriver ? 'MOTORISTA' : 'GESTOR'),
    },
    {
      title: 'Editar',
      function: id => (isDriver ? handleEditDriver(id) : handleEditUser(id)),
    },
  ];

  return (
    <S.Container>
      {/* usuario cadastro */}
      {openModalAddGestor && (
        <CadastroGestor
          open={openModalAddGestor}
          handleClose={() => setOpenModalAddGestor(false)}
          dataEdit={currentUserEditing}
          fetchData={() => fetchUsers()}
        />
      )}
      {openModalAddMotorista && (
        <CadastroMotorista
          open={openModalAddMotorista}
          handleClose={() => setOpenModalAddMotorista(false)}
          dataEdit={currentUserEditing}
          fetch={() => {
            setOpenModalAddMotorista(false);
            fetchUsers();
          }}
        />
      )}

      <S.HeaderContianer>
        <div>
          <h1>Alunos</h1>
        </div>
        <S.ButtonContianer>
          <GhostButton
            startIcon={<SaveAlt />}
            loading={loadingExcel}
            size="medium"
            onClick={() => handleExcel()}
            style={{ marginLeft: '10px' }}
          >
            EXPORTAR
          </GhostButton>
          <Button startIcon={<AddCircleOutline />} onClick={handleClick}>
            <span style={{ fontSize: 14 }}>ADICIONAR ALUNOS</span>
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              sx={{ width: 170 }}
              onClick={() => {
                setActionsUserEditing(null);
                setOpenModalAddGestor(true);
              }}
            >
              Adicionar Gestor
            </MenuItem>
            <MenuItem
              sx={{ width: 170 }}
              onClick={() => {
                setActionsUserEditing(null);
                setOpenModalAddMotorista(true);
              }}
            >
              Adicionar Motorista
            </MenuItem>
          </Menu>
        </S.ButtonContianer>
      </S.HeaderContianer>
      <Divider light sx={{ marginTop: 2, marginBottom: 2 }} />
      <S.Controls>
        <div
          className="left"
          style={{
            background: isDriver
              ? theme.palette.system.disabled
              : theme.palette.system.highlight,
          }}
          onClick={() => {
            setLoading(true);
            setIsDriver(true);
            setInterval(() => {
              setLoading(false);
            }, 1500);
          }}
        >
          Motorista
        </div>
        <div
          className="right"
          style={{
            background: !isDriver
              ? theme.palette.system.disabled
              : theme.palette.system.highlight,
          }}
          onClick={() => {
            setLoading(true);
            setIsDriver(false);
            setInterval(() => {
              setLoading(false);
            }, 1500);
          }}
        >
          Gestor
        </div>
      </S.Controls>
      <S.TableContainer>
        {isDriver && (
          <DefaultTable
            data={dataMotorista || []}
            columns={columnsAluno}
            actions={actionsAlunos}
            searchKeys={['nome', 'filial_name']}
            loading={loading}
            placeholder="Buscar Cliente"
            searchEvent={search =>
              trackEvent(user, 'Buscar Cliente', null, search)
            }
            empty={{
              title: 'Nenhum cliente encontrado',
              description: '',
            }}
          />
        )}
        {!isDriver && (
          <DefaultTable
            data={dataUsuario || []}
            columns={columnsAluno}
            actions={actionsAlunos}
            searchKeys={['nome', 'filial_name']}
            loading={loading}
            placeholder="Buscar Cliente"
            searchEvent={search =>
              trackEvent(user, 'Buscar Cliente', null, search)
            }
            empty={{
              title: 'Nenhum cliente encontrado',
              description: '',
            }}
          />
        )}
      </S.TableContainer>
    </S.Container>
  );
};

export default Alunos;
