import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from 'styled-components';
import * as S from './styled';

export const columns2 = [
  {
    header: 'Posição',
    id: 'posicao',
    type: 'number',
    sort: true,
    width: 80,
    children: (value, item) => {
      const theme = useTheme();
      if (item?.oldPosition) {
        switch (item?.oldPosition) {
          case 'UP':
            return (
              <S.TableName center className="position">
                <ArrowDropUpIcon
                  className="svg-up"
                  htmlColor={theme.palette.brand.secondary.natural}
                />
                <span>{item?.posicao}</span>
              </S.TableName>
            );
          case 'DOWN':
            return (
              <S.TableName center className="position">
                <ArrowDropDownIcon
                  className="svg-up"
                  htmlColor={theme.palette.semantics.feedback.attention.natural}
                />
                <span>{item?.posicao}</span>
              </S.TableName>
            );
          case 'EQUAL':
            return (
              <S.TableName center className="position">
                <CircleIcon
                  className="svg"
                  htmlColor={theme.palette.semantics.feedback.success.natural}
                />
                <span>{item?.posicao}</span>
              </S.TableName>
            );
        }
      } else {
        return <S.TableName center>{item?.posicao}</S.TableName>;
      }
    },
  },
  {
    header: 'Empresa',
    id: 'transportadora',
    type: 'string',
    sort: true,
    picture: 'logo_url',
  },
  {
    header: 'Pontos/Hora',
    id: 'pontos_por_hora',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Horas',
    id: 'horas',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Motoristas Avaliados',
    id: 'motoristas_avaliados',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Motoristas Cadastrados',
    id: 'motoristas_cadastrados',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Quebra de Requisitos',
    id: 'quebra_requisitos',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Desvios Excluídos',
    id: 'percentual_desvios_excluidos',
    type: 'number',
    sort: true,
    align: 'center',
    value: value => `${value}%`,
  },
  {
    header: 'Desvios Finalizados no Prazo',
    id: 'desvios_no_prazo',
    type: 'number',
    sort: true,
    width: 200,
    align: 'center',
    value: value => `${value}%`,
  },
  {
    header: 'Disponibilização de Horas',
    id: 'disponibilizacao_avaliacoes',
    type: 'number',
    sort: true,
    align: 'center',
    value: value => `${value}%`,
  },
  {
    header: 'Prazo disponibilização',
    id: 'atendimento_sla',
    type: 'number',
    sort: true,
    align: 'center',
    value: value => `${value}%`,
  },
];

export const columnsCompany2 = [
  {
    header: 'Posição',
    id: 'posicao',
    type: 'number',
    sort: true,
    width: 80,
    children: (value, item) => {
      const theme = useTheme();
      if (item?.oldPosition) {
        switch (item?.oldPosition) {
          case 'UP':
            return (
              <S.TableName center className="position">
                <ArrowDropUpIcon
                  className="svg-up"
                  htmlColor={theme.palette.brand.secondary.natural}
                />
                <span>{item?.posicao}</span>
              </S.TableName>
            );
          case 'DOWN':
            return (
              <S.TableName center className="position">
                <ArrowDropDownIcon
                  className="svg-up"
                  htmlColor={theme.palette.semantics.feedback.attention.natural}
                />
                <span>{item?.posicao}</span>
              </S.TableName>
            );
          case 'EQUAL':
            return (
              <S.TableName center className="position">
                <CircleIcon
                  className="svg"
                  htmlColor={theme.palette.semantics.feedback.success.natural}
                />
                <span>{item?.posicao}</span>
              </S.TableName>
            );
        }
      } else {
        return <S.TableName center>{item?.posicao}</S.TableName>;
      }
    },
  },
  {
    header: 'Empresa',
    id: 'transportadora',
    type: 'string',
    sort: true,
    picture: 'logo_url',
  },
  {
    header: 'FIlial',
    id: 'filial',
    type: 'string',
    sort: true,
  },
  {
    header: 'Pontos/Hora',
    id: 'pontos_por_hora',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Horas',
    id: 'horas',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Motoristas Avaliados',
    id: 'motoristas_avaliados',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Motoristas Cadastrados',
    id: 'motoristas_cadastrados',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Quebra de Requisitos',
    id: 'quebra_requisitos',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Desvios Excluídos',
    id: 'percentual_desvios_excluidos',
    type: 'number',
    sort: true,
    align: 'center',
    value: value => `${value}%`,
  },
  {
    header: 'Desvios Finalizados no Prazo',
    id: 'desvios_no_prazo',
    type: 'number',
    sort: true,
    width: 200,
    align: 'center',
    value: value => `${value}%`,
  },
  {
    header: 'Disponibilização de Horas',
    id: 'disponibilizacao_avaliacoes',
    type: 'number',
    sort: true,
    align: 'center',
    value: value => `${value}%`,
  },
  {
    header: 'Prazo disponibilização',
    id: 'atendimento_sla',
    type: 'number',
    sort: true,
    align: 'center',
    value: value => `${value}%`,
  },
];
