import { formatNewHourClean } from 'utils/dates';
import { ReactComponent as Analise } from 'images/icons/tooltip/analise.svg';
import { Icon } from '../../components/IconsOnisys';

export const getTipoDesvio = infraction =>
  infraction?.desvio_tipo?.desvio_tipo_padrao?.categoria;
export const getGrupoDesvio = infraction =>
  infraction?.desvio_tipo?.desvio_tipo_padrao?.grupo;

export const getStatusColor = (status, theme) => {
  switch (status) {
    case 'ABERTO':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'PENDENTE':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'FINALIZADO':
      return theme?.palette?.semantics?.feedback?.information?.natural;
    case 'DELETADO':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
  }
};

export const getCriticidadeColor = (status, theme) => {
  switch (status) {
    case 'PRIMÁRIA':
      return theme?.palette?.semantics?.feedback?.information?.natural;
    case 'MODERADA':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'GRAVE':
    case 'GRAVÍSSIMA':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    default:
      theme?.palette?.semantics?.feedback?.unknown?.natural;
  }
};

export const statusTabs = [
  { value: 'ABERTO', label: 'Abertos' },
  { value: 'PENDENTE', label: 'Pendentes' },
  { value: 'FINALIZADO', label: 'Finalizados' },
  { value: 'DELETADO', label: 'Excluídos' },
];

export const iconsCards = {
  proximo_vencimento: 'schedule.svg',
  criticidade_alta: 'Grupo12754.svg',
  exclusao_revisao: 'warning.svg',
  desvios_analise: 'analise.svg',
};

export const formatArrFilters = filters => {
  const _filters = {};
  Object.keys(filters).forEach(key => {
    if (filters[key] && !['status', 'sortBy'].includes(key)) {
      if (typeof filters[key] === 'number')
        _filters[key] = `${filters[key]}`.split(',');
      else _filters[key] = filters[key].split(',');
      return;
    }
    _filters[key] = filters[key];
  });

  return _filters;
};

export const hasReview = desvio => {
  const review = desvio.aprovado_distribuidora;
  let msg = '';

  // Revisão de exclusão
  if (desvio.data_exclusao) {
    if (review === 0) msg = 'Exclusão em revisão pelo cliente.';
    else if (review === 2) msg = 'Exclusão recusada pelo cliente.';
    else if (review === 3)
      msg = 'Cliente pediu mais informações para aprovar exclusão.';
  }

  // Revisão de troca de operação
  else if (desvio.data_alteracao_distribuidora) {
    if (!desvio.data_revisao_distribuidora)
      msg = 'Troca de operação em revisão pelo cliente';
    else msg = 'Troca de operação recusada pelo cliente';
  }

  return msg;
};

export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista/Filial',
    id: 'motorista_nome',
    type: 'string',
    tooltip: true,
    sort: true,
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.filial_nome,
    },
  },
  {
    header: 'Placa',
    id: 'placa',
    type: 'string',
    sort: true,
    width: 100,
    style: {
      textTransform: 'uppercase',
    },
  },
  {
    header: 'Desvio',
    id: 'titulo',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Data Desvio',
    id: 'data_desvio',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewHourClean(item?.data_desvio),
  },
  {
    header: 'Data Vencimento',
    id: 'data_vencimento',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewHourClean(item?.data_vencimento),
  },
  {
    header: 'Responsável',
    id: 'responsavel_nome',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Criticidade',
    id: 'criticidade',
    type: 'string',
    sort: true,
    width: 200,
    align: 'center',
    style: {
      textTransform: 'uppercase',
    },
    conditional: [
      {
        condition: value => true,
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.information?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.information?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'MODERADA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.warning?.natural,
          backgroundColor: theme?.palette?.semantics?.feedback?.warning?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVÍSSIMA' || value === 'GRAVE',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.attention?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.attention?.light,
          textAlign: 'center',
        }),
      },
    ],
    attention: (_, item) => {
      if (item.em_analise)
        return {
          text:
            item.em_analise === 'ANALISE'
              ? 'Desvio em análise.'
              : 'Desvio com retorno de análise.',
          iconIn: <Analise />,
          iconOut: <Analise />,
        };
      const msg = hasReview(item);

      if (msg)
        return {
          text: msg,
        };
    },
  },
];

export const columnsEmbarcador = [
  columns[0],
  {
    header: 'Empresa',
    id: 'empresa_nome',
    type: 'string',
    tooltip: true,
    sort: true,
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.filial_nome,
    },
  },
  ...columns.slice(2),
];

export const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo do desvio',
    value: 'titulo',
    selected: true,
    default: true,
  },
  {
    label: 'Criticidade',
    value: 'criticidade',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'pontos',
    selected: true,
    default: true,
  },
  {
    label: 'Data do desvio',
    value: 'data_desvio',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'motorista_nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'filial_nome',
    selected: true,
    default: true,
  },
  {
    label: 'Veículo',
    value: 'placa',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'distribuidora_nome',
    selected: false,
    default: false,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Previsão de conclusão',
    value: 'data_previsao',
    selected: false,
    default: false,
  },
  {
    label: 'Data conclusão',
    value: 'data_encerramento',
    selected: true,
    default: true,
  },
  {
    label: 'Plano de ação',
    value: 'plano_acao',
    selected: true,
    default: true,
  },
  {
    label: 'Observação',
    value: 'observacao',
    selected: false,
    default: false,
  },
  {
    label: 'Responsável',
    value: 'responsavel_nome',
    selected: true,
    default: true,
  },
  {
    label: 'Disponibilizado em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
  {
    label: 'Movido para pendente em',
    value: 'data_pendente',
    selected: false,
    default: false,
  },
  {
    label: 'Justificativa exclusão',
    value: 'motivo_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Observação exclusão',
    value: 'observacao_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Data exclusão',
    value: 'data_exclusao',
    selected: false,
    default: false,
  },
];

export const fieldsProvider = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Transportadora',
    value: 'empresa_nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'filial_nome',
    selected: true,
    default: true,
  },
  {
    label: 'Placa',
    value: 'placa',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo de desvio',
    value: 'titulo',
    selected: true,
    default: true,
  },
  {
    label: 'Criticidade',
    value: 'criticidade',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'pontos',
    selected: true,
    default: true,
  },
  {
    label: 'Objeto',
    value: 'objeto',
    selected: false,
    default: false,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Status Embarcadora',
    value: 'status_embarcadora',
    selected: true,
    default: true,
  },
  {
    label: 'Data do desvio',
    value: 'data_desvio',
    selected: true,
    default: true,
  },

  {
    label: 'Disponibilizado em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
  {
    label: 'Movido para pendente em',
    value: 'data_pendente',
    selected: false,
    default: false,
  },
  {
    label: 'Plano de ação',
    value: 'plano_acao',
    selected: true,
    default: true,
  },
  {
    label: 'Previsão de conclusão',
    value: 'data_previsao',
    selected: true,
    default: true,
  },
  {
    label: 'Finalizado em',
    value: 'data_encerramento',
    selected: true,
    default: true,
  },
  {
    label: 'Responsavel',
    value: 'responsavel_nome',
    selected: true,
    default: true,
  },
  {
    label: 'Comentário',
    value: 'comentario',
    selected: false,
    default: false,
  },
  {
    label: 'Observação',
    value: 'observacao',
    selected: false,
    default: false,
  },
  // TODO: nao tem criador
  {
    label: 'Criado por',
    value: 'criador_nome',
    selected: true,
    default: true,
  },

  {
    label: 'Aprovado exclusão',
    value: 'aprovado_exclusao',
    selected: true,
    default: true,
  },

  {
    label: 'Data envio revisão',
    value: 'data_exclusao',
    selected: false,
    default: false,
  },

  {
    label: 'Data revisão distribuidora',
    value: 'data_revisao_distribuidora',
    selected: true,
    default: true,
  },

  {
    label: 'Vencimento distribuidora',
    value: 'vencimento_distribuidora',
    selected: true,
    default: true,
  },

  {
    label: 'Usuário distribuidora',
    value: 'revisor_nome',
    selected: false,
    default: false,
  },

  {
    label: 'Local',
    value: 'avaliacao_local',
    selected: false,
    default: false,
  },

  {
    label: 'Justificativa exclusão',
    value: 'motivo_exclusao',
    selected: true,
    default: true,
  },

  {
    label: 'Observação exclusão',
    value: 'observacao_exclusao',
    selected: true,
    default: true,
  },

  {
    label: 'Status Embarcadora',
    value: 'aprovado_distribuidora',
    selected: true,
    default: true,
  },

  {
    label: 'Observações distribuidora',
    value: 'observacao_distribuidora',
    selected: true,
    default: true,
  },
];

export const cardsInfo = [
  {
    type: '1',
    value: '0',
    icon: <Icon name="icone_risco_altissmo" />,
    text: 'Risco altíssimo',
    loading: false,
  },
  {
    type: '2',
    value: '0',
    icon: <Icon name="icone_risco_alto" />,
    text: 'Risco alto',
    loading: false,
  },
  {
    type: '3',
    value: '0',
    icon: <Icon name="icone_prox_vencimento" />,
    text: 'Deslocamentos perigosos',
    loading: false,
  },
];
