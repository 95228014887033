/* eslint-disable import/order */
// Styles
import * as S from '../styled';
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';

// Components
import Loading from 'components/Loading';
import Button from 'components/Buttons/Default';
import TableLocal from 'components/TableLocal';
import ConfirmModal from 'components/ConfirmModal';
import ModalAlunos from '../../../../Capacitacao/Turmas/Alunos';
import EditTurma from '../../../../Capacitacao/Turmas/Modal/EditTurma';

// Components MUI
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

// Utils
import { columnsView } from '../columns';

import {
  getTurma,
  deleteAlunoTurma,
} from '../../../../Capacitacao/Turmas/services';

const UnicTurma = ({
  behaviorCloseModal,
  openModal,
  idturma,
  setAllTurmas,
}) => {
  const [loading, setLoading] = useState(false);
  const [openModalAddAluno, setOpenModalAddAluno] = useState(false);
  const [openModalEditAluno, setOpenModalEditAluno] = useState(false);
  const [turmaName, setTurmaName] = useState('');
  const [foto, setFoto] = useState('');
  const [infoGroup, setinfoGroup] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [currentID, setCurrentID] = useState(null);

  // tabelas
  const [selectedRow, setSelectedRow] = useState('');
  const [data, setData] = useState([]);

  const fetchTurma = () =>
    getTurma(idturma).then(res => {
      setTurmaName(res.data.data.nome);
      setFoto(res.data.data.foto);
      setinfoGroup({
        id: res.data.data.id,
        nome: res.data.data.nome,
        foto: res.data.data.foto,
        status: res.data.data.ativa,
        para_motoristas: res.data.data.para_motoristas,
        para_usuarios: res.data.data.para_usuarios,
      });
      setData(res.data.data.alunos);
      setLoading(false);
    });

  useEffect(() => {
    fetchTurma();
    setLoading(true);
  }, [openModal]);

  const getAcron = str => {
    const matches = str.match(/\b(\w)/g);
    const acronym = matches.join('');
    return acronym.toUpperCase();
  };

  const formatTable = arr => {
    arr.forEach(i => {
      if (i.id === 'id') {
        i.Cell = function ({ row }) {
          return <S.ColDriver>{row.original.id}</S.ColDriver>;
        };
      } else if (i.id === 'nome') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <div className="container-img-name">
                <Avatar
                  alt={getAcron(row.original.nome)}
                  src={row.original.foto}
                  sx={{ marginRight: 1 }}
                />
                <S.Paragraph fontweight="bold" color="#494f65">
                  {row.original.nome}
                </S.Paragraph>
              </div>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'tipo') {
        i.Cell = function ({ row }) {
          return <S.ColDriver>{row.original.tipo}</S.ColDriver>;
        };
      } else if (i.id === 'funcao') {
        i.Cell = function ({ row }) {
          return <S.ColDriver>{row.original.funcao}</S.ColDriver>;
        };
      } else if (i.id === 'filial') {
        i.Cell = function ({ row }) {
          return <S.ColDriver>{row.original.filial.nome}</S.ColDriver>;
        };
      }
    });
    return arr;
  };
  const bulkActions = [
    {
      title: 'Excluir',
      function: id => setConfirm(true),
    },
  ];

  const sortBy = [
    {
      id: 'nome',
      desc: true,
    },
  ];

  const handleSetExcluir = async (id = null) => {
    const usersToDelete = [];
    data.map(item => {
      if (id === null && selectedRow.includes(item.id)) {
        usersToDelete.push(item.id_relacao_turma);
      } else if (Number(id) === Number(item.id)) {
        usersToDelete.push(item.id_relacao_turma);
      }
    });

    const dataToDelte = {
      id_turma: infoGroup.id,
      ids_alunos: usersToDelete,
    };
    const res = await deleteAlunoTurma(dataToDelte);
    if (res.data.success) {
      toast.success(res.data.message);
      fetchTurma();
    } else {
      toast.error(res.data.message);
    }
  };

  // --------------------------------------RETURN--------------------------------------//
  return (
    <S.ModalTurmas
      open={openModal}
      onClose={behaviorCloseModal}
      disableEscapeKeyDown={false}
    >
      <S.ContainerModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PeopleAltOutlinedIcon htmlColor={colors.greenMain} />
            <h1 style={{ marginLeft: '10px' }}>Turma #{idturma}</h1>
          </div>
          <IconButton onClick={() => behaviorCloseModal()}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>

        <Divider light sx={{ marginTop: 1, marginBottom: 3 }} />

        <S.ButtonContianer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Avatar src={foto} sx={{ marginRight: 1 }}>
              {turmaName.length > 0 && turmaName[0].toUpperCase()}
            </Avatar>
            <h1 className="titleTurma">{turmaName}</h1>
          </div>

          <div>
            <Button
              style={{ marginLeft: 10 }}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => setOpenModalAddAluno(true)}
            >
              <span style={{ fontSize: 14 }}>NOVOS ALUNOS</span>
            </Button>
          </div>
        </S.ButtonContianer>

        <S.TableContainer>
          <div
            style={{
              opacity: loading ? 0 : 1,
              pointerEvents: loading ? 'none' : 'all',
            }}
          >
            <TableLocal
              pageSizes={4}
              heightEmpty="285px"
              columns={formatTable(columnsView)}
              data={data}
              sortBy={sortBy}
              permitIsSortedOccur
              tableType
              actions={[
                {
                  name: 'excluir',
                  action: id => {
                    setCurrentID(id);
                    setConfirm(true);
                  },
                  text: 'Excluir',
                },
              ]}
              setSelectedData={setSelectedRow}
              bulkActions={bulkActions}
              empty={{
                image: '',
                title: 'Alunos não encontrados.',
                subtitle: '',
              }}
              search={{
                inputLabel: 'Buscar Cliente',
                keys: ['nome', 'tipoUser'],
              }}
            />
          </div>

          {loading && <Loading />}
        </S.TableContainer>

        {/* Modal Area */}
        <ConfirmModal
          open={confirm}
          handleClose={() => setConfirm(false)}
          title={`Exclusão de ${selectedRow.length === 0 ? 'aluno' : 'alunos'}`}
          subtitle={`Deseja excluir definitivamente ${
            selectedRow.length === 0
              ? 'o aluno selecionado'
              : ' os alunos selecionados'
          }?`}
          buttonText="Excluir"
          onClick={() => {
            selectedRow.length === 0
              ? handleSetExcluir(currentID)
              : handleSetExcluir();
            setConfirm(false);
          }}
        />

        {infoGroup && openModalAddAluno && (
          <ModalAlunos
            openModalAluno={openModalAddAluno}
            id_turma={idturma}
            tipoUser={{
              para_motoristas: infoGroup.para_motoristas ? 1 : 0,
              para_usuarios: infoGroup.para_usuarios ? 1 : 0,
              existentIds: data.map(item => item.id),
            }}
            behaviorCloseModal={() => setOpenModalAddAluno(false)}
            fetchData={fetchTurma}
          />
        )}

        <EditTurma
          open={openModalEditAluno}
          dataEdit={infoGroup}
          handleClose={() => setOpenModalEditAluno(false)}
          fetchData={fetchTurma}
        />
      </S.ContainerModal>
    </S.ModalTurmas>
  );
};

export default UnicTurma;
