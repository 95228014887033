import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)`
  ${({ theme, ...props }) => css`
    &.MuiButton-root {
      min-width: 120px;
      width: ${props.width || ''};
      cursor: ${props.loading === 'true' ? 'default' : 'pointer'};

      background: ${theme.palette.brand.secondary.natural};
      border-radius: 4px;

      font: normal normal 900 16px/24px Texta;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      color: ${theme.palette.words.text.contrast};

      &:hover {
        background: ${theme.palette.brand.secondary.light};
      }

      &:focus {
        background: ${theme.palette.brand.secondary.natural};
        box-shadow: 0px 3px 20px ${theme.palette.brand.secondary.natural};
      }

      &:active {
        background: ${theme.palette.brand.secondary.natural};
        box-shadow: 0px 3px 15px ${theme.palette.brand.secondary.natural};
      }
    }

    &.MuiButton-sizeSmall {
      height: 30px;
      font: normal normal 900 12px/24px Texta;
    }

    &.MuiButton-sizeMedium {
      height: 40px;
      font: normal normal 900 14px/18px Texta;
    }

    &.MuiButton-sizeLarge {
      height: 50px;
      font: normal normal 900 16px/24px Texta;
    }

    &&.Mui-disabled {
      background: ${theme.palette.system.disabled};
      color: ${theme.palette.words.disabled.natural};
    }
  `}
`;
