import { Modal } from '@mui/material';
import colors from 'styles/colors';
import styled from 'styled-components';

export const ModalMUI = styled.div`
  position: relative;
  display: none;
  overflow: hidden;
  background-color: white;
`;

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.white};
  padding: 20px 40px;
  overflow-y: scroll;
  overflow-x: hidden;

  .box {
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .lineGraph {
    position: relative;
    background-color: red;
    overflow: hidden;
  }
`;

export const Infos = styled.div`
  display: flex;
  justify-content: space-between;

  .formIdent {
    text-align: right;
    font: normal normal medium 26px/32px Texta;
    letter-spacing: 0px;
    color: #2e3240;
    opacity: 1;

    .logoCompany {
      img {
        max-height: 60px;
        max-width: 200px;
      }
    }
  }
`;

export const Header = styled.div`
  padding: 16px 16px 16px;
  margin-top: 12px;
  background: ${colors.white} 0% 0% no-repeat padding-box;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  opacity: 1;

  .topInfos {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    .coverArea {
      flex: 3;
      width: 100%;
      height: 200px;
      border-radius: 5px;

      .background {
        background-image: url(${props => props.bg});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: auto;
        height: 350px;
        border-radius: 5px;
      }
    }

    .textsArea {
      flex: 4;
      margin-left: 20px;

      .titleForm {
        font-weight: 800;
        font-size: 25px;
        color: ${colors.greyTitle};
      }

      .normalText {
        text-align: left;
        font: normal normal medium 14px/20px Texta;
        letter-spacing: 0px;
        color: ${colors.greySubtitle};
        opacity: 1;
      }

      .inputBox {
        border: 1px solid ${colors.grey};
        border-radius: 4px;
        opacity: 1;
        padding: 12px;

        .p {
          text-align: left;
          font: normal normal bold 16px/23px Texta;
          letter-spacing: 0px;
          color: ${colors.greyTitle};
          opacity: 1;
        }
      }
      .inputBoxArea {
        border: 1px solid ${colors.grey};
        border-radius: 4px;
        opacity: 1;
        height: 200px;
        padding: 12px;

        .p {
          text-align: left;
          font: normal normal bold 16px/23px Texta;
          letter-spacing: 0px;
          color: ${colors.greyTitle};
          opacity: 1;
        }
      }

      .descriptionForm {
      }
    }
  }

  .bottomInfos {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    .card {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .cardInfos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
          font-size: 16px;
          font-weight: 500;
          color: ${colors.greySubtitle};
          text-align: center;
        }

        .numbers {
          font-weight: 600;
          font-size: 18px;
          color: ${colors.greyTitle};
        }
      }
    }

    .bar {
      height: 100%;
      min-height: 100px;
      width: 1px;
      /* background-color: ${colors.greyTiny}; */
    }
  }
`;

export const Header2 = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableArea = styled.div`
  position: relative;

  .coverInput {
    width: 100%;
    height: 100px;
    background-color: ${colors.white};
    position: absolute;
    top: 0;
    z-index: 3;
  }

  .coverInputBottom {
    width: 100%;
    height: 100px;
    background-color: ${colors.white};
    position: absolute;
    bottom: 0;
    z-index: 3;
  }
`;

export const Paragraph = styled.p`
  font-weight: bold;
  color: ${colors.blueTitle};
  font-size: 14px;
  text-align: ${props => (props.center ? 'center' : 'start')};

  &.normal {
    font-size: 16px;
  }

  &.name {
    text-transform: capitalize;
  }

  &.small {
    font-weight: 500;
    font-size: 12px;
  }

  &.green {
    color: ${colors.greenSucces};
    background-color: ${colors.greenBackground};
    border-radius: 20px;
    text-align: center;
  }

  &.orange {
    color: ${colors.orangeAlert};
    background-color: ${colors.orangeBackground};
    border-radius: 20px;
    text-align: center;
  }

  &.red {
    color: ${colors.redDanger};
    background-color: ${colors.redBackground};
    border-radius: 20px;
    text-align: center;
  }
`;

export const ColDriver = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.blueTitle};
  }
`;

export const CardHeader = styled.div`
  margin-top: 10px;
  background: ${colors.greyTitle} 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  padding-top: 11px;

  h1 {
    text-align: center;
    font: normal normal 900 13px/14px Texta;
    letter-spacing: 0px;
    color: ${colors.white};
    text-transform: uppercase;
    opacity: 1;
  }
`;

export const Assinatura = styled.div`
  hr {
    margin: 20px 0;
  }

  h4 {
    color: #9a9ea8;
  }
`;

export const AsignTitle = styled.p`
  font-size: 16px;
  text-transform: capitalize;
  display: flex;
`;

export const NoContent = styled.p`
  height: 42px;
  font-size: 16px;
`;

export const AsignDate = styled.p`
  font-size: 16px;
  text-transform: capitalize;
  margin-left: 10px;
`;
