// React
import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { startOfMonth, subMonths } from 'date-fns';
import { usePlans } from 'hooks/usePlans';

// components pagina
import DesviosAbertos from './DesviosAbertos';
import Tarefas from './MinhasTarefas';
import Cards from './Cards';
import { GraficoDesviosTratadoNoprazo } from './GraficoDesviosTratadoNoprazo';

// Styles
import * as S from './styled';

// services
import * as API from './services';

const MinhasTarefas = ({ handleCount, hidden }) => {
  const theme = useTheme();
  const {
    isProvider,
    hasTelemetria,
    hasMonitoramento,
    hasTorrePlus,
    hasTorre,
  } = usePlans();

  const [cards01, setCards01] = useState([]);
  const [cards02, setCards02] = useState([]);
  const [cards03, setCards03] = useState([]);
  const [alertas, setAlertas] = useState([]);
  const [allTerefas, setAllTerefas] = useState([]);
  const [dateHDesvio, setHdesvio] = useState({
    finalDate: new Date(),
    initialDate: startOfMonth(subMonths(new Date(), 11)),
  });
  const [dataHdesvio, setDataHdesvio] = useState(null);

  const fetchCards01 = async () => {
    const res = await API.getCards1();
    setCards01(res?.data || []);
  };

  const fetchCards02 = async () => {
    const res = await API.getCards2();
    setCards02(res?.data || []);
  };

  const fetchCards03 = async () => {
    const res = await API.getCards3();
    setCards03(res?.card || []);
  };

  const fetchHistoricoDesvios = async () => {
    const res = await API.historicoDesvios(dateHDesvio);
    setDataHdesvio(res?.data || []);
  };

  const fetchAlertaDesvios = async () => {
    const res = await API.alertaDesvios();
    setAlertas(res?.data || []);
  };

  const fetchMinhasTarefas = async () => {
    const res = await API.minhasTarefas();
    setAllTerefas(res?.data || []);
  };

  useEffect(() => {
    fetchCards01();
    fetchCards02();
    fetchCards03();
    fetchAlertaDesvios();
    fetchMinhasTarefas();
  }, []);

  useEffect(() => {
    fetchHistoricoDesvios();
  }, [dateHDesvio]);

  return (
    <S.Container hidden={hidden}>
      <section className="grid grid-template-columns-2">
        <div className="item">
          <Cards data={{ cards01, cards02, cards03 }} />
        </div>
        <div className="item item-grid">
          <div className="sub-item sub-item-grid">
            <div
              className="inner-item inner-item-grid style-container"
              style={{ backgroundColor: 'white' }}
            >
              <DesviosAbertos data={alertas} />
            </div>
            <div
              className="inner-item style-container"
              style={{ backgroundColor: 'white' }}
            >
              <Tarefas data={allTerefas} handleCount={handleCount} />
            </div>
          </div>
          <div
            className="sub-item style-container"
            style={{ height: '80%', backgroundColor: 'white' }}
          >
            <GraficoDesviosTratadoNoprazo
              handleDate={setHdesvio}
              data={dataHdesvio}
              title="Histórico de desvios tratados no prazo"
            />
          </div>
        </div>
      </section>
    </S.Container>
  );
};

export default MinhasTarefas;
