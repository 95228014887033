import React from 'react';

import Grid from '@mui/material/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Tooltip, SvgIcon } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Loading from 'components/Loading';
import { useTheme } from 'styled-components';
import * as S from './styled';

const CardIndicadores = ({
  icon,
  iconCustom,
  value,
  text,
  border,
  selected,
  handleClick,
  smalltype,
  info,
  loading,
  disabled,
}) => {
  const theme = useTheme();
  const iconSrc = icon && require(`../../../images/icons/cards/${icon}`);
  return (
    <S.StyledCard
      disabled={disabled}
      onClick={() => !disabled && handleClick && handleClick()}
      border={
        selected ? `1px solid ${theme.palette.brand.secondary.natural}` : border
      }
      ck={handleClick ? 'pointer' : 'default'}
      smalltype={smalltype}
    >
      {!loading && (
        <Grid container>
          {(icon || iconCustom) && (
            <S.StyledGrid smalltype={smalltype} item xs={2} md={2} xl={2}>
              {iconCustom || <img src={iconSrc} />}
            </S.StyledGrid>
          )}

          <S.StyledGrid smalltype={smalltype} item xs={2} md={3} xl={2}>
            <h1>{Number(value) > 999 ? `${value.toString()[0]}K` : value}</h1>
          </S.StyledGrid>
          <S.StyledGrid smalltype={smalltype} item xs={6} md={6} xl={6}>
            <p>{text}</p>
          </S.StyledGrid>
          {handleClick && info !== undefined && (
            <S.StyledGrid smalltype={smalltype} item xs={2} md={1} xl={2}>
              <Tooltip title={info} placement="top">
                <SvgIcon
                  fontSize={smalltype ? 'smalltype' : 'medium'}
                  component={InfoOutlinedIcon}
                />
              </Tooltip>
            </S.StyledGrid>
          )}
          {handleClick && selected !== undefined && (
            <S.StyledGrid smalltype={smalltype} item xs={2} md={1} xl={2}>
              <KeyboardArrowDownIcon
                fontSize={smalltype ? 'smalltype' : 'medium'}
                style={{
                  transform: `rotate(${selected ? '0deg' : '-90deg'})`,
                  transition: 'all ease .3s',
                }}
              />
            </S.StyledGrid>
          )}
        </Grid>
      )}
      {loading && (
        <S.Container>
          <div className="card">
            <Loading />
          </div>
        </S.Container>
      )}
    </S.StyledCard>
  );
};

export default CardIndicadores;
