export const columns = [
  {
    header: 'Matrícula',
    id: 'matricula',
    type: 'number',
    sort: true,
    width: 150,
  },
  {
    header: 'Motorista',
    id: 'nome',
    type: 'string',
    sort: true,
  },
  {
    header: 'Pontuação',
    id: 'pontuacao',
    sufix: 'pontos',
    type: 'number',
    sort: true,
    align: 'center',
    conditional: [
      {
        condition: () => true,
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Nível',
    id: 'nivel.nivel',
    type: 'string',
    sort: true,
    width: 180,
  },
  {
    header: 'Ação Disciplinar',
    id: 'acao_disciplinar',
    type: 'string',
    sort: true,
    tooltip: true,
  },
];
