import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 1.5rem 0;

  @media (min-width: 70rem) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
