import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import DefaultButton from 'components/Buttons/Ghost';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Grid } from '@mui/material';
import {
  SaveAlt,
  AddCircleOutline,
  EditOutlined,
  DeleteOutline,
  Check,
} from '@mui/icons-material';
import ConstantsUpdater from 'services/updateConstants';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { formatNameDate } from 'utils/dates';
import { trackEvent } from 'utils/mixpanel';

import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

// Components
import GhostButton from 'components/Buttons/Ghost';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';
import InfoCard from 'components/Cards/InfoCard';
import Card from 'components/Cards/Indicador';
import Table from 'components/TableLocal';
import Tabs from 'components/Tabs';
import EmptyCard from 'components/Cards/EmptyDataCard';
import ConfirmModal from 'components/ConfirmModal';
import ExcelModal from 'components/ExcelModal';
import ExportToExcel from 'utils/exportToCvs';
import Podium from 'components/Podium';
import { requestDriversRanking, requestExcel } from './services';
import {
  infoMessage,
  cardsMotoristas,
  columnsMotoristasRanking,
  fields,
  columns,
  resetExcelFields,
} from './constants';
import * as S from './styled';
import { DefaultTable } from 'components/_Table/templates/default';

const pageTabs = [
  { value: 'direcao', label: 'Direção' },
  { value: 'descarga', label: 'Descarga' },
];

const sortBy = [{ id: 'pontos_por_hora', desc: false }];

const MotoristasRanking = () => {
  const navigate = useNavigate();
  const filter = useSelector(state => {
    return state.filter;
  });
  const filterRankingDriver = useSelector(state => {
    return state.filterRankingDriver;
  });
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const [data, setData] = useState(null);
  const [media, setMedia] = useState(0);
  const [parametro, setParametro] = useState(0);

  const [pageTab, setPageTab] = useState('direcao');
  const [loading, setLoading] = useState(false);
  const [loadingPodium, setLoadingPodium] = useState(true);
  const [podium, setPodium] = useState([]);

  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const handleColumns = () => {
    const newCols = columns.map(item => {
      if (item.id === 'pontos_por_hora') {
        return {
          ...item,
          conditional: [
            {
              condition: () => value => Math.round(value) > +parametro,
              style: theme => ({
                color: theme.palette.semantics.feedback.attention.light,
                backgroundColor:
                  theme.palette.semantics.feedback.attention.natural,
                textAlign: 'center',
              }),
            },
            {
              condition: value => Math.round(value) <= +parametro,
              style: theme => ({
                color: theme.palette.semantics.feedback.success.light,
                backgroundColor:
                  theme.palette.semantics.feedback.success.natural,
                textAlign: 'center',
              }),
            },
          ],
          subRow: {
            prefix: 'Meta:',
            value: () => `${parametro} pts/h`,
          },
        };
      }
      return item;
    });
    return newCols;
  };

  // const [columns, setColumns] = useState(
  //   formatColumns(columnsMotoristasRanking),
  // );

  const fetchRanking = async () => {
    setLoading(true);
    const filters = {
      ...filter,
      idDesvio: filterRankingDriver.desvio ?? null,
      idVeiculo: filterRankingDriver.veiculo ?? null,
      motoristas: filterRankingDriver.motoristas ?? null,
    };
    const res = await requestDriversRanking(filters);

    if (res && res.data.success) {
      setData(res.data.rows || []);
      setParametro(res.data.parametroPtsHr || 0);
      // setColumns(formatColumns(columnsMotoristasRanking));

      let totalPoints = res.data.rows.reduce((acc, cur) => {
        return acc + cur.pontos_por_hora;
      }, 0);

      // Até então não será mais utilizado. Pode ser que volte no futuro
      const mediaPoints = Math.round(totalPoints / res.data.rows.length);
      setMedia(mediaPoints);
      const { rows } = res.data;
      const formatedPodium = [];
      if (rows && rows.length) {
        for (let i = 0; i <= 2; i++) {
          if (rows.length > i) {
            formatedPodium.push({
              nameOne: rows[i].nome,
              nameTwo: rows[i].motorista.nome,
              position: i + 1,
              oldPosition: i,
              logo: rows[i].motorista.foto,
              hours: rows[i].horas,
              points: rows[i].pontos_totais,
            });
          }
        }
      }

      setPodium(formatedPodium);
      setLoadingPodium(false);
    } else {
      toast.error(
        'Erro ao acessar o ranking, tente mais tarde, caso persista procure o nosso time de suporte.',
      );
    }

    setLoading(false);
  };

  useEffect(async () => {
    fetchRanking();
  }, []);

  useEffect(async () => {
    setLoadingPodium(true);
    setLoading(true);

    fetchRanking();

    setLoading(false);
  }, [
    filter.finalDate,
    filter.initialDate,
    filter.filial,
    filter.operation,
    filter.client,
    filterRankingDriver.desvio,
    filterRankingDriver.veiculo,
    filterRankingDriver.motoristas,
    filterRankingDriver.carregado,
    filterRankingDriver.pista_molhada,
  ]);

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = {
      ...filter,
      ...filterRankingDriver,
      excelFields: newFields,
    };

    const res = await requestExcel(newQuery);
    if (res.data && res.data?.data?.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `ranking_motoristas_${formatedDate}`,
      });
    else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  const handleVer = id => {
    trackEvent(user, 'RANKING MOTORISTA: VER PERFIL');
    if (id) navigate(`/motoristas/${id}`);
    else toast.error('Não foi possível acessar os detalhes deste motorista.');
  };

  const handlePageTab = (event, newValue) => {
    setPageTab(newValue);
  };

  const renderHeader = () => {
    return (
      <>
        <S.TitleWrapper>
          <h1>Ranking Motoristas</h1>
          {pageTab === 'direcao' && (
            <div>
              <GhostButton
                startIcon={<SaveAlt />}
                size="medium"
                onClick={() => {
                  trackEvent(user, 'RANKING MOTORISTA: EXPORTAR');
                  setOpenExcelModal(true);
                }}
              >
                EXPORTAR
              </GhostButton>
            </div>
          )}
        </S.TitleWrapper>

        <Podium data={podium} loading={loadingPodium} />

        <div style={{ padding: '15px 0px' }}>
          <InfoCard message={infoMessage[pageTab]} key={pageTab} />
        </div>

        {/* <Tabs value={pageTab} items={pageTabs} onChange={handlePageTab} /> */}
      </>
    );
  };

  const actions = [{ title: 'Ver perfil', function: handleVer }];

  return (
    <>
      {/* <ConstantsUpdater names={['drivers']} trigger={triggerUpdate} setTrigger={setTriggerUpdate} /> */}
      <S.Main>
        {renderHeader()}
        {pageTab === 'direcao' && (parametro > 0 || media > 0) && (
          <DefaultTable
            loading={loading}
            data={data || []}
            columns={handleColumns()}
            actions={actions}
            searchKeys={['nome', 'matricula']}
            placeholder="Buscar por nome, função ou filial"
            sortBy={{ id: 'pontos_por_hora', order: 'ASC' }}
            searchEvent={search =>
              trackEvent(user, 'Busca Ranking de Motoristas', null, search)
            }
            empty={{
              image: 'motorista.png',
              title: 'Ops! Não foi encontrado motoristas para essa busca.',
              description:
                'Altere os filtros ou faça o cadastro do novo motorista!',
            }}
          />
        )}
      </S.Main>
      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}
    </>
  );
};

export default MotoristasRanking;
