export const grupos = [
  {
    name: 'Desvios automáticos',
    value: 'AUTOMATICO',
  },
  {
    name: 'Faixa de velocidade',
    value: 'VELOCIDADE',
  },
  {
    name: 'Cerca eletrônica',
    value: 'CERCA',
  },
  {
    name: 'Desvios Manuais',
    value: 'MANUAL',
  },
];

export const columns = [
  {
    header: 'Status',
    id: 'ativo',
    type: 'boolean',
    sort: true,
    width: 105,
  },
  {
    header: 'Título',
    id: 'titulo',
    type: 'string',
    sort: true,
  },
  {
    header: 'Grupo',
    id: 'grupo',
    type: 'string',
    sort: true,
  },
  {
    header: 'Cliente',
    id: 'distribuidora.nome',
    type: 'string',
    value: (_, item) => item.distribuidora?.nome ?? 'Todos',
    sort: true,
    width: 200,
    attention: (_, item) => {
      if (!item.id_empresa && !item.isProvider)
        return {
          text: 'Desvio configurado pelo cliente',
        };
    },
  },
  {
    header: 'Tipo de desvio',
    id: 'tipo',
    value: (_, item) =>
      item.desvios_tipos
        ?.map(tipo => tipo.desvio_tipo_padrao?.titulo)
        .join(', ') || '-',
    type: 'string',
    sort: false,
  },
  {
    header: 'Total Desvios',
    id: 'desvios_tipos.length',
    type: 'number',
    sort: false,
    align: 'center',
  },
];

export const fields = [
  {
    label: 'Ativo',
    value: 'ativo',
    selected: true,
    default: true,
  },
  {
    label: 'Título',
    value: 'titulo',
    selected: true,
    default: true,
  },
  {
    label: 'Categoria',
    value: 'grupo',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'distribuidora.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo de desvio',
    value: 'desvios_tipos.desvio_tipo_padrao.titulo',
    selected: true,
    default: true,
  },
  {
    label: 'Pontos',
    value: 'desvios_tipos.pontos',
    selected: true,
    default: true,
  },
  {
    label: 'Criticidade',
    value: 'desvios_tipos.criticidade',
    selected: true,
    default: true,
  },
];

export const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};
