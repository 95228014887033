import styled, { css } from 'styled-components';

export const TableName = styled.h3`
  ${({ theme, ...props }) => css`
    color: ${theme.palette.words.title.natural};
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: ${props.center ? 'center' : 'start'};

    &.logoContent {
      justify-content: start;
    }

    &.position {
      justify-content: space-around;
    }

    &.points {
      color: ${theme.palette.semantics.feedback.success.natural};
      background-color: ${theme.palette.semantics.feedback.success.light};
      border-radius: 30px;
    }

    .logo {
      width: 38px;
      height: 38px;
      border: 1px solid ${theme.palette.brand.secondary.natural};
      border-radius: 50px;
      padding: 2px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50px;
      }

      span {
        color: ${theme.palette.words.subtitle.natural};
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
      }
    }

    .svg {
      margin-right: 10px;
      transform: scale(0.3);
    }

    .svg-up {
      margin-right: 10px;
    }
  `}
`;

export const TotalContentBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    margin: 30px 0px;

    .boxItem {
      flex: 1;
      border-left: 2px solid ${theme.palette.system.border};

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${theme.palette.brand.primary.background};
        text-align: center;
        padding: 20px;
        font-weight: 600;
        color: ${theme.palette.words.subtitle.natural};
        height: 50px;
        border-bottom: 2px solid ${theme.palette.system.border};
      }

      .count {
        padding: 20px;
        text-align: center;
        font-weight: 500;
        color: ${theme.palette.words.title.natural};
      }
    }
  `}
`;

export const TotalTitle = styled.div`
  ${({ theme }) => css`
    font-size: 25px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    color: ${theme.palette.words.title.natural};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  `}
`;
