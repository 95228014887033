import { useState } from 'react';
import { toast } from 'react-toastify';
import ExportToExcel from 'utils/exportToCvs';
import { formatNameDate } from 'utils/dates';

const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};

function useExportExcel(fileName, fields, request, query) {
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = { ...query, excelFields: newFields };

    const res = await request(newQuery);
    if (res.excel) {
      ExportToExcel({
        excel: res.excel,
        name: `${fileName}_${formatedDate}`,
      });
      toast.success(res.message);
    } else if (res.message) toast.error(res.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };
  return {
    handleRequestExcel,
    openExcelModal,
    setOpenExcelModal,
    loadingExcel,
    excelFields,
    setExcelFields,
  };
}

export default useExportExcel;
