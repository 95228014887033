import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Skeleton from '@mui/material/Skeleton';
import { get } from 'lodash';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      >
        <p className="label">{`${label}`}</p>
        {payload.map((item, index) => (
          <p key={index} style={{ color: item.color }}>
            {`${item.name} : ${item.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const mockData = [
  {
    name: '',
    status_aberto: 10,
    status_finalizado: 8,
    status_pendente: 5,
    status_pendente_pos: 3,
    sol_exclusao: 2,
    excluidos: 1,
  },
  {
    name: '',
    status_aberto: 12,
    status_finalizado: 10,
    status_pendente: 6,
    status_pendente_pos: 4,
    sol_exclusao: 3,
    excluidos: 2,
  },
  {
    name: '',
    status_aberto: 15,
    status_finalizado: 12,
    status_pendente: 8,
    status_pendente_pos: 6,
    sol_exclusao: 4,
    excluidos: 3,
  },
  {
    name: '',
    status_aberto: 7,
    status_finalizado: 5,
    status_pendente: 3,
    status_pendente_pos: 2,
    sol_exclusao: 1,
    excluidos: 0,
  },
  {
    name: '',
    status_aberto: 20,
    status_finalizado: 18,
    status_pendente: 12,
    status_pendente_pos: 10,
    sol_exclusao: 6,
    excluidos: 4,
  },
];

export const GraphBar = ({ loading = false, data = [], condition = false }) => {
  const colors = ['#F64E60', '#FFA801', '#0C12F2', '#1BC5BD'].reverse();

  const CustomTick = ({ x, y, payload, index }) => {
    const originalLabel = payload.value;
    const words = originalLabel.split(' ');
    let firstLine = '';
    let secondLine = '';

    if (words.length > 1) {
      const midpoint = Math.ceil(words.length / 2);
      firstLine = words.slice(0, midpoint).join(' ');
      secondLine = words.slice(midpoint).join(' ');
    } else {
      firstLine = originalLabel;
    }

    const abbreviateLine = line => {
      if (line.length > 15) {
        return `${line.substring(0, 13)}...`;
      }
      return line;
    };

    firstLine = abbreviateLine(firstLine);
    secondLine = abbreviateLine(secondLine);

    return (
      <text
        x={x}
        y={y + 12}
        fill={data.length === 4 ? colors[index] : '#4B5166'}
        fontSize={14}
        fontWeight="bold"
        textAnchor="middle"
      >
        <tspan x={x} dy="0em">
          {firstLine}
        </tspan>
        {secondLine && (
          <tspan x={x} dy="1.2em">
            {secondLine}
          </tspan>
        )}
      </text>
    );
  };

  const _maxValue = Math.max(
    ...data.map(item =>
      Math.max(
        Number(item.status_aberto) || 0,
        Number(item.status_finalizado) || 0,
        Number(item.status_pendente) || 0,
        Number(item.status_pendente_pos) || 0,
        Number(item.sol_exclusao) || 0,
        Number(item.excluidos) || 0,
      ),
    ),
  );

  const minWidth = 1000;
  const calculatedWidth = data.length * 100;
  const chartWidth = Math.max(minWidth, calculatedWidth);

  const sortData = data => {
    return data.sort((a, b) => {
      const maxA = Math.max(
        Number(a.status_aberto) || 0,
        Number(a.status_finalizado) || 0,
        Number(a.status_pendente) || 0,
        Number(a.status_pendente_pos) || 0,
        Number(a.sol_exclusao) || 0,
        Number(a.excluidos) || 0,
      );

      const maxB = Math.max(
        Number(b.status_aberto) || 0,
        Number(b.status_finalizado) || 0,
        Number(b.status_pendente) || 0,
        Number(b.status_pendente_pos) || 0,
        Number(b.sol_exclusao) || 0,
        Number(b.excluidos) || 0,
      );

      return maxB - maxA; // Ordena de forma decrescente
    });
  };

  return (
    <div
      style={{
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        position: 'relative',
      }}
      className="barScroll"
    >
      {!loading ? (
        <>
          {data.length === 0 && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                color: 'rgb(75, 81, 102,0.8)',
                fontSize: 20,
                fontWeight: 'bold',
                fontFamily: 'Texta',
                zIndex: 1,
              }}
            >
              Nenhum registro encontrado. Tente ajustar os filtros!
            </div>
          )}
          <div style={{ opacity: data.length === 0 ? 0.3 : 1 }}>
            <BarChart
              width={chartWidth}
              height={200}
              data={data.length > 0 ? sortData(data) : mockData}
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 15,
              }}
              barCategoryGap={50}
              barGap={4}
            >
              <CartesianGrid horizontal vertical={false} />
              <Tooltip content={<CustomTooltip />} />

              <XAxis
                dataKey="name"
                tick={<CustomTick />}
                interval={0}
                textAnchor="end"
              />
              <YAxis domain={[0, _maxValue]} />
              <Bar
                dataKey="status_finalizado"
                fill={data.length > 0 ? '#4B5166' : '#ccc'}
                name="Desvios finalizados"
                barSize={10}
                radius={[4, 4, 0, 0]}
              />

              {!condition && (
                <Bar
                  dataKey="status_aberto"
                  name="Desvios abertos"
                  fill={data.length > 0 ? '#FFA801' : '#ccc'}
                  barSize={10}
                  radius={[4, 4, 0, 0]}
                />
              )}

              <Bar
                dataKey="status_pendente"
                fill={data.length > 0 ? 'rgb(189, 204, 48)' : '#ccc'}
                barSize={10}
                name="Movidos para pendentes"
                radius={[4, 4, 0, 0]}
              />
              {condition && (
                <Bar
                  dataKey="sol_exclusao"
                  name="Solicitações de exclusão"
                  fill={data.length > 0 ? '#0C12F2' : '#ccc'}
                  barSize={10}
                  radius={[4, 4, 0, 0]}
                />
              )}
              <Bar
                dataKey="status_pendente_pos"
                fill={data.length > 0 ? 'rgb(246, 78, 96)' : '#ccc'}
                name="Movidos para pendentes após vencimento"
                barSize={10}
                radius={[4, 4, 0, 0]}
              />
              {condition && (
                <Bar
                  dataKey="excluidos"
                  name="Excluídos"
                  fill={data.length > 0 ? '#FFA801' : '#ccc'}
                  barSize={10}
                  radius={[4, 4, 0, 0]}
                />
              )}
            </BarChart>
          </div>
        </>
      ) : (
        <div
          style={{
            marginLeft: 50,
            transform: 'translateY(-10px)',
          }}
        >
          <Skeleton height={250} />
        </div>
      )}
    </div>
  );
};
