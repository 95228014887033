// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

// Components
import Button from 'components/Buttons/Default';
import ConfirmModal from 'components/ConfirmModal';
import { DefaultTable } from 'components/_Table/templates/default';

// Components MUI
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ContentPasteOffOutlinedIcon from '@mui/icons-material/ContentPasteOffOutlined';

// Services
import { Tooltip } from '@mui/material';
import { useQuery } from 'react-query';
import { trackEvent } from 'utils/mixpanel';
import { getTurmas, deleteTurma, updateTurma } from './services';
import { getRouterQueryParams } from 'utils/router-query-params';

// Table columns
import { columnsTurmas } from './columns';
import { ModalListTasks } from './ModalBlocked/index.js';
import CadastrarTurmaModal from './Modal/AdicionarTurma';
import EditTurma from './Modal/EditTurma';
import * as S from './style.js';

const Turmas = () => {
  const theme = useTheme();
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const navigate = useNavigate();

  const criar = getRouterQueryParams({ location: useLocation }).get('criar');
  const [openModalAddAluno, setOpenModalAddAluno] = useState(
    !!criar && criar !== 'false',
  );
  const [openModalEditAluno, setOpenModalEditAluno] = useState(false);

  const [currenGroup, setCurrentGroup] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [viewContentConfirm, setViewContentConfirm] = useState(false);
  const [currentId, setCurrentId] = useState(-1);

  // Tumras bloqueadas
  const [conteudos, setConteudos] = useState(null);
  const [formularios, setFormularios] = useState(null);
  const [needRefetch, setNeedRefetch] = useState(false);

  // Tabelas
  const [data, setData] = useState([]);
  const [loadingDisable, setLoadingDisable] = useState(false);

  const {
    data: res,
    isError,
    refetch: fetchTurma,
    isFetching: loading,
  } = useQuery(['list-turma', 1], () => getTurmas(), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (loading || isError) {
      return null;
    }
    setData(res.data.data);
  }, [res]);

  const bulkActions = [
    {
      title: 'Excluir',
      function: id => {
        setCurrentId(id);
        handleSetExcluir();
      },
    },
  ];

  const formatTable = () => {
    const col = columnsTurmas.map(i => {
      if (i.id === 'atividades') {
        return {
          header: 'Atividades',
          align: 'center',
          children: (value, item) => (
            <S.ColDriver>
              <Tooltip
                title={
                  item.atividades
                    ? `${item.atividades} atividade(s) relacionada(s)`
                    : 'Nenhuma atividade relacionada'
                }
                onClick={
                  item.atividades
                    ? () => {
                        openModalActivities(item.id);
                      }
                    : undefined
                }
              >
                <S.Paragraph
                  className="activity"
                  hasActivity={!!item.atividades}
                >
                  <span>{item.atividades}</span>
                  <ErrorOutlineOutlinedIcon
                    htmlColor={
                      item.atividades
                        ? theme.palette.semantics.feedback.information.natural
                        : theme.palette.words.text.light
                    }
                  />
                </S.Paragraph>
              </Tooltip>
            </S.ColDriver>
          ),
        };
      }
      return i;
    });

    return col;
  };

  const handleSetExcluir = async () => {
    setLoadingDisable(true);
    const res = await deleteTurma(currentId);
    if (res.data.success) {
      toast.success(res.data.message);
      fetchTurma();
    } else {
      if (res.data?.hasContent) {
        setFormularios(res.data.formularios || []);
        setConteudos(res.data.conteudos || []);
        setViewContentConfirm(true);
      }
      toast.error(res.data.message);
    }
    setConfirm(false);
    setLoadingDisable(false);
    trackEvent(user, 'Turma Desativada Capacitação');
  };

  const handleActiveTurma = async turma => {
    const res = await updateTurma(turma.id, {
      id: turma.id,
      nome: turma.nome,
      ativa: 1,
      foto: turma.foto,
      para_motoristas: turma.para_motoristas,
      para_usuarios: turma.para_usuarios,
    });

    if (res.data.success) {
      fetchTurma();
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  };

  const navigateTO = id => {
    navigate(`/capacitacao/viewTurmas/${id}`, { id });
  };

  const openModalActivities = id => {
    Promise.all([setCurrentId(id)]).then(() => {
      setFormularios([]);
      setConteudos([]);
      setViewContentConfirm(true);
    });
  };

  const actions = [
    {
      title: 'Editar',
      function: id => {
        const info = data.find(item => Number(item.id) === Number(id));
        info.status = info.ativa;
        setCurrentGroup(info);
        setOpenModalEditAluno(true);
      },
    },
    {
      title: 'Ativar/Desativar',
      function: id => {
        const turma = data.find(item => Number(item.id) === Number(id));
        if (turma?.ativa) {
          if (turma.automatica) {
            toast.error(
              'Essa é uma turma automática e ela não pode ser desativada',
            );
          } else {
            if (turma.atividades) {
              openModalActivities(id);
            } else {
              setCurrentId(id);
              setConfirm(true);
            }
          }
        } else {
          handleActiveTurma(turma);
        }
      },
    },
    {
      title: 'Ver turma',
      function: id => navigateTO(id),
    },
  ];

  return (
    <S.Container>
      <CadastrarTurmaModal
        open={openModalAddAluno}
        handleClose={() => setOpenModalAddAluno(false)}
        fetchData={fetchTurma}
      />

      <EditTurma
        open={openModalEditAluno}
        dataEdit={currenGroup}
        handleClose={() => setOpenModalEditAluno(false)}
        fetchData={fetchTurma}
      />

      <S.ButtonContianer>
        <div>
          <h1>Turmas</h1>
        </div>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setOpenModalAddAluno(true)}
        >
          <span style={{ fontSize: 14 }}>ADICIONAR TURMA</span>
        </Button>
      </S.ButtonContianer>

      <S.TableContainer>
        {/* <TableLocal
          columns={formatTable(columnsTurmas)}
          data={data}
          sortBy={[]}
          permitIsSortedOccur
          onClickRow={val => {
            navigateTO(val.id);
          }}
          actions={actions}
          setSelectedRow={id => {
            setActions([
              {
                name: 'editar',
                text: 'Editar',
                action: id => {
                  const info = data.find(
                    item => Number(item.id) === Number(id),
                  );
                  info.status = info.ativa;
                  setCurrentGroup(info);
                  setOpenModalEditAluno(true);
                },
              },
              {
                name: 'excluir',
                text: data.find(item => Number(item.id) === Number(id))?.ativa
                  ? 'Desativar'
                  : 'Ativar',
                action: id => {
                  const turma = data.find(
                    item => Number(item.id) === Number(id),
                  );
                  if (turma?.ativa) {
                    if (turma.automatica) {
                      toast.error(
                        'Essa é uma turma automática e ela não pode ser desativada',
                      );
                    } else {
                      if (turma.atividades) {
                        openModalActivities(id);
                      } else {
                        setCurrentId(id);
                        setConfirm(true);
                      }
                    }
                  } else {
                    handleActiveTurma(turma);
                  }
                },
              },
              {
                name: 'ver',
                text: 'Ver turma',
                action: id => navigateTO(id),
              },
            ]);
          }}
          bulkActions={bulkActions}
          empty={{
            image: '',
            title: 'Suas turmas aparecerão aqui!',
            subtitle: '',
          }}
          search={{
            inputLabel: 'Buscar Turma',
            keys: ['nome', 'tipoUser', 'filial'],
          }}
        /> */}
        <DefaultTable
          data={data || []}
          onClickRow={id => {
            navigateTO(id);
          }}
          columns={formatTable()}
          actions={actions}
          searchKeys={['nome', 'tipoUser', 'filial']}
          loading={loading}
          placeholder="Buscar Turma"
          searchEvent={search => trackEvent(user, 'Buscar Turma', null, search)}
          empty={{
            title: 'Nenhum Turma encontrada',
            description: '',
          }}
        />
      </S.TableContainer>
      <ConfirmModal
        open={confirm}
        handleClose={() => setConfirm(false)}
        title="Tem certeza que deseja desativar a turma?"
        subtitle="Os alunos perderão acesso ao conteúdo"
        buttonText="Confirmar"
        titleIcon={
          <ContentPasteOffOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.attention.natural}
          />
        }
        onClick={() => handleSetExcluir()}
        loading={loadingDisable}
      />
      {viewContentConfirm && (
        <ModalListTasks
          open={viewContentConfirm}
          onClose={() => {
            setViewContentConfirm(false);
            if (needRefetch) {
              setNeedRefetch(false);
              fetchTurma();
            }
          }}
          conteudos={conteudos || []}
          formularios={formularios || []}
          idDisabled={currentId}
          fetchTurma={fetchTurma}
          setNeedRefetch={setNeedRefetch}
        />
      )}
    </S.Container>
  );
};

export default Turmas;
