import styled, { css } from 'styled-components';

const ContainerModifier = {
  disabled: () => css`
    opacity: 0.7;
    pointer-events: none;
  `,
};

export const Container = styled.div`
  ${({ theme, disabled }) => css`
    display: flex;
    color: ${theme.palette.words.subtitle.natural};
    font-weight: 600;
    font-size: 18px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease-in-out;
    ${disabled && ContainerModifier.disabled}

    .labelElement {
      .select {
        width: 88px;
        height: 40px;
        border: 2px solid ${theme.palette.system.border};
        background-color: ${theme.palette.system.highlight};
        border-radius: 5px;
        padding: 0px 5px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        font-size: 16px;
        color: ${theme.palette.words.text.light};
        font-weight: 600;

        option {
          font-size: 18px;
          color: ${theme.palette.words.text.light};
        }

        &:hover {
          border: 2px solid ${theme.palette.words.text.natural};
        }

        &:focus {
          border: 2px solid ${theme.palette.brand.secondary.natural};
        }
      }
    }

    @media (max-width: 1000px) {
      font-size: 16px;

      .labelElement {
        .select {
          width: 60px;
        }
      }
    }

    @media (max-width: 865px) {
      font-size: 14px;

      .labelElement {
        .select {
          width: 50px;
        }
      }

      .view {
        font-size: 12px;
      }
    }
  `}
`;
