// Styles
import { useEffect } from 'react';
import * as S from './styled';

// Context
import { useTable } from '../../../context';
import { _setPageSize } from '../../../context/actions';

export const Counter = ({ fixedValue, sizes = [10, 20, 30], disabled }) => {
  const { state, dispatch } = useTable();
  const { pageSize, count } = state;

  useEffect(
    () =>
      !fixedValue &&
      !sizes.includes(pageSize) &&
      dispatch(_setPageSize(sizes[0])),
    [sizes],
  );

  const handleChange = value => {
    dispatch(_setPageSize(value));
  };

  return (
    <S.Container disabled={disabled} id="counter-table-component">
      <p className="view">Exibindo</p>

      {!fixedValue && (
        <label className="labelElement">
          <select
            data-testid="select"
            value={pageSize}
            onChange={e => handleChange(e.target.value)}
            className="select"
            disabled={disabled}
          >
            {sizes.map((size, index) => (
              <option key={index} value={size} data-testid="select-option">
                {size}
              </option>
            ))}
          </select>
        </label>
      )}

      {fixedValue && <p className="view">{fixedValue}</p>}
      <p className="view">de {count}</p>
    </S.Container>
  );
};
