import React, { useEffect, useRef, useState } from 'react';
import fileUpload from 'images/image_file.png';
import { toast } from 'react-toastify';
import { firestorage } from 'utils/firebase';
import Icon from 'components/Icons';
import LoadingCenter from 'components/LoadingCenter';
import { getDatetimeString } from 'utils/dates';
import { Tooltip } from '@mui/material';
import { useTheme } from 'styled-components';
import * as S from './styled';

const DropZone = ({
  setFileName,
  height,
  setFileUrl,
  width,
  fileUrl,
  image_ref,
  company_name,
  deleteIcon,
  text,
  isNew,
}) => {
  const theme = useTheme();
  const [currentFile, setCurrentFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef();

  const drag = event => {
    if (!fileUrl) event.preventDefault();
  };

  const fileInputClicked = () => {
    if (!fileUrl) return fileInputRef.current.click();
  };

  const filesSelected = async () => {
    if (fileInputRef.current.files.length) {
      const { files } = fileInputRef.current;
      const file = fileInputRef.current.files[0];
      if (files.length > 1)
        toast.error('Só é permitido fazer upload de uma imagem');
      else setCurrentFile(file);
    }
  };

  const fileDrop = event => {
    if (!fileUrl) {
      event.preventDefault();
      const { files } = event.dataTransfer;
      const file = event.dataTransfer.files[0];
      if (files.length > 1)
        toast.error('Só é permitido fazer upload de uma imagem');
      else setCurrentFile(file);
    }
  };

  const validateFile = file => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'];
    if (validTypes.indexOf(file.type) === -1) return false;
    return true;
  };

  const savedFileFirebase = async data => {
    try {
      setLoading(true);
      const dh = getDatetimeString();
      if (!data) return toast.error('Não foi possível enviar sua imagem');

      const uploadTask = firestorage
        .ref(`/${company_name}/${image_ref}/${dh}_${data.name}`)
        .put(data);
      uploadTask.on(
        'state_changed',
        snapShot => {},
        err => {
          console.log('Err', err);
        },
        () => {
          firestorage
            .ref(`/${company_name}/${image_ref}/`)
            .child(`${dh}_${data.name}`)
            .getDownloadURL()
            .then(fireBaseUrl => {
              setFileUrl(fireBaseUrl);
            });
        },
      );
    } catch (error) {
      toast.error('Não foi possível enviar sua imagem');
    }
    setLoading(false);
  };

  const handleFiles = data => {
    if (data) {
      if (validateFile(data)) {
        savedFileFirebase(data);
        if (setFileName) setFileName(data);
      } else toast.error('Tipo de arquivo inválido');
    }
  };

  useEffect(() => {
    handleFiles(currentFile);
  }, [currentFile]);

  // Não deletar imagem do firebase!
  // Caso a quantidade de imagens for exorbitante, analisar o caso
  const deleteImageFromStorage = fileUrl => {
    // if (isNew) {
    //   let imageName = firestorage
    //     .refFromURL(fileUrl)
    //     .location.path_.split("/").pop();
    //   let imageReference = firestorage.ref(`/${company_name}/${image_ref}`).child(imageName);

    //   imageReference
    //     .delete()
    //   setFileUrl(null);
    // }
    setFileUrl(null);
  };

  return (
    <S.Container height={height} width={width}>
      <S.DropContainer
        onDragOver={drag}
        onDragEnter={drag}
        onDragLeave={drag}
        onDrop={fileDrop}
        onClick={fileInputClicked}
        height={height}
        width={width}
      >
        {loading && <LoadingCenter />}

        {!loading && !fileUrl && (
          <>
            <S.Message>
              {text && <p>{text}</p>}
              <S.Image src={fileUpload} alt={fileUpload} />
              <p>Solte o arquivo aqui ou clique para fazer upload</p>
              {/* <S.ImageType>( jpeg, jpg ou png )</S.ImageType> */}
            </S.Message>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple
              onChange={filesSelected}
            />
          </>
        )}

        {/* {!loading && fileUrl && fileUrl.includes('.pdf') &&
          <embed src={fileUrl} width={width} height={height} type="application/pdf" />
        } */}

        {!loading && fileUrl && !fileUrl.includes('.pdf') && (
          <S.ImageURL
            width={width}
            height={height}
            src={fileUrl}
            alt={fileUrl}
          />
        )}
      </S.DropContainer>
      {fileUrl && deleteIcon && (
        <Tooltip title="Deletar arquivo" placement="right">
          <S.IconDivClose onClick={() => deleteImageFromStorage(fileUrl)}>
            <Icon
              className={S.icon}
              name="close"
              size={25}
              color={theme.palette.brand.secondary.natural}
            />
          </S.IconDivClose>
        </Tooltip>
      )}
    </S.Container>
  );
};

export default DropZone;
