import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

// components
import Tabs from 'components/Tabs';
import { Switch } from 'components/Inputs/Switch';
import Divider from '@mui/material/Divider';
import Loading from 'components/Loading';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';

// constants
import { tabs } from './constants';

// styles
import * as S from './styled';

// services
import * as services from './services';

// icons
import { ReactComponent as EditarIcon } from 'images/icons/configuracoes/icone-editar.svg';
import { ReactComponent as BtnAdd } from 'images/icons/configuracoes/icone-adicionar.svg';

export const EscalationList = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState('Minhas listas');

  const {
    data: getEscalationList,
    isLoading,
    refetch,
  } = useQuery(['acao_motorista'], () => services.getEscalation(), {
    refetchOnWindowFocus: false,
  });

  const getColor = op => {
    switch (op) {
      case 'ALTÍSSIMO':
        return '#F64E60';
      case 'ALTO':
        return '#FFA801';
    }
  };

  const formatArrayString = (array, ids) => {
    let result = array.filter(item => ids && ids.includes(item.id));
    result = result.map(item => item.titulo);
    result = result.join(', ');
    return `${result.slice(0, 60)}...`;
  };

  const handleNavigate = params => {
    navigate('/configuracoes/escalation-list/nova-list', {
      state: {
        item: params,
        desviosTiposPadroes: getEscalationList.desviosTiposPadroes,
        desviosTipos: getEscalationList.desviosTipos,
      },
    });
  };

  const handleSwitchChange = async (id, value) => {
    const res = value
      ? await services.enableEscalation(id)
      : await services.disableEscalation(id);
    if (res?.success) {
      toast.success(res.message);
    } else {
      toast.error('Falha ao atualizar lista.');
    }
    refetch();
  };

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Tabs value={currentTab} items={tabs} onChange={setCurrentTab} />
          <Divider
            sx={{ marginBottom: 4, height: 2, transform: 'translateY(-2px)' }}
          />
          {getEscalationList?.rows?.map((item, index) => (
            <Fragment key={index}>
              <div className="item-list">
                <div className="item-list">
                  {item?.id ? (
                    <Switch
                      check={item.status === 'ATIVO'}
                      setCheckbox={value => handleSwitchChange(item.id, value)}
                      textOn=""
                      textOff=""
                    />
                  ) : (
                    <IconButton
                      sx={{ marginRight: 1 }}
                      onClick={() => handleNavigate(item)}
                    >
                      <BtnAdd />
                    </IconButton>
                  )}
                  <div className="description">
                    {item?.is_default && 'Onisys - '}
                    <span style={{ color: getColor(item?.risco) }}>
                      {`${item?.titulo.slice(0, 50)}` || ''}
                    </span>
                  </div>
                  <div className="desvios-list">
                    {formatArrayString(
                      item.is_default
                        ? getEscalationList?.desviosTiposPadroes
                        : getEscalationList.desviosTipos,
                      item?.ids_desvios,
                    )}
                  </div>
                </div>
                <div style={{ marginTop: 10 }}>
                  <IconButton onClick={() => handleNavigate(item)}>
                    <EditarIcon />
                  </IconButton>
                </div>
              </div>
              <Divider style={{ marginTop: 15 }} />
            </Fragment>
          ))}
          <div className="container-actions">
            <IconButton onClick={() => handleNavigate()}>
              <BtnAdd />
            </IconButton>
            <div onClick={() => handleNavigate()} className="btn-add">
              Adicionar lista
            </div>
          </div>
        </>
      )}
    </S.Container>
  );
};
