import api from 'services/api';

export const fetchAcaoSuspensao = async id => {
  const { data } = await api.get(`/acoes-suspensoes/${id}`);
  return data?.data || null;
};

export const fetchSequence = async (index, query) => {
  const res = await api.get(`/acoes-suspensoes/index/${index}`, {
    params: query,
  });
  if (res?.data) return res.data;
  return null;
};

export const fetchDesvios = async id => {
  const res = await api.get(`/acoes-suspensoes/desvios/${id}`);
  if (res?.data) return res.data;
  return null;
};

export const saveAcaoSuspensao = async data => {
  const res = await api.put(`/acoes-suspensoes`, data);
  return res;
};

export const finishAcaoSuspensao = async data => {
  const res = await api.put(`/acoes-suspensoes/finalizar`, data);
  return res;
};
