// Pages
import { Configuracoes } from 'pages/Configuracoes';
import { PerfilUsuario } from 'pages/Configuracoes/Usuarios/PerfilUsuario';
import LogUsuario from 'pages/Configuracoes/Usuarios/History';
import LogParametrizacoes from 'pages/Configuracoes/Configuracoes/History';
import Historico from 'pages/Historico';

export default {
  '/configuracoes/perfil': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Perfil & Empresa',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/personalizacao': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Personalização',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/filiais': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Filiais',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/clientes': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Clientes',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/usuarios': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Usuários',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/usuarios/criar': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Novo usuário',
    defaults: [],
    page: <PerfilUsuario />,
  },

  '/configuracoes/usuarios/:id': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Perfil do usuário',
    defaults: [],
    page: <PerfilUsuario />,
  },

  '/configuracoes/motoristas': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Motoristas',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/bases': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Minhas Bases',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/notificacoes': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Notificações',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/relatorios': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Relatórios',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/envios': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Envios',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/seguranca': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Segurança',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/configuracoes': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Configurações',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/escalation-list': {
    back: -1,
    subTitle: 'Configurações',
    title: 'Escalation List',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/escalation-list/nova-list': {
    back: -1,
    subTitle: 'Escalation List',
    title: 'Nova lista',
    defaults: [],
    page: <Configuracoes />,
  },

  '/logs/configuracoes/escalation-list/:id': {
    back: -1,
    title: 'Histórico da Configuração do Escalation List',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/escalation-list/:id/logs"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },

  '/configuracoes/api': {
    back: -1,
    subTitle: `API's`,
    title: 'Configurações',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/guia': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Guia do Usuário',
    defaults: [],
    page: <Configuracoes />,
  },

  '/configuracoes/guia-provider': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Guia do Usuário Provider',
    defaults: [],
    page: <Configuracoes />,
  },
  '/configuracoes/guia-provider': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Guia do Usuário Provider',
    defaults: [],
    page: <Configuracoes />,
  },

  '/logs/usuario/:id': {
    back: '/configuracoes/usuarios',
    title: 'Histórico de alterações - Usuário',
    defaults: [],
    page: <LogUsuario />,
  },

  '/logs/parametrizacoes': {
    back: -1,
    title: 'Histórico de alterações - Configurações',
    defaults: [],
    page: <LogParametrizacoes />,
  },
};
