export const columnsViolacoes = [
  {
    Header: 'Ativo',
    id: 'status',
    accessor: d => (d.status ? String(d.status) : false),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Violação',
    id: 'violacao',
    accessor: d => String(d.descricao),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Pontos',
    id: 'pontos',
    accessor: d => Number(d.pontos),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Decaimento (dias)',
    id: 'decaimento_tempo',
    accessor: d => String(d.decaimento_tempo),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Expiração (dias)',
    id: 'prazo',
    accessor: d => (d.prazo ? Number(d.prazo) : 'N/A'),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Plano de ação',
    id: 'plano_de_acao',
    accessor: d => (d.plano_de_acao ? String(d.plano_de_acao) : 'N/A'),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Ações',
    id: 'acoes',
    accessor: 'acoes',
    show: true,
  },
];
