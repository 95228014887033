import React from 'react';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import { AddCircleOutline } from '@mui/icons-material';
import { Grid, Stepper, Step, StepLabel } from '@mui/material';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import * as S from './styled';

// > image = .png salvo em images/empty
// > title e subtitle de cada card deve ser personalizado
// > handleDefaultButton handleGhostButton define as funções dos botoes
// > height e margintop são opcionais
const EmptyDataCard = ({
  image,
  title,
  subtitle,
  height,
  margintop,
  configViolations,
  configNiveis,
}) => {
  const theme = useTheme();
  const img = require(`images/empty/${image}`);
  const navigate = useNavigate();

  const handleConfigViolacoes = () => {
    navigate('/pad/configuracoes/violacoes');
  };

  const handleConfigNiveis = () => {
    navigate('/pad/configuracoes/niveis');
  };

  const renderHeader = () => {
    const steps = ['Violações', 'Níveis'];

    return (
      <>
        <Grid item sm={12}>
          <div>
            <Grid columnSpacing={4} item sm={12}>
              <Stepper
                activeStep={configViolations === false ? 0 : 1}
                alternativeLabel
              >
                {steps.map((label, idx) => {
                  const stepProps = {};
                  const labelProps = {};
                  // if (isStepFinished(idx)) {
                  //     stepProps.completed = false;
                  // }
                  return (
                    <Step orientation="vertical" key={label} {...stepProps}>
                      <StepLabel
                        sx={{
                          '.Mui-active': {
                            fill: `${theme.palette.system.highlight} !important`,
                            color: `${theme.palette.words.subtitle.natural} !important`,
                            borderColor: `${theme.palette.semantics.feedback.success.natural} !important`,
                          },
                          '.MuiStepLabel-label': {
                            color: `${theme.palette.brand.primary.light}`,
                          },
                          '.MuiStepIcon-text': {
                            fill: `${theme.palette.words.subtitle.natural} !important`,
                          },
                          '.Mui-completed': {
                            fill: `${theme.palette.semantics.feedback.success.natural} !important`,
                            color: `${theme.palette.brand.primary.light} !important`,
                            borderColor: `${theme.palette.semantics.feedback.success.natural} !important`,
                          },
                        }}
                        {...labelProps}
                        StepIconProps={{
                          sx: {
                            border: `2px solid ${theme.palette.system.border}!important`,
                            borderRadius: '50%',
                            color: `${theme.palette.words.text.contrast}`,
                          },
                        }}
                      >
                        <p>{label}</p>
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Grid>
          </div>
        </Grid>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        {renderHeader()}
      </Grid>

      <S.Container height={height} margintop={margintop}>
        <S.Image src={img} alt="empty_data_image" />
        <S.TextButtons>
          <h1>{title || 'Nenhum dado retornado'}</h1>

          <h2>
            {subtitle ||
              'Ainda não há dados suficientes para esta busca. Favor verificar seus filtros'}
          </h2>

          <S.ButtonsContainer>
            {configViolations === false && configNiveis === false && (
              <DefaultButton
                icon={<AddCircleOutline />}
                size="medium"
                onClick={() => handleConfigViolacoes()}
              >
                INICIAR PARA VIOLAÇÕES
              </DefaultButton>
            )}

            {configViolations === true && configNiveis === false && (
              <DefaultButton
                icon={<AddCircleOutline />}
                size="medium"
                onClick={() => handleConfigNiveis()}
              >
                ADICIONAR PONTUAÇÃO
              </DefaultButton>
            )}
          </S.ButtonsContainer>
        </S.TextButtons>
      </S.Container>
    </Grid>
  );
};

export default EmptyDataCard;
