import React, { useMemo } from 'react';
import { SingleTable } from '../../Components/SingleTable';
import { columns } from './constants';
import { useQuery } from 'react-query';
import { requestRanking } from 'pages/Provider/RankingEmpresas/services';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { usePlans } from 'hooks/usePlans';

const today = endOfDay(new Date());
const last30 = startOfDay(addDays(new Date(), -30));

const query = {
  initialDate: last30,
  finalDate: today,
  tipo: 'geral',
  pageIndex: 0,
  pageSize: 5,
  sortBy: { id: 'posicao', order: 'DESC', desc: true },
};

export const MenosSeguros = () => {
  const { planosAtivos } = usePlans();
  const { isFetching, data: resData } = useQuery(
    ['empresas-menos-seguras', query],
    () => query && requestRanking(query),
    {
      refetchOnWindowFocus: false,
    },
  );

  const data = useMemo(() => {
    const _data = resData?.data?.data ?? [];
    return _data.slice(0, 5);
  }, [resData]);

  return (
    <SingleTable
      title="Empresas menos seguras"
      subtitle="Últimos 30 dias"
      data={data}
      columns={columns.filter(
        col =>
          !col.plans ||
          planosAtivos.some(({ id_do_plano }) =>
            col.plans.includes(id_do_plano),
          ),
      )}
      link={{
        to: '/empresas/ranking?order=DESC',
        children: 'Ver todos',
        justify: 'right',
      }}
      loading={isFetching}
    />
  );
};
