import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import { SwitchTable } from 'components/Inputs/SwitchTable';

import TableLocal from 'components/TableLocal';
import EmptyCard from 'components/Cards/EmptyDataCard';
import Button from 'components/Buttons/Default';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';
import { AddCircleOutline } from '@mui/icons-material';
import * as S from './styled';

// Services
import * as request from '../services';

// Columns
import { columnsNiveis } from './columns';

// Modal
import AddNivelModal from '../AddNivelModal';
import EditNivelModal from '../EditNivelModal';

// Components

const PadNiveisConfig = () => {
  // const filter = useSelector(state => { return state.filter })

  const [query, setQuery] = useState({});
  const [niveis, setNiveis] = useState(null);
  const [columns, setColumns] = useState(columnsNiveis);
  const [loading, setLoading] = useState(false);
  const [editNivel, setEditNivel] = useState(null);

  const [openAddNivelModal, setOpenAddNivelModal] = useState(false);
  const [openEditNivelModal, setOpenEditNivelModal] = useState(false);

  const [activeNiveis, setActiveNiveis] = useState(0);
  const [disableButton, setDisableButton] = useState(true);
  const [updated, setUpdated] = useState(false);

  const navigate = useNavigate();

  // TODO Aprender como passar o objeto inteiro! Não somente o ID!
  const handleEdit = id => {
    const editing = niveis.find(n => String(n.id) === String(id));
    if (editing) {
      setEditNivel(editing);
      setOpenEditNivelModal(true);
    } else setOpenEditNivelModal(false);
  };

  const handleDelete = async id => {
    if (niveis && niveis.length > 0) {
      const lastNivel = niveis.reduce((max, nivel) =>
        max.pontuacao_final > nivel.pontuacao_final ? max : nivel,
      );
      if (parseInt(lastNivel.id, 10) !== parseInt(id, 10)) {
        toast.error(
          `Remova primeiro o nível com maior pontuação: ${lastNivel.nivel}`,
        );
      } else {
        const res = await request.deleteNivel(id);
        if (res.success) toast.success(res.message);
        else toast.error(res.message);
        setUpdated(true);
      }
    }
  };

  const handleFinishConfigNiveis = () => {
    navigate('/pad/configuracoes');
  };

  const sortBy = [
    {
      id: 'status',
      desc: false,
    },
  ];

  const fetchNiveis = async () => {
    setLoading(true);

    const res = await request.requestNiveis();
    if (res.success) {
      setNiveis(res.data);
      setActiveNiveis(res.data.length);
    } else toast.error('Não foi possível encontrar a lista de níveis.');

    setUpdated(false);
    setLoading(false);
  };
  const actions = [
    {
      name: 'editar',
      text: 'Editar',
      action: handleEdit,
    },
    {
      name: 'excluir',
      text: 'Excluir',
      action: handleDelete,
    },
  ];

  // useEffect(() => {
  //     setColumns(formatColumns(columnsNiveis))
  // }, [])

  useEffect(() => {
    fetchNiveis();
  }, []);

  useEffect(() => {
    fetchNiveis();
  }, [updated]);

  // const formatColumns = async (col) => {
  //     col.forEach(i => {
  //         if (i.id === 'nivel') {
  //             i.Cell = ({ row }) => (
  //                 <p fontWeight={'700'}>row.original.nivel</p>
  //             )
  //         }

  //     })
  // }

  const renderTop = () => (
    <>
      <S.TitleWrapper>
        <h1>Configurar Níveis</h1>
        <div>
          <DefaultButtonPopover
            startIcon={<AddCircleOutline />}
            size="medium"
            sx={{ marginLeft: '10px' }}
            onClick={() => setOpenAddNivelModal(true)}
          >
            ADICIONAR PONTUAÇÃO
          </DefaultButtonPopover>
        </div>
      </S.TitleWrapper>
    </>
  );

  const renderTable = () => {
    if (niveis && niveis.length > 0) {
      return (
        <TableLocal
          columns={columnsNiveis}
          data={niveis}
          sortBy={sortBy}
          permitIsSortedOccur
          actions={actions}
          loading={loading}
        />
      );
    }
    return (
      <EmptyCard
        image="padconfig.png"
        title={
          'Agora basta clicar em "ADICIONAR PONTUAÇÃO" para criar o primeiro nível.'
        }
        subtitle="Estamos quase lá!"
      />
    );
  };

  const renderFooter = () => {
    return (
      <Grid container marginTop="10px" display="flex">
        <Grid item marginLeft="5%">
          <S.Paragraph>{activeNiveis} níveis cadastrados</S.Paragraph>
        </Grid>
        <Grid item marginLeft="75%">
          <Button
            onClick={() => handleFinishConfigNiveis()}
            children="PROSSEGUIR"
            width="156px"
            disabled={disableButton}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <S.Main>
      {renderTop()}
      {renderTable()}
      <S.Wrapper>{renderFooter()}</S.Wrapper>

      {openAddNivelModal && (
        <AddNivelModal
          open={openAddNivelModal}
          handleClose={() => setOpenAddNivelModal(false)}
          title="Criar Novo Nível"
          niveis={niveis}
          closeModal={setOpenAddNivelModal}
          setUpdated={setUpdated}
          stopProgress={setDisableButton}
        />
      )}
      {openEditNivelModal && (
        <EditNivelModal
          open={openEditNivelModal}
          handleClose={() => setOpenEditNivelModal(false)}
          title="Editar Nível"
          niveis={niveis}
          edited={editNivel}
          closeModal={setOpenEditNivelModal}
          setUpdated={setUpdated}
        />
      )}
    </S.Main>
  );
};

export default PadNiveisConfig;
